import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import ButtonBackPage from "../../components/ButtonBackPage";
import { UthermFullImg } from "../../assets/images";
import General from "./General";
import Environment from "./Environment";
import CPU from "./CPU";
import { useParams, useHistory } from "react-router-dom";
import * as api from "../../Service/Api_Muthermfacesense";
import axios from "axios";
import ButtonCommand from "./ButtonCommand";
import { Tabs } from "antd";
import ConfigDevice from "./ConfigDevice";
import Announcement from "./Announcement";

const { TabPane } = Tabs;

export default function DeviceOne() {
  const history = useHistory();
  const { device_serial_number } = useParams();
  const token = localStorage.getItem("token");
  const [meta_data, setMeta_data] = useState(null);
  const [status_device, setStatus_device] = useState(null);

  useEffect(() => {
    if (!device_serial_number) {
      history.push("/device");
    } else {
      getMetaData();
      getStatusOnlineDevice();
    }
    return () => {};
  }, [device_serial_number]);

  function getStatusOnlineDevice() {
    const urlGetStatus = api.GET_STATUS_ONLINE_DEVICE_ONE(
      token,
      device_serial_number
    );
    axios(urlGetStatus)
      .then((res) => {
        setStatus_device(res.data.result);
      })
      .catch((error) => {
        // console.log(error);
        // console.log(error.response);
      });
  }

  function getMetaData() {
    const urlGetMetaData = api.GET_META_DATA(token, device_serial_number);
    axios(urlGetMetaData)
      .then((res) => {
        if (res.data.result[0]) {
          setMeta_data(res.data.result[0].meta_data);
        } else {
          history.push("/device");
        }
      })
      .catch((error) => {
        // console.log(error);
        // console.log(error.response);
      });
  }

  function renderObj(meta_data) {
    if (meta_data !== null) {
      return (
        <ul>
          {Object.entries(meta_data).map(([key, value]) => {
            {
              return (
                <li key={key}>
                  {key} : {JSON.stringify(value)}
                </li>
              );
            }
          })}
        </ul>
      );
    }
  }

  // function test() {
  //   const List = [1, 10, 2, 2, 10, 3, 3, 3, 4, 5, 5];
  //   let finalResult = List.filter(
  //     (e, i, a) => a.filter((f) => f == e).length == 1
  //   ).sort(function (a, b) {
  //     return a - b;
  //   });
  //   console.log(finalResult);

  //   // for (let i = 0; i < List.length; i++) {
  //   //   for (let j = 1; j < List.length; j++) {
  //   //     if(List[i] === List[j]){
  //   //       List.splice(j, 1);
  //   //       List.splice(i, 1);
  //   //       j--;
  //   //     }
  //   //   }
  //   // }
  //   // console.log(List);
  // }

  // function test() {
  //   var array = [9,4,5,8,7,1,9,4,5,8,7,2]
  //   var numberDup = []
  //   var isNumber = []
  //   var num = 0
  //   for (let i = 0; i < array.length; i++) {
  //    for (let j = i+1; j < array.length; j++) {
  //      if(array[i] === array[j] && array[i] !== undefined && array[j] !== undefined){
  //       //  console.log(array[i] + " " + array[j]);

  //         // numberDup.push(array[i])
  //         // numberDup.push(array[j])
  //         // isNumber.push(array[j])
  //         delete array[i];
  //         delete array[j];

  //         // array.splice(i,1)
  //         // array.splice(j,1)

  //         num++
  //         i+=1
  //      }
  //    }
  //   }
  //   // console.log(numberDup);
  //   // console.log(isNumber);
  //   // console.log("array.length = "+array.length);
  //   console.log(num);
  // }

  return (
    <Container fluid>
      {/* <button onClick={() => test()}>test function</button> */}
      <ButtonBackPage
        className="icon-txt-button"
        style={{ width: "80px", marginTop: "0px" }}
        pathRoute="/device"
      />
      <br />
      <Tabs defaultActiveKey="1">
        <TabPane
          tab={<p className="txt-sub-header1">Device Information</p>}
          key="1"
        >
          <div className="box-information-by-nile margin-box-device-information">
            <p className="txt-sub-header1-by-nile">Device Information</p>

            <Row>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <div
                  className="col-jcst-atct div-img-device-information-by-nile"
                  style={{ height: "auto" }}
                >
                  <img
                    className="img-device-information-by-nile"
                    src={UthermFullImg}
                    alt="UthermFullImg"
                    style={{ height: "150px" }}
                  />
                  <ButtonCommand device_serial_number={device_serial_number} getStatusOnlineDevice={getStatusOnlineDevice} />
                </div>
                <br />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <General
                  device_serial_number={device_serial_number}
                  meta_data={meta_data}
                  status_device={status_device}
                />
                <br />
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Environment meta_data={meta_data} />
                <br />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <CPU />
              </Col>
            </Row>
          </div>
        </TabPane>

        <TabPane tab={<p className="txt-sub-header1">Config device</p>} key="2">
          <ConfigDevice device_serial_number={device_serial_number} />
        </TabPane>

        <TabPane tab={<p className="txt-sub-header1">Announcement</p>} key="3">
          <Announcement device_serial_number={device_serial_number} />
        </TabPane>
      </Tabs>

      {/* <br />
      <p>Meta data</p>
      {renderObj(meta_data)}
      <hr />
      <p>Get device command</p>
      {renderObj(status_device)}
      <hr />
      <br /> */}
    </Container>
  );
}
