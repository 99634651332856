import React, { useState, useEffect } from "react";
import QrReader from "react-qr-scanner";
import { Spin } from "antd";
import { Container, Row, Col } from "reactstrap";
import { useDispatch } from "react-redux";
import {
  getCutQrcodeEmployee,
} from "../../../redux/actions";

export default function ScanQRCode({ userInfo, setUserInfo, switchSelect }) {
  const [checkingData, setCheckingData] = useState(false);
  const [status, setStatus] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    
    return () => {
      setStatus(null)
      setCheckingData(false)
    }
  }, [])
  

  useEffect(() => {
    setStatus(null)
    setCheckingData(false)
    return () => {};
  }, [switchSelect]);

  const handleScan = (data) => {
    if (data) {
      checkQRCode(data);
    }
  };
  const handleError = (err) => {
    // console.log(err);
  };

  function checkQRCode(dataQRCode) {
    setCheckingData(true)
    dispatch(getCutQrcodeEmployee(dataQRCode.text));
  }

  return (
    <div>
      {checkingData === true && (
        <div style={{ textAlign: "center", marginTop:'20px' }}>
          <Spin size="large" />
        </div>
      )}

      {checkingData === false && userInfo.firstname === null && (
        <>
          {status !== null && (
            <p style={{ color: "red", textAlign: "center", fontSize: "18px" }}>
              {" "}
              {status}{" "}
            </p>
          )}
          <Container fluid>
            <Row>
              <Col xs="0" sm="0" md="3" lg="4" xl="4" />
              <Col xs="12" sm="12" md="6" lg="4" xl="4">
                <br />
                <QrReader
                  delay={300}
                  onError={handleError}
                  onScan={handleScan}
                  style={{
                    width: "500px",
                    maxWidth: "100%",
                    display: "flex",
                    margin: "auto",
                  }}
                />
              </Col>
              <Col xs="0" sm="0" md="3" lg="4" xl="4" />
            </Row>
          </Container>
          <br />
        </>
      )}
    </div>
  );
}
