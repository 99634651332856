import { useState , useEffect } from "react";
import Drawer from "react-motion-drawer";
import { HiOutlineMenu } from "react-icons/hi";
import { icon_logo } from "../assets/icons";
import { useHistory } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";
import { BiChevronLeft } from "react-icons/bi";
import { listMenuAll , listMenuUser } from "./ListMenu";
import { useSelector } from "react-redux";

export default function LeftBarMenu() {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const [openMenu, setOpenMenu] = useState(false);
  const [isRoot, setIsRoot] = useState(false)
  const { UserInformation } = useSelector((state) => state);
  
  useEffect(() => {
    if(UserInformation){
      setIsRoot(UserInformation.is_root)
    }
  
    return () => {
      
    }
  }, [UserInformation])

  function getBasePath() {
    let pathOne = Object.values(params).reduce(
      (path, param) => path.replace("/" + param, ""),
      location.pathname
    );
    let pathTwo = location.pathname.slice(
      0,
      location.pathname.lastIndexOf("/")
    );
    if (pathTwo) return pathTwo;
    else return pathOne;
  }

  const basePath = getBasePath();

  function routePath(params) {
    history.push(params);
    setOpenMenu(false);
  }

  function checkPathActive(paramsPath) {
    var classPathStyle = "nav-button-mb";
    paramsPath.map((path) => {
      if (basePath === path) {
        classPathStyle = "active-navbar-mb";
      }
    });
    return classPathStyle;
  }

  return (
    <div className={isRoot === true ? "left-bar-menu-sm" : "left-bar-menu-sm-user"}>
      <div className="nav-box">
        <button
          onClick={() => setOpenMenu(!openMenu)}
          style={{
            backgroundColor: "#fff",
            border: "1px solid #fff",
            marginLeft: "25px",
          }}
        >
          <HiOutlineMenu size={30} style={{ color: "#9B9B9B" }} />
        </button>

        <Drawer
          width={280}
          fadeOut
          open={openMenu}
          onChange={() => setOpenMenu(!openMenu)}
        >
          <div
            style={{
              backgroundColor: `rgba(248, 249, 254)`,
              width: "100%",
              height: "100%",
              position: "relative",
            }}
          >
            <br /> <br />
            <img
              src={icon_logo}
              alt="LogoImg"
              style={{ display: "block", margin: "auto" }}
            />
            <br /> <br />
{
  isRoot === true && (
    <>
     {listMenuAll.map((data, index) => {
              let classActive = checkPathActive(data.activePathMenubar);
              return (
                <div className="div-menu-mobile-list" key={index}>
                  <button
                    className={classActive}
                    onClick={() => routePath(data.pathnameMenu)}
                  >
                    {data.nameMenu}
                  </button>
                </div>
              );
            })}
    </>
  )
}
       
{
  isRoot === false && (
    <>
     {listMenuUser.map((data, index) => {
              let classActive = checkPathActive(data.activePathMenubar);
              return (
                <div className="div-menu-mobile-list" key={index}>
                  <button
                    className={classActive}
                    onClick={() => routePath(data.pathnameMenu)}
                  >
                    {data.nameMenu}
                  </button>
                </div>
              );
            })}
    </>
  )
}
            <div className="div-menu-mobile-list">
              <button
                className={checkPathActive(["/setting"])}
                onClick={() => routePath("/setting")}
              >
                Setting
              </button>
            </div>

            {/* <div className="button-fixed-bottom-menu-mobile">
              <p className="text-button-back-fixed-bottom-menu-mobile cursor-pointer">
                {" "}
                <BiChevronLeft size={25} color="#7b7b7b" /> Back to Bizzly
              </p>
            </div> */}

          </div>
        </Drawer>
      </div>
    </div>
  );
}
