import { put, takeEvery, call } from "redux-saga/effects";
import {
  GET_LOG_FILTER_REQUEST,
  GET_LOG_FILTER_SUCCESS,
  GET_LOG_FILTER_FAILED,
  CLEAR_LOG_FILTER,
} from "../actions";
import * as api from "../../Service/Api_Muthermfacesense";
import axios from "axios";

const token = localStorage.getItem("token");

const checkParameter = (params, data) => {
  if (data) {
    return params + data;
  } else {
    return "";
  }
};

function logFilterFetch(payload) {
  const url = `api/log/logs_pagination_with_filter?page=${payload.page}&limit=${
    payload.limit
  }&timestamp_date_start=${payload.timestamp_date_start}&timestamp_date_end=${
    payload.timestamp_date_end
  }${checkParameter("&employee_id=", payload.employee_id)}${checkParameter(
    "&device_sn=",
    payload.device_serial_number
  )}`;
  const logAll = api.GET_DYNAMIC_URL(token, url);
  return axios(logAll)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

function* handleGetLogFilter({ payload }) {
  if (payload.clear) {
    yield put({
      type: CLEAR_LOG_FILTER,
    });
  } else {
    try {
      const responseLog = yield call(logFilterFetch, payload);
      yield put({
        type: GET_LOG_FILTER_SUCCESS,
        log: responseLog.message,
        totalCount: responseLog.total,
        totalPage: responseLog.total_page,
      });
    } catch (err) {
      if (err.response) {
        yield put({
          type: GET_LOG_FILTER_FAILED,
          error: err.response.data.detail,
          statusCode: err.response.status,
        });
      } else {
        yield put({
          type: GET_LOG_FILTER_FAILED,
          error: err.message,
        });
      }
    }
  }
}

function* logFilter() {
  yield takeEvery(GET_LOG_FILTER_REQUEST, handleGetLogFilter);
}

export default logFilter;
