import { put, takeEvery, call } from "redux-saga/effects";
import {
  GET_DEVICE_AFTER_SELECT_RPC_REQUEST,
  GET_DEVICE_AFTER_SELECT_RPC_SUCCESS,
  GET_DEVICE_AFTER_SELECT_RPC_FAILED,
} from "../actions";
import * as api from "../../Service/Api_Muthermfacesense";
import axios from "axios";

const token = localStorage.getItem("token");

function deviceAfterSelectRpcFetch(payload) {
    const deviceAfterSelectRpc = api.GET_DEVICE_AFTER_SELECT_RPC(token , payload);
    return axios(deviceAfterSelectRpc)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  function* handleGetDeviceAfterSelectRpc({payload}) {
    if(payload === "isDefault"){
      yield put({
        type: GET_DEVICE_AFTER_SELECT_RPC_SUCCESS,
        device_after_select_rpc : ["isDefault"],
        selectAllDevice : true
      });
    } else {
      try {
        const responseDeviceAfterSelectRpc = yield call(deviceAfterSelectRpcFetch , payload);
        yield put({
          type: GET_DEVICE_AFTER_SELECT_RPC_SUCCESS,
          device_after_select_rpc: responseDeviceAfterSelectRpc.result.data,
          selectAllDevice : false
        });
      } catch (err) {
        yield put({
          type: GET_DEVICE_AFTER_SELECT_RPC_FAILED,
          error: err.response.data.detail,
          statusCode: err.response.status,
          selectAllDevice : false
        });
      }
    }
  }
  
  function* deviceAfterSelectRpc() {
    yield takeEvery(GET_DEVICE_AFTER_SELECT_RPC_REQUEST, handleGetDeviceAfterSelectRpc);
  }
  
  export default deviceAfterSelectRpc;