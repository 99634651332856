import React, { useState } from "react";
import InputNormal from "../../components/InputNormal";
import { HiOutlineUpload } from "react-icons/hi";
import { Row, Col } from "reactstrap";
import InputDropdown from "../../components/InputDropdown";
import {
  ImgRegionMap,
  ImgRegionText,
} from "../../assets/images/region-img/IndexRegionImage";
export default function AddNewStudent() {
  const [regionImgMap, setregionImgMap] = useState(ImgRegionMap[0]);
  const [regionImgText, setregionImgText] = useState(ImgRegionText[0]);

  return (
    <div>
      <div className="box-add-new-student-by-nile">
        <div className="row-jcct-py">
          <p
            style={{
              color: "#134e96",
              fontSize: "1.2rem",
              marginBottom: "10px",
            }}
            className="txt-sub-explanation1"
          >
            New Employee
          </p>
        </div>

        <Row>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <div className="box-main-name-add-new-student">
              <InputNormal title={"First Name"} />
              <InputNormal title={"Last Name"} />
            </div>
          </Col>

          <Col xs={12} sm={12} md={6} lg={3} xl={3}>
            <div className="padding-div-border-input-field-add-new-student">
              <InputNormal title={"ID"} />
              <InputDropdown
                title={"Factory"}
                item={[
                  "โรงงานบางนา กม.21",
                  "โรงงานลำพูน",
                  "โรงงานศรีราชา",
                  "โรงงานหาดใหญ่",
                  "โรงงานโคกกรวด",
                  "โรงงานพิษณุโลก",
                  "โรงงานธารเกษม",
                  "โรงงานราชบุรี",
                  "โรงงานหนองแค",
                  "โรงงานท่าเรือ",
                  "โรงงานปักธงชัย",
                  "โรงงานขอนแก่น",
                ]}
                isButtom={true}
                itemCallback={(item, key) => {
                  setregionImgMap(ImgRegionMap[key]);
                  setregionImgText(ImgRegionText[key]);
                }}
              />
            </div>
          </Col>

          <Col xs={12} sm={12} md={6} lg={3} xl={3}>
            <div className="col-aict padding-div-border-input-field-add-new-student div-upload-image-add-new-student">
              <div className="box-image-train-new-student">
                <HiOutlineUpload size={"2.6rem"} />
                <p className="txt-unfocus1">Click to Upload</p>
              </div>
              <p className="txt-sub-explanation1">Profile Pic</p>
              <p className="txt-unfocus1">Use From Activities</p>
            </div>
          </Col>
        </Row>
        <div className="mt-1"></div>
        <Row className="justify-content-center">
          <Col xs={5} sm={4} md={2} lg={2} xl={2}>
            <p className="txt-sub-explanation1">{`Region - ${regionImgText}`}</p>
          </Col>
        </Row>
        <div className="mt-1"></div>
        <Row xs={3} sm={3} md={5} className="justify-content-center">
          {regionImgMap.map((img, key) => {
            return (
              <Col  md={2} lg={2} xl={2}>
                <div className="box-circle-region-img">
                  <img src={img}></img>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
}

// import React from "react";
// import InputNormal from "../../components/InputNormal";
// import { HiOutlineUpload } from "react-icons/hi";
// import ButtonPrimary from "../../components/ButtonPrimary";

// export default function AddNewStudent() {
//   return (
//     <div>
//       <div className="box-add-new-student">
//         <div className="row-jcct-py">
//           <p style={{ color: "#134e96" }} className="txt-sub-explanation1">
//             New Student
//           </p>
//         </div>
//         <div className="row-gab2-w100">
//           <div className="col-gab1-w50">
//             <div className="box-main-name-add-new-student">
//               <InputNormal title={"First Name"} />
//               <InputNormal title={"Last Name"} />
//             </div>
//             <InputNormal title={"Student ID"} />
//             <InputNormal title={"Adviser Name"} />
//           </div>
//           <div className="col-gab1-w50">
//             <div className="row-gab1">
//               <div>
//                 <InputNormal title={"Class"} />
//                 <InputNormal title={"Room"} />
//               </div>
//               <div className="col-aict">
//                 <div className="box-image-train-new-student">
//                   <HiOutlineUpload size={"2.6rem"} />
//                   <p className="txt-unfocus1">Click to Upload</p>
//                 </div>
//                 <p className="txt-sub-explanation1">Profile Pic</p>
//                 <p className="txt-unfocus1">Use From Activities</p>
//               </div>
//             </div>
//             <InputNormal title={"Club"} />
//             <InputNormal
//               title={"Parent Name (Father , Mother , Uncle, Brother or etc..)"}
//             />
//           </div>
//         </div>
//         <InputNormal title={"Remark"} />
//       </div>
//       <div className="row-jcct">
//         <ButtonPrimary
//           style={{ margin: "2rem 0", padding: "0.6rem 10rem" }}
//           className={"primary-button"}
//           text={"Save"}
//         />
//       </div>
//     </div>
//   );
// }
