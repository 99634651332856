import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { Row, Col } from "reactstrap";
import ButtonPrimary from "../../components/ButtonPrimary";
import ContentOfConsent from "./ContentOfConsent";

export default function ConsentModal() {
  const checkConsent = localStorage.getItem("consent_approve");
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (checkConsent === false || !checkConsent || checkConsent === null || checkConsent === 'false') {
      setVisible(true);
    } else if(checkConsent === 'true') {
      setVisible(false);
    } else {
        setVisible(true);
    }

    return () => {};
  }, [checkConsent]);

  function approve() {
    localStorage.setItem("consent_approve", true);
    setTimeout(() => {
      setVisible(false);
    }, 500);
  }

  function cancel() {
    var win = window.open("about:blank", "_self");
    win.close();
  }

  return (
    <div>
      <Modal
        centered
        closable={false}
        visible={visible}
        footer={null}
        width={800}
        className="modalStyle-ModalSelectATK"
      >
        <p
          className="txt-sub-explanation1"
          style={{ textAlign: "center", fontSize: "30px" }}
        >
          นโยบายคุ้มครองข้อมูลส่วนบุคคล
        </p>
        <p
          className="txt-sub-explanation1"
          style={{ textAlign: "center", fontSize: "20px" }}
        >
          บริษัท นก ซอฟต์ จำกัด
        </p>
        <br />
        <Row>
          <Col>
            <ContentOfConsent />
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="12" md="6" lg="6" xl="6">
            <br />
            <ButtonPrimary
              className="button2-save-create-new-employee-by-nile"
              text="ยอมรับ"
              onClick={() => approve()}
            />
          </Col>
          <Col xs="12" sm="12" md="6" lg="6" xl="6">
            <br />
            <ButtonPrimary
              className="icon-txt-button2-by-nile"
              text="ปฏิเสธ"
              onClick={() => cancel()}
            />
          </Col>
        </Row>
      </Modal>
    </div>
  );
}
