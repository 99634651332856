import React from "react";
import { Row, Col } from "reactstrap";
export default function CardStatusDashboard({
  imageCard,
  HeaderValue,
  FooterValue,
}) {
  return (
    <div className="box-top-show-by-nile">
      <Row>
        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
          <img className="img-top-show-by-nile" src={imageCard} alt={imageCard} />
        </Col>
        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
          <p className="txt-top-show-header-by-nile">{HeaderValue}</p>
          <p className="txt-top-show-content-by-nile">{FooterValue}</p>
        </Col>
      </Row>
    </div>
  );
}
