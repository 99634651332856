import React from "react";
import { left,thaiStudentPic } from "../../assets/images";
import {
  no_image_in_systems,
  serverIcon,
  studentIcon,
} from "../../assets/icons";
import { Row, Col } from "reactstrap";
import DisplayTextHeaderAndValue from "../../components/DisplayTextHeaderAndValue";

export default function CardDetailUserLog() {
  return (
    <div className="box-top-trainimage-by-nile">
      <Row>
        <Col xs={12} sm={12} md={12} lg={2} xl={2}>
          <img
            src={thaiStudentPic}
            alt="left"
            className="image-user-log-in-card-by-nile"
          />
          <p className="txt-sub-explanation1-by-nile">Image from Log</p>
        </Col>

        <Col xs={12} sm={12} md={6} lg={5} xl={5}>
          <div className="div-DisplayTextHeaderAndValue-by-nile">
            <DisplayTextHeaderAndValue
              headerText="First Name"
              valueText="Panudet"
            />
            <DisplayTextHeaderAndValue
              headerText="Last Name"
              valueText="Panumas"
            />
            <DisplayTextHeaderAndValue
              headerText=" ID"
              valueText="5503051614030"
            />
          </div>
        </Col>

        <Col xs={12} sm={12} md={6} lg={5} xl={5}>
          <div className="div-DisplayTextHeaderAndValue-by-nile">
            <DisplayTextHeaderAndValue
              headerText="Device SN"
              valueText="SN20201523"
            />
            <DisplayTextHeaderAndValue
              headerText="Data/Time"
              valueText="14 Dec 2021 11:58:22"
            />
            <DisplayTextHeaderAndValue
              headerText="Status"
              valueText={
                <img
                  src={no_image_in_systems}
                  className="status-img-train-in-card-by-nile"
                />
              }
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}
