import React from 'react'
import RebuildAllRpc from './RebuildAllRpc';
import RpcZoneID from './RpcZoneID'

export default function Systems() {
  return (
    <div>
        <RebuildAllRpc />
        <br />
        <RpcZoneID />
        <br />
    </div>
  )
}
