import { put, takeEvery, call } from "redux-saga/effects";
import {
    GET_CUT_QR_CODE_EMPLOYEE_REQUEST,
    GET_CUT_QR_CODE_EMPLOYEE_SUCCESS,
    GET_CUT_QR_CODE_EMPLOYEE_FAILED,
} from "../actions";
import * as api from "../../Service/Api_Muthermfacesense";
import axios from "axios";

function cutQRcodeFetch(payload) {
    const deviceAfterSelectRpc = api.GET_EMPLOYEE_INFO_FROM_QRCODE(payload);
    return axios(deviceAfterSelectRpc)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  function* handleGetCutQRCode({payload}) {
    if(payload === null){
      yield put({
        type: GET_CUT_QR_CODE_EMPLOYEE_FAILED,
        cut_employee_code : null
      });
    } else {
      try {
        const responseCutQrCodeEmployee = yield call(cutQRcodeFetch , payload);
        yield put({
          type: GET_CUT_QR_CODE_EMPLOYEE_SUCCESS,
          cut_employee_code: responseCutQrCodeEmployee.employee_code
        });
      } catch (err) {
        yield put({
          type: GET_CUT_QR_CODE_EMPLOYEE_FAILED,
          cut_employee_code : null
        });
      }
    }
  }
  
  function* cutQRCodeEmployee() {
    yield takeEvery(GET_CUT_QR_CODE_EMPLOYEE_REQUEST, handleGetCutQRCode);
  }
  
  export default cutQRCodeEmployee;