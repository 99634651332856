import React, { useEffect, useState } from "react";
import axios from "axios";
import * as api from "../../../Service/Api_Muthermfacesense";
import { Spin } from "antd";
import DisplayComponents from "./DisplayComponents";
import EditComponents from "./EditComponents";

export default function Announcement({ device_serial_number }) {
  const token = localStorage.getItem("token");
  const [loadingAnnouncement, setLoadingAnnouncement] = useState(false);
  const [edit, setEdit] = useState(false);
  const [defaultAnnouncement, setDefaultAnnouncement] = useState([]);
  const [announcement, setAnnouncement] = useState([]);
  const [newInputAnnouncement, setNewInputAnnouncement] = useState(null);
  const [errorInput, setErrorInput] = useState(false);

  useEffect(() => {
    if (edit === false) {
      setAnnouncement(defaultAnnouncement);
      setNewInputAnnouncement(null);
      setErrorInput(false);
    }
    return () => {};
  }, [edit]);
  // console.log(newInputAnnouncement);
  // console.log(edit)

  useEffect(() => {
    getAnnouncement();

    return () => {};
  }, [device_serial_number]);

  function getAnnouncement() {
    setDefaultAnnouncement(["ทดสอบประกาศ 1", "ทดสอบประกาศ 2"]);
    setAnnouncement(["ทดสอบประกาศ 1", "ทดสอบประกาศ 2"]);
    //     setLoadingAnnouncement(true)
    //     const url_get = api.GET_ANNOUNCEMENT_DEVICE_ONE(token , device_serial_number)
    //    axios(url_get)
    //    .then( res => {
    //     setLoadingAnnouncement(false)
    //        console.log(res.data);
    //    })
    //    .catch( error => {
    //     setLoadingAnnouncement(false)
    //    })
  }

  function removeAnnouncement(index) {
    const values = [...announcement];
    values.splice(index, 1);
    setAnnouncement(values);
  }

  function EditAnnouncement(index, value) {
    const values = [...announcement];
    values[index] = value;
    setAnnouncement(values);
  }

  function addNewAnnouncement() {
    if (!newInputAnnouncement || newInputAnnouncement === null) {
      setErrorInput(true);
    } else {
      setErrorInput(false);
      const values = [...announcement];
      values.push(newInputAnnouncement);
      setAnnouncement(values);
      setNewInputAnnouncement(null);
    }
  }

  function submitNewAnnouncement() {
    console.log(announcement);
  }

  return (
    <div>
      {loadingAnnouncement === true && (
        <Spin size="large" className="spin-center-display" />
      )}
      {loadingAnnouncement === false && (
        <div>
          <EditComponents
            announcement={announcement}
            setEdit={setEdit}
            edit={edit}
            removeAnnouncement={removeAnnouncement}
            newInputAnnouncement={newInputAnnouncement}
            setNewInputAnnouncement={setNewInputAnnouncement}
            addNewAnnouncement={addNewAnnouncement}
            errorInput={errorInput}
            EditAnnouncement={EditAnnouncement}
            submitNewAnnouncement={submitNewAnnouncement}
          />
          {announcement.length !== 0 && (
            <DisplayComponents announcement={announcement} />
          )}
        </div>
      )}
    </div>
  );
}
