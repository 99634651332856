import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import axios from "axios";
import { useHistory } from "react-router-dom";
import * as api from "../../Service/Api_Muthermfacesense";
import MetaTags from "react-meta-tags";
import { logo_remove_bg } from "../../assets/images";
import DisplayCardLg from "./DisplayCardLg";
import DisplayCardSm from "./DisplayCardSm";
import DisplayTemp from "./DisplayTemp";
import SwitchToggleComponents from "../../components/SwitchToggleComponents";
import ButtonPrimary from "../../components/ButtonPrimary";
import UserIHR from "./UserIHR";
import ScanQRCode from "./ScanQRCode";
import ConvertTimezoneLocal from "../../components/ConvertTimezoneLocal";
import { useDispatch, useSelector } from "react-redux";
import { getCutQrcodeEmployee } from "../../redux/actions";
import NotificationAlertAntd from "../../components/NotificationAlertAntd";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import ConsentModal from './ConsentModal';

var Ip = require("../../Service/Ip");

const url_string = window.location.href;
const url = new URL(url_string);
const logDataUser = url.searchParams.get("log");

export default function TrainImageFromActivities_Public() {
  const history = useHistory();
  const dispatch = useDispatch();
  const qrCodeCut = useSelector((state) => state.qrCodeCut);
  const [switchSelect, setSwitchSelect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ihr_code_input, setIhr_code_input] = useState(null);
  const [featureInfo, setFeatureInfo] = useState({
    img: null,
    _id: null,
    created_at: null,
    device_sn: null,
  });
  const [userInfo, setUserInfo] = useState({
    employee_id: null,
    firstname: null,
    lastname: null,
    code: null,
    factory: null,
  });

  useEffect(() => {
    findFeatureInfo();
    return () => {};
  }, [logDataUser]);

  function findFeatureInfo() {
    const getFeature = api.GET_FEATURE_INFO(logDataUser);
    axios(getFeature)
      .then((res) => {
        setFeatureInfo({
          ...featureInfo,
          img: res.data.message.img,
          _id: res.data.message._id.$oid,
          created_at: res.data.message.created_at.$date,
          device_sn: res.data.message.device_sn,
        });
      })
      .catch((error) => {
        // console.log(error.response);
      });
  }

  useEffect(() => {
    if (qrCodeCut.cut_employee_code !== null) {
      const codeEmployee = api.GET_EMPLOYEE_INFO_FROM_CODE(
        qrCodeCut.cut_employee_code
      );
      axios(codeEmployee)
        .then((res) => {
          if (res.data.employee === null) {
            NotificationAlertAntd("info", "ไม่พบผู้ใช้งานในระบบ");
          } else {
            setUserInfo({
              ...userInfo,
              employee_id: res.data.employee.employee_id,
              firstname: res.data.employee.first_name,
              lastname: res.data.employee.last_name,
              // factory: res.data.employee. ,
              code: res.data.employee.code,
            });
          }
        })
        .catch((error) => {
          NotificationAlertAntd("error", "Internet error");
        });
    } else {
      setIhr_code_input(null);
      setUserInfo({
        ...userInfo,
        employee_id: null,
        firstname: null,
        lastname: null,
        factory: null,
        code: null,
      });
    }
    return () => {};
  }, [qrCodeCut]);

  const handleChange = (e) => {
    setSwitchSelect(e.target.checked);
    setIhr_code_input(null);
  };

  function trainEmployeeFeature() {
    const add = api.ADD_EMPLOYEE_FEATURE_NO_TOKEN(
      userInfo.employee_id,
      featureInfo._id
    );
    axios(add)
      .then((res) => {
        setLoading(false);
        history.push("/self-service-success");
      })
      .catch((error) => {
        setLoading(false);
        NotificationAlertAntd("error", "เทรนไม่สำเร็จ");
      });
    // setLoading(true)
    // history.push("/self-service-success");
  }

  return (
    <div>
      <MetaTags>
        <title>Smart Visitor</title>
      </MetaTags>
      <div className="header-train-image-from-activities-public-page">
        <img
          className="img-header-train-image-from-activities-public-page"
          style={{ height: "50px", padding: "5px" }}
          src={logo_remove_bg}
          alt="logo_remove_bg"
        />
      </div>
      <br />
      <p className="text-header-train-image-from-activities-public-page">
        {" "}
        Smart Visitor{" "}
      </p>
      <DisplayCardLg
        userInfo={userInfo}
        imageFeature={`${Ip.getImagePath()}imagestatic/${featureInfo.img}`}
        featureInfo={featureInfo}
        created_at={
          featureInfo.created_at
            ? ConvertTimezoneLocal(featureInfo.created_at)
            : "N/A"
        }
      />
      <DisplayCardSm
        userInfo={userInfo}
        imageFeature={`${Ip.getImagePath()}imagestatic/${featureInfo.img}`}
        featureInfo={featureInfo}
        created_at={
          featureInfo.created_at
            ? ConvertTimezoneLocal(featureInfo.created_at)
            : "N/A"
        }
      />
      {/* <DisplayTemp tempData={37.5} /> */}

      <div className="div-line-color" />
      {userInfo.firstname === null && (
        <>
          <div
            style={{
              textAlign: "center",
              backgroundColor: "#FFF",
              padding: "10px",
            }}
          >
            <SwitchToggleComponents
              checked={switchSelect}
              textRight="สแกน QR code"
              textLeft="กรอก User ihr"
              onChange={handleChange}
              offstyle="btn-danger"
              onstyle="btn-success"
            />
            <p className="add-font-family" style={{ color: "red" }}>
              กรุณากรอก User ihr ( User ที่ใช้ลางาน ) หรือสแกน QR Code
              ด้วยบัตรพนักงาน เพื่อยืนยันตัวตน
            </p>
          </div>
          <div className="div-line-color" />
          {switchSelect === false && (
            <UserIHR
              switchSelect={switchSelect}
              userInfo={userInfo}
              setUserInfo={setUserInfo}
              ihr_code_input={ihr_code_input}
              setIhr_code_input={setIhr_code_input}
            />
          )}
          {switchSelect === true && (
            <ScanQRCode
              switchSelect={switchSelect}
              userInfo={userInfo}
              setUserInfo={setUserInfo}
            />
          )}
        </>
      )}

      {userInfo.firstname !== null && (
        <Container fluid>
          <Row>
            <Col>
              <br /> <br />
              <p
                className="add-font-family"
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#707070",
                }}
              >
                <AiOutlineQuestionCircle
                  size="25px"
                  style={{ marginRight: "10px", color: "#1890ff" }}
                />
                ยืนยันการเทรนรูป
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs="0" sm="0" md="3" lg="4" xl="4" />
            <Col xs="12" sm="6" md="3" lg="2" xl="2">
              <br />
              <ButtonPrimary
                className="button2-save-create-new-employee-by-nile"
                text="ยืนยัน"
                onClick={() => trainEmployeeFeature()}
                loading={loading}
              />
            </Col>
            <Col xs="12" sm="6" md="3" lg="2" xl="2">
              <br />
              <ButtonPrimary
                className="icon-txt-button2-by-nile"
                text="ไม่ใช่ฉัน"
                onClick={() => dispatch(getCutQrcodeEmployee(null))}
              />
            </Col>
            <Col xs="0" sm="0" md="3" lg="4" xl="4" />
          </Row>
        </Container>
      )}

      <ConsentModal />
    </div>
  );
}
