import { useState, useEffect } from "react";
import { Row, Col, Container } from "reactstrap";
import { logo } from "../assets/icons";
import { useHistory } from "react-router-dom";
import ButtonSetting from "./ButtonSetting";
import { listMenuAll, listMenuUser } from "./ListMenu";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Header() {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const [isRoot, setIsRoot] = useState(false);
  const { UserInformation } = useSelector((state) => state);

  const styleRoot = { height: "54px" };
  const styleUser = { height: "54px", marginLeft: "20%" };

  useEffect(() => {
    if (UserInformation) {
      setIsRoot(UserInformation.is_root);
    }

    return () => {};
  }, [UserInformation]);

  function getBasePath() {
    let pathOne = Object.values(params).reduce(
      (path, param) => path.replace("/" + param, ""),
      location.pathname
    );
    let pathTwo = location.pathname.slice(
      0,
      location.pathname.lastIndexOf("/")
    );
    if (pathTwo) return pathTwo;
    else return pathOne;
  }

  const basePath = getBasePath();

  function routePath(params) {
    history.push(params);
  }

  function checkPathActive(paramsPath) {
    var classPathStyle = "nav-button";
    paramsPath.map((path) => {
      if (basePath === path) {
        classPathStyle = "active-navbar";
      }
    });
    return classPathStyle;
  }

  return (
    <div>
      <Row>
        <div
          className={isRoot === true ? "header-bar-lg" : "header-bar-lg-user"}
        >
          <div className="nav-box">
            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
              <div className="nav-title">
                <img src={logo} alt="LogoImg" />
                <h5 className="nav-title-logo">Factory Management</h5>
              </div>
            </Col>

            <Col xs={7} sm={7} md={7} lg={7} xl={7}>
              {isRoot === true && (
                <div style={styleRoot}>
                  {listMenuAll.map((data, index) => {
                    let classActive = checkPathActive(data.activePathMenubar);
                    return (
                      <button
                        key={index}
                        className={classActive}
                        onClick={() => routePath(data.pathnameMenu)}
                      >
                        {data.nameMenu}
                      </button>
                    );
                  })}
                </div>
              )}
              {isRoot === false && (
                <div style={styleUser}>
                  {listMenuUser.map((data, index) => {
                    let classActive = checkPathActive(data.activePathMenubar);
                    return (
                      <button
                        key={index}
                        className={classActive}
                        onClick={() => routePath(data.pathnameMenu)}
                      >
                        {data.nameMenu}
                      </button>
                    );
                  })}
                </div>
              )}
            </Col>

            <Col xs={1} sm={1} md={1} lg={1} xl={1}>
              <ButtonSetting
                classNameButtonSetting={checkPathActive(["/setting"])}
                routePath={routePath}
              />
            </Col>
          </div>
        </div>
      </Row>
    </div>
  );
}
