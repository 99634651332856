import React, { useEffect, useState } from "react";
import axios from "axios";
import * as api from "../../Service/Api_Muthermfacesense";
import { Input, Modal } from "antd";
import ButtonPrimary from "../../components/ButtonPrimary";
import { Row, Col } from "reactstrap";
import { CgDanger } from "react-icons/cg";
import NotificationAlertAntd from '../../components/NotificationAlertAntd';

const { TextArea } = Input;

export default function ConfigDevice({ device_serial_number }) {
  const token = localStorage.getItem("token");
  const [defaultConfig, setDefaultConfig] = useState(null);
  const [config, setConfig] = useState(null);
  const [btnEditConfig, setBtnEditConfig] = useState(true);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getConfigDevice();

    return () => {};
  }, [device_serial_number]);

  function getConfigDevice() {
    const getConfig = api.GET_CONFIG_DEVICE_ONE(token, device_serial_number);
    axios(getConfig)
      .then((res) => {
        if (res.data.result !== null) {
          const dataConfig = {
            config: res.data.result.config,
          };
          setDefaultConfig(JSON.stringify(dataConfig, undefined, 2));
          setConfig(JSON.stringify(dataConfig, undefined, 2));
        } else {
          const dataConfig = {
            config: {},
          };
          setDefaultConfig(JSON.stringify(dataConfig, undefined, 2));
          setConfig(JSON.stringify(dataConfig, undefined, 2));
        }
      })
      .catch((error) => {});
  }

  function handleConfig(e) {
    setConfig(e.target.value);
  }

  function cancelConfig() {
    setConfig(defaultConfig);
    setBtnEditConfig(true);
    setVisible(false);
  }

  const parseJSON = (inputString ) => {
    if (inputString) {
      try {
        return JSON.parse(inputString);
      } catch (e) {
        return e;
      }
    }
  };

  function submitConfig() {
  const data = parseJSON(config);
  if( data && data.message === "Unexpected end of JSON input"){
    NotificationAlertAntd("error","Please check command JSON format")
  } else {
    setLoading(true)
    const upsertConfig = api.SEND_UPSERT_CONFIG_DEVICE_ONE(token , device_serial_number , data)
    axios(upsertConfig)
    .then( res => {
      setLoading(false)
      getConfigDevice()
      setBtnEditConfig(true);
      setVisible(false);
      NotificationAlertAntd("success","Edit config success")
    })
    .catch( error => {
      setLoading(false)
      if(error){
        if(error.response){
          if(error.response.status === 422){
            NotificationAlertAntd("error",error.response.statusText )
          } else {
            NotificationAlertAntd("error","Internet error" )
          }
        } else {
            NotificationAlertAntd("error","Internet error" )
        }
      } 
    })
  }
  }

  return (
    <div>
      {btnEditConfig === true && (
        <ButtonPrimary
          className="icon-txt-button2-by-nile"
          text="Edit config"
          style={{
            width: "150px",
            float: "right",
            marginBottom: "10px",
            marginRight: "10px",
          }}
          onClick={() => setBtnEditConfig(false)}
        />
      )}

      {btnEditConfig === false && (
        <ButtonPrimary
          className="icon-txt-button2-by-nile"
          text="Cancel"
          style={{
            width: "150px",
            float: "right",
            marginBottom: "10px",
            marginRight: "10px",
          }}
          onClick={() => cancelConfig()}
        />
      )}

      <TextArea
        // style={{ height:'300px' }}
        disabled={btnEditConfig}
        autoSize={true}
        value={config}
        onChange={handleConfig}
      />

      {btnEditConfig === false && (
        <ButtonPrimary
          className="btn-submit-config"
          // style={{ width:'150px', color:"#134e96" ,marginBottom:'10px' , marginRight:'10px' }}
          text="Submit config"
          onClick={() => setVisible(true)}
        />
      )}

      <Modal
        centered
        closable={false}
        visible={visible}
        onOk={() => cancelConfig()}
        onCancel={() => cancelConfig()}
        footer={null}
        // width={800}
        className="modalStyle-ModalSelectATK"
      >
        <p className="txt-sub-header1-by-nile">
          {" "}
          <CgDanger color="red" size="25px" /> Confirm edit config{" "}
        </p>
        <Row>
          <Col xs="12" sm="12" md="6" lg="6" xl="6">
            <br />
            <ButtonPrimary
              className="button2-save-create-new-employee-by-nile"
              text="Confirm"
              loading={loading}
              onClick={() => submitConfig()}
            />
          </Col>
          <Col xs="12" sm="12" md="6" lg="6" xl="6">
            <br />
            <ButtonPrimary
              className="icon-txt-button2-by-nile"
              text="Cancel"
              onClick={() => cancelConfig()}
            />
          </Col>
        </Row>
      </Modal>
    </div>
  );
}
