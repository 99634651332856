import * as types from '../actions'

const INITIAL_STATE = {
  page: 'start page',
}

function startReduxPage(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case types.START_REDUX_TEST:
        return { ...state, page: payload }
    default:
      return state
  }
}

export default startReduxPage