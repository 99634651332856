import * as types from "../actions";

const initialState = {
  cut_employee_code: null,
};

const qrCodeCut = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CUT_QR_CODE_EMPLOYEE_REQUEST:
      return { ...state, cut_employee_code: null };
    case types.GET_CUT_QR_CODE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        cut_employee_code: action.cut_employee_code,
      };
    case types.GET_CUT_QR_CODE_EMPLOYEE_FAILED:
      return {
        ...state,
        cut_employee_code: null,
      };
    default:
      return state;
  }
};

export default qrCodeCut;
