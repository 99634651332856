import React from "react";

export default function TextField({
  title,
  placeholder,
  error,
  fontSize,
  color,
  fontWeight,
  onChange,
  type ,
  styleError
}) {
  var colorMap = color ? { color: color } : {};
  var fontSizerMap = fontSize ? { fontSize: fontSize } : {};
  var fonWeightMap = fontWeight ? { fontWeight: fontWeight } : {};

  return (
    <div className="row-textfield">
      <p
        style={{ ...colorMap, ...fontSizerMap, ...fonWeightMap }}
        className="txt-title-field"
      >
        {title}
      </p>
      <input
        id={error && "error-textfield"}
        className="text-field"
        placeholder={placeholder}
        onChange={onChange}
        type={type}
      />
      {error && <p style={styleError ? styleError : null} >{error}</p>}
    </div>
  );
}
