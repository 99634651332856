import React from "react";

export default function InputNoHeader({
  className,
  onChange,
  placeholder,
  value,
  style
}) {
  const mgTop = {
    marginTop: "10px",
  };
  return (
    <div style={mgTop}>
      <input
        className={className ? className : "text-field"}
        placeholder={placeholder}
        style={style}
        onChange={onChange}
        value={value}
      />
    </div>
  );
}
