import React from "react";
const listUtherm = {
  0: {
    0: [
      "NT0052",
      "NT0053",
      "NT0068",
      "NT0069",
      "NT0068",
      "NT0069",
      "NT0051",
      "NT0057",
      "NT0054",
      "NT0055",
      "NT0062",
      "NT0063",
      "NT0070",
      "NT0071",
      "NT0066",
      "NT0065",
      "NT0075",
      "NT0076",
      "NT0079",
      "NT0050",
      "NT0077",
      "NT0078",
      "NT0072",
      "NT0073",
      "NT0060",
      "NT0061",
    ],
    1: ["NT0052", "NT0053"], //โรงงานบางนา
    2: ["NT0068", "NT0069"], //โรงงานลำพูน
    3: ["NT0051", "NT0057"], //โรงงานศรีราชา
    4: ["NT0054", "NT0055"], //โรงงานหาดใหญ่
    5: ["NT0062", "NT0063"], //KRF โรงงานโคกกรวด
    6: ["NT0070", "NT0071"], //โรงงานพิษณุโลก
    7: ["NT0066", "NT0065"], //โรงงานธารเกษม
    8: ["NT0075", "NT0076"], //RBF โรงงานราชบุรี
    9: ["NT0079", "NT0050"], // โรงงานหนองแค
    10: ["NT0077", "NT0078"], //TRF โรงงานท่าเรือ
    11: ["NT0072", "NT0073"], // โรงงานปักธงชัย
    12: ["NT0060", "NT0061"], // โรงงานขอนแก่น
  },
  1: {
    0: [
      "NT0077",
      "NT0078",
      "NT0077",
      "NT0078",
      "NT0079",
      "NT0050",
      "NT0075",
      "NT0076",
      "NT0066",
      "NT0065",
      "NT0052",
      "NT0053",
    ],
    1: ["NT0077", "NT0078"],
    2: ["NT0079", "NT0050"],
    3: ["NT0075", "NT0076"],
    4: ["NT0066", "NT0065"],
    5: ["NT0052", "NT0053"],
  },
  2: {
    0: ["NT0068", "NT0069", "NT0070", "NT0071"],
    1: ["NT0068", "NT0069"],
    2: ["NT0070", "NT0071"],
  },
  3: {
    0: ["NT0060", "NT0061", "NT0072", "NT0073", "NT0062", "NT0063"],
    1: ["NT0060", "NT0061"],
    2: ["NT0072", "NT0073"],
    3: ["NT0062", "NT0063"],
  },
  4: {
    0: ["NT0054", "NT0055"],
    1: ["NT0054", "NT0055"],
  },
  5: {
    0: ["NT0051", "NT0057"],
    1: ["NT0051", "NT0057"],
  },
};
const listFactory = {
  0: [
    "ทั้งหมด",
    "โรงงานบางนา กม.21",
    "โรงงานลำพูน",
    "โรงงานศรีราชา",
    "โรงงานหาดใหญ่",
    "โรงงานโคกกรวด",
    "โรงงานพิษณุโลก",
    "โรงงานธารเกษม",
    "โรงงานราชบุรี",
    "โรงงานหนองแค",
    "โรงงานท่าเรือ",
    "โรงงานปักธงชัย",
    "โรงงานขอนแก่น",
  ],
  1: [
    "ทั้งหมด",
    "โรงงานท่าเรือ",
    "โรงงานหนองแค",
    "โรงงานราชบุรี",
    "โรงงานธารเกษม",
    "โรงงานบางนา กม.21",
  ],
  2: ["ทั้งหมด", "โรงงานลำพูน", "โรงงานพิษณุโลก"],
  3: ["ทั้งหมด", "โรงงานขอนแก่น", "โรงงานปักธงชัย", "โรงงานโคกกรวด"],
  4: ["ทั้งหมด", "โรงงานหาดใหญ่"],
  5: ["ทั้งหมด", "โรงงานศรีราชา"],
};
const DataFactory = {
  data: {
    0: [
      {
        province: "พระนครศรีอยุธยา",
        number_province: 0,
        region: "ภาคกลาง",
        factory: [
          {
            factory: "โรงงานท่าเรือ",
            address:
              "63 หมู่ที่7 ถ.ท่าเรือ-ท่าลาน ต.จาปา อ.ท่าเรือ จ.พระนครศรีอยุธยา 13130 โทร (035)341959 โทรสาร (035)343284",
            device: ["NT0077", "NT0078"],
          },
        ],
      },
      {
        province: "สระบุรี",
        region: "ภาคกลาง",
        number_province: 1,
        factory: [
          {
            factory: "โรงงานหนองแค",
            address:
              "57 หมู่ที่5 ถ.พหลโยธิน ต.หนองไข่น้ำ อ.หนองแค จ.สระบุรี 18140 โทร (036)383887-91 โทรสาร (036)383894",
            device: ["NT0079", "NT0050"],
          },
          {
            factory: "โรงงานธารเกษม",
            address:
              "61/2 หมู่ที่ 2 ถ.สายตรี ต.ธารเกษม อ.พระพุทธบาท จ.สระบุรี 18120 โทร (036)368000-3 โทรสาร (036)368015",
            device: ["NT0066", "NT0065"],
          },
        ],
      },
      {
        province: "ราชบุรี",
        region: "ภาคกลาง",
        number_province: 2,
        factory: [
          {
            factory: "โรงงานราชบุรี",
            address:
              "80/3 หมู่ที่ 8 ถ.เพชรเกษม ต.ดอนกระเบื้อง อ.บ้านโป่ง จ.ราชบุรี 70110 โทร (032)925500-6 โทรสาร (032)925521",
            device: ["NT0075", "NT0076"],
          },
        ],
      },
      {
        province: "สมุทรปราการ",
        region: "ภาคกลาง",
        number_province: 3,
        factory: [
          {
            factory: "โรงงานบางนา กม.21",
            address:
              "57 หมู่ที่ 1 ถ.บางนาขตราด ต.ศรีษะจรเข้ใหญ่ อ.บางเสาธง จ.สมุทรปราการ 10540 ",
            device: ["NT0052", "NT0053"],
          },
        ],
      },
      {
        province: "ลำพูน",
        region: "ภาคเหนือ",
        number_province: 4,
        factory: [
          {
            factory: "โรงงานลำพูน",
            address:
              "89 ถ.ลำปาง-เชียงใหม่ ต.เวียงยอง อ.เมือง จ.ลำพูน 51000 โทร. (053)525139-142 โทรสาร. (053)525147",
            device: ["NT0068", "NT0069"],
          },
        ],
      },
      {
        province: "พิษณุโลก",
        region: "ภาคเหนือ",
        number_province: 5,
        factory: [
          {
            factory: "โรงงานพิษณุโลก",
            address:
              "178 หมู่ที่ 4 ถ.พิษณุโลก-หล่มสัก กม.9 ต.สมอแข อ.เมืองพิษณุโลก จ.พิษณุโลก 65000 โทร(055)987529-33 โทรสาร (055)987534",
            device: ["NT0070", "NT0071"],
          },
        ],
      },
      {
        province: "ขอนแก่น",
        region: "ภาคอีสาน",
        number_province: 6,
        factory: [
          {
            factory: "โรงงานขอนแก่น",
            address:
              "555 หมู่ที่8 ถ.มิตรภาพ ต.ท่าพระ อ.เมืองขอนแก่น จ.ขอนแก่น 40260 โทร (043)262698-9",
            device: ["NT0060", "NT0061"],
          },
        ],
      },
      {
        province: "นครราชสีมา",
        region: "ภาคอีสาน",
        number_province: 7,
        factory: [
          {
            factory: "โรงงานปักธงชัย",
            address:
              "250 หมู่ที่ 6 ถ.สี่คิ้ว-โชคชัย ต.ตะคุ อ.ปักธงชัย จ.นครราชสีมา 30150 โทร (044)284111 โทรสาร (044)284811",
            device: ["NT0072", "NT0073"],
          },
          {
            factory: "โรงงานโคกกรวด",
            address:
              "86 หมู่ที่6 ถ.มิตรภาพ กม.ที่ 16-17 ต.โคกกรวด อ.เมือง จ.นครราชสีมา 30280 โทร. (044)291-277 โทรสาร (044)395-337",
            device: ["NT0062", "NT0063"],
          },
        ],
      },
      {
        province: "สงขลา",
        region: "ภาคใต้",
        number_province: 8,
        factory: [
          {
            factory: "โรงงานหาดใหญ่",
            address:
              "70,73 หมู่ที่ 8 ถ.กาญจนวนิช ต.น้ำน้อย อ.หาดใหญ่ จ.สงขลา 90110 โทร(074)200 550-99 โทรสาร (074)448-977-9",
            device: ["NT0054", "NT0055"],
          },
        ],
      },
      {
        province: "ชลบุรี",
        region: "ภาคตะวันออก",
        number_province: 9,
        factory: [
          {
            factory: "โรงงานศรีราชา",
            address:
              "242/2 หมู่ 2 ต.บางพระ อ.ศรีราชา จ.ชลบุรี 20210 โทร. (038)-341127-31 แฟกซ์. (038)-341277",
            device: ["NT0051", "NT0057"],
          },
        ],
      },
    ],
    1: [
      {
        province: "พระนครศรีอยุธยา",
        number_province: 0,
        region: "ภาคกลาง",
        factory: [
          {
            factory: "โรงงานท่าเรือ",
            address:
              "63 หมู่ที่7 ถ.ท่าเรือ-ท่าลาน ต.จาปา อ.ท่าเรือ จ.พระนครศรีอยุธยา 13130 โทร (035)341959 โทรสาร (035)343284",
            device: ["NT0077", "NT0078"],
          },
        ],
      },
      {
        province: "สระบุรี",
        region: "ภาคกลาง",
        number_province: 1,
        factory: [
          {
            factory: "โรงงานหนองแค",
            address:
              "57 หมู่ที่5 ถ.พหลโยธิน ต.หนองไข่น้ำ อ.หนองแค จ.สระบุรี 18140 โทร (036)383887-91 โทรสาร (036)383894",
            device: ["NT0079", "NT0050"],
          },
          {
            factory: "โรงงานธารเกษม",
            address:
              "61/2 หมู่ที่ 2 ถ.สายตรี ต.ธารเกษม อ.พระพุทธบาท จ.สระบุรี 18120 โทร (036)368000-3 โทรสาร (036)368015",
            device: ["NT0066", "NT0065"],
          },
        ],
      },
      {
        province: "ราชบุรี",
        region: "ภาคกลาง",
        number_province: 2,
        factory: [
          {
            factory: "โรงงานราชบุรี",
            address:
              "80/3 หมู่ที่ 8 ถ.เพชรเกษม ต.ดอนกระเบื้อง อ.บ้านโป่ง จ.ราชบุรี 70110 โทร (032)925500-6 โทรสาร (032)925521",
            device: ["NT0075", "NT0076"],
          },
        ],
      },
      {
        province: "สมุทรปราการ",
        region: "ภาคกลาง",
        number_province: 3,
        factory: [
          {
            factory: "โรงงานบางนา กม.21",
            address:
              "57 หมู่ที่ 1 ถ.บางนาขตราด ต.ศรีษะจรเข้ใหญ่ อ.บางเสาธง จ.สมุทรปราการ 10540 ",
            device: ["NT0052", "NT0053"],
          },
        ],
      },
    ],
    2: [
      {
        province: "ลำพูน",
        region: "ภาคเหนือ",
        number_province: 0,
        factory: [
          {
            factory: "โรงงานลำพูน",
            address:
              "89 ถ.ลำปาง-เชียงใหม่ ต.เวียงยอง อ.เมือง จ.ลำพูน 51000 โทร. (053)525139-142 โทรสาร. (053)525147",
            device: ["NT0068", "NT0069"],
          },
        ],
      },
      {
        province: "พิษณุโลก",
        region: "ภาคเหนือ",
        number_province: 1,
        factory: [
          {
            factory: "โรงงานพิษณุโลก",
            address:
              "178 หมู่ที่ 4 ถ.พิษณุโลก-หล่มสัก กม.9 ต.สมอแข อ.เมืองพิษณุโลก จ.พิษณุโลก 65000 โทร(055)987529-33 โทรสาร (055)987534",
            device: ["NT0070", "NT0071"],
          },
        ],
      },
    ],
    3: [
      {
        province: "ขอนแก่น",
        region: "ภาคอีสาน",
        number_province: 0,
        factory: [
          {
            factory: "โรงงานขอนแก่น",
            address:
              "555 หมู่ที่8 ถ.มิตรภาพ ต.ท่าพระ อ.เมืองขอนแก่น จ.ขอนแก่น 40260 โทร (043)262698-9",
            device: ["NT0060", "NT0061"],
          },
        ],
      },
      {
        province: "นครราชสีมา",
        region: "ภาคอีสาน",
        number_province: 1,
        factory: [
          {
            factory: "โรงงานปักธงชัย",
            address:
              "250 หมู่ที่ 6 ถ.สี่คิ้ว-โชคชัย ต.ตะคุ อ.ปักธงชัย จ.นครราชสีมา 30150 โทร (044)284111 โทรสาร (044)284811",
            device: ["NT0072", "NT0073"],
          },
          {
            factory: "โรงงานโคกกรวด",
            address:
              "86 หมู่ที่6 ถ.มิตรภาพ กม.ที่ 16-17 ต.โคกกรวด อ.เมือง จ.นครราชสีมา 30280 โทร. (044)291-277 โทรสาร (044)395-337",
            device: ["NT0062", "NT0063"],
          },
        ],
      },
    ],
    4: [
      {
        province: "สงขลา",
        region: "ภาคใต้",
        number_province: 0,
        factory: [
          {
            factory: "โรงงานหาดใหญ่",
            address:
              "70,73 หมู่ที่ 8 ถ.กาญจนวนิช ต.น้ำน้อย อ.หาดใหญ่ จ.สงขลา 90110 โทร(074)200 550-99 โทรสาร (074)448-977-9",
            device: ["NT0054", "NT0055"],
          },
        ],
      },
    ],
    5: [
      {
        province: "ชลบุรี",
        region: "ภาคตะวันออก",
        number_province: 0,
        factory: [
          {
            factory: "โรงงานศรีราชา",
            address:
              "242/2 หมู่ 2 ต.บางพระ อ.ศรีราชา จ.ชลบุรี 20210 โทร. (038)-341127-31 แฟกซ์. (038)-341277",
            device: ["NT0051", "NT0057"],
          },
        ],
      },
    ],
  },
};
export { DataFactory };
