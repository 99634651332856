import React, { useState , useEffect } from "react";
import ButtonPrimary from "./ButtonPrimary";
import { MdArrowDropDown } from "react-icons/md/index";
import { BiFilterAlt } from "react-icons/bi";
export default function Dropdown({
  items,
  itemCallback,
  hasFilter,
  styles,
  isButtom,
  id_Dropdown,
  name_Dropdown
  // initState,
}) {
  // var init = initState && items[0];
  // console.log(init);
  // const [itemNow, setItem] = useState(items[0]);
  const [itemNow, setItem] = useState(null);
  var top = isButtom ? { bottom: "3.6rem" } : { top: "3.6rem" };
  var height = isButtom && { height: "auto" };

useEffect(() => {
 if(items.length > 0){
  setItem(items[0][name_Dropdown])
  itemCallback(items[0][name_Dropdown], items[0][id_Dropdown]);
 }

  return () => {
    
  }
}, [items])

// useEffect(() => {
//   itemCallback(items[0][name_Dropdown], items[0][id_Dropdown]);

//   return () => {
    
//   }
// }, [])


  return (
    <div className="box-dropdown-filter-cover">
      <ButtonPrimary
        className="box-dropdown-filter"
        text={
          <>
            {hasFilter ? <BiFilterAlt style={{ fontSize: "1.3rem" }} /> : <></>}
            {itemNow}
            <MdArrowDropDown style={{ fontSize: "1.7rem" }} />
          </>
        }
      />
      <div
        className="box-dropdown-filter-selector"
        style={{ ...styles, ...top, ...height }}
      >
        <ul>
          {items.map((item, index) => {
            return (
              <li
                key={index}
                onClick={() => {
                  setItem(item[name_Dropdown]);
                  itemCallback(item[name_Dropdown], item[id_Dropdown]);
                }}
              >
                <p className="txt-drop-down">{item[name_Dropdown]}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
