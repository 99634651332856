import React, { useState, useEffect } from "react";
import TableLogComponents from "../../components/TableLogComponents";
import { useHistory } from "react-router-dom";
import { BiDotsVerticalRounded } from "react-icons/bi";
import axios from "axios";
import { Pagination } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeeRequest } from "../../redux/actions";
import FindFactoryFromID from "../../components/FindFactoryFromID";
import FindDepartmentFromID from "../../components/FindDepartmentFromID";

export default function TableStudents({
  employee,
  page_limit,
  setPage_limit,
  search,
}) {
  var history = useHistory();
  const dispatch = useDispatch();

  // console.log(Department);

  const rowTableLogRealtime = [
    {
      title: "ID",
      dataIndex: "employee_id",
      key: "employee_id",
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Firstname",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Lastname",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Factory",
      render: (data) => <p className="mb-0px">{FindFactoryFromID(data.factory_id)}</p>,
    },
    {
      title: "Department",
      render: (data) => <p className="mb-0px">{FindDepartmentFromID(data.department_id)}</p>,
    },
    {
      render: (data) => (
        <div
          className="cursor-pointer"
          onClick={() => history.push("/employee/personal/" + data.employee_id)}
        >
          <BiDotsVerticalRounded size={25} />
        </div>
      ),
    },
  ];

  function changePagination(page, pageSize) {
    const payload = {
      page: page,
      limit: pageSize,
      ...search,
    };
    dispatch(getEmployeeRequest(payload));
    setPage_limit({
      ...page_limit,
      page: page,
      page_Size: pageSize,
    });
  }

  return (
    <div className="box-table-by-nile">
      <TableLogComponents
        columns={rowTableLogRealtime}
        dataSource={employee.employee}
        loading={employee.loading}
      />
      <br />
      <div style={{ float: "right" }}>
        <Pagination
          current={page_limit.page}
          total={page_limit.limit}
          onChange={changePagination}
        />
      </div>
      <p className="text-header-date-picker" style={{ marginTop: "10px" }}>
        total data : {page_limit.limit}
      </p>
      <br />
    </div>
  );
}
