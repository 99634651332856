import React from "react";
import { Col, Row } from "reactstrap";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  registerables as registerablesJS
} from "chart.js";

import { Bar} from "react-chartjs-2";

ChartJS.register(...registerablesJS);

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip
);

ChartJS.defaults.font.family = "Kanit";
// ChartJS.defaults.options.plugins.legend.title.position = "end";
// ChartJS.defaults.options.plugins.legend.align = "end";

const listColor = [
  "#EEAD85",
  "#79C1D9",
  "#FE8E74",
  "#C0E5FE",
  "#FF5F81",
  "#6B80BE",
  "#A42822",
  "#85ABAF",
  "#F4D042",
  "#77A15E",
  "#FB6C44",
  "#8190E7",
  "#EBCEC8",
  "#8BD1B4",
  "#C081A3",
  "#B4BA7A",
  "#F9C9A0",
  "#DAFE7A",
  "#FDE490",
  "#7B7E23",
  "#DC88A5",
  "#4B7BA9",
  "#FE65DA",
  "#2D9091",
  "#B36E43",
  "#BC9DFB",
];

export default function BarchartMonth({
  month,
  lineData,
  barData,
  labels,
  isTotal,
}) {

  const data = {
    labels,
    datasets: [
      {
        label: "อุณภูมิที่ตรวจพบ",
        type: "line",
        data: lineData,
        backgroundColor: "#9A9A9A",
        borderRadius: Number.MAX_VALUE,
        barPercentage: 1,
        // borderWidth: 0.5,
        borderWidth: isTotal ? 0.5 : 4,
        borderColor: "#9A9A9A",
        borderSkipped: "false",
        categoryPercentage: 0.5,
        //   order: 1,
        //   stack: "1",
      },
      {
        label: "ทั้งหมด",
        type: "bar",
        data: barData,
        backgroundColor: listColor,
        borderRadius: {
          topLeft: Number.MAX_VALUE,
          topRight: Number.MAX_VALUE,
          bottomLeft: Number.MAX_VALUE,
          bottomRight: Number.MAX_VALUE,
        },
        // barPercentage: 1,
        barPercentage: isTotal ? 1 : 0.3,
        borderSkipped: "false",
        // barThickness:29
        categoryPercentage: 0.5,
        //   stack: "1",
        //   order: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        align: "end",
        display: false,
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart",
        align: "center",
      },
    },
    scales: {
      x: {
        // categoryPercentage: 0.5,
        // barPercentage: 0.5,
        stacked: true,
        display: false,
        ticks: {
          display: false,
        },
        title: {
          display: true,
          text: "มกราคม",
        },
        grid: {
          offset: false,
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        display: false,
        position: "right",
        title: { display: true },
        ticks: {
          display: true,
        },
      },
    },
  };
  
  return (
    <Col style={{ height: "200px" }}>
      <Row>
        <Bar height={200} options={options} data={data} />
      </Row>
      <Row className="justify-content-center">
        <p style={{ textAlign: "center" }} className="txt-sub-explanation1">
          {month}
        </p>
      </Row>
    </Col>
  );
}
