import React, { useState } from "react";
import { BiCheck } from "react-icons/bi";
import { Row, Col } from "reactstrap";

export default function SelectedField({ listImage }) {
  const [isSeleted, setIsSeleted] = useState(0);
  return (
    <div
      style={{
        textAlign: "center",
        width: "100%",
      }}
    >
      {listImage.map((item, key) => {
        return (
          <div
            style={{
              width: "150px",
              height: "100px",
              display: "inline-block",
            }}
            key={item.title}
            id={key == isSeleted ? "selected-box" : ""}
            className="box-switch-add-new-student-by-nile"
            onClick={() => {
              setIsSeleted(key);
            }}
          >
            <div
              className="box-checked"
              id={key == isSeleted ? "selected-checked" : ""}
            >
              <BiCheck color="white" />
            </div>
            <img className="img-box-switch" src={item.image}></img>
            <p className="txt-sub-explanation1-by-nile">{item.title}</p>
          </div>
        );
      })}

      {listImage[isSeleted].page}
    </div>
  );
}

// import React, { useState } from "react";
// import { BiCheck } from "react-icons/bi";
// export default function SelectedField({ listImage }) {
//   const [isSeleted, setIsSeleted] = useState(0);
//   return (
//     <div className="col">
//       <div className="row-jcct-gab2">
//         {listImage.map((item, key) => {
//           return (
//             <div
//             key={item.title}
//               id={key == isSeleted ? "selected-box" : ""}
//               className="box-switch-add-new-student"
//               onClick={() => {
//                 setIsSeleted(key);
//               }}
//             >
//               <div
//                 className="box-checked"
//                 id={key == isSeleted ? "selected-checked" : ""}
//               >
//                 <BiCheck color="white" />
//               </div>
//               <img className="img-box-switch" src={item.image}></img>
//               <p className="txt-sub-explanation1">{item.title}</p>
//             </div>
//           );
//         })}
//       </div>
//       {listImage[isSeleted].page}
//     </div>
//   );
// }
