import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import BoxAllDevice from "../../components/BoxAllDevice";
import { useDispatch, useSelector } from "react-redux";
import { getDeviceRequest } from "../../redux/actions";
import InputNormal from "../../components/InputNormal";
import ButtonPrimary from "../../components/ButtonPrimary";
import { BiSearch } from "react-icons/bi";
import { Form } from 'antd';

export default function Device() {
  const history = useHistory();
  const dispatch = useDispatch();
  const device = useSelector((state) => state.device);
  const [deviceAll, setDeviceAll] = useState([]);
  const [deviceDisplay, setDeviceDisplay] = useState([]);
  const [searchDevice, setSearchDevice] = useState(null);

  useEffect(() => {
    dispatch(getDeviceRequest());

    return () => {};
  }, []);

  useEffect(() => {
    setDeviceAll(device.device);
    setDeviceDisplay(device.device)
    return () => {};
  }, [device]);

  function searchDeviceFunc() {
    if(searchDevice === null){
      setDeviceDisplay(deviceAll)
    } else {
      const filtered = deviceAll.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.includes(searchDevice)));
      setDeviceDisplay(filtered)
    }
  }

  const onFinish = (values) => {
    searchDeviceFunc()
  };

  return (
    <Container fluid>
       <Form
      name="Form-search-device"
      onFinish={onFinish}
      autoComplete="off"
    >
      <Row>
        <Col xs={12} sm={12} md={6} lg={4} xl={4}>
          <InputNormal
            className="text-header-date-picker"
            title="Search device"
            placeholder="Name , Serial number"
            onChange={(e) =>
              e.target.value
                ? setSearchDevice(e.target.value)
                : setSearchDevice(null)
            }
          />
        </Col>

        <Col xs={6} sm={6} md={3} lg={3} xl={2}>
          <ButtonPrimary
            className="button-new-student-by-nile"
            style={{ width: "100%", marginTop: "60px" }}
            text={
              <>
                <span
                  className="text-in-button-employee"
                  style={{ color: "#134e96" }}
                >
                  Search
                </span>
                <BiSearch
                  size="25px"
                  className="icons-in-button-new-employee"
                  style={{ color: "#134e96" }}
                />
              </>
            }
            onClick={() => searchDeviceFunc()}
          />
        </Col>
      </Row>
      </Form>
      <Row>
        {deviceDisplay.map((data, index) => {
          return (
            <Col key={index} xs={12} sm={12} md={6} lg={4} xl={3}>
              <BoxAllDevice
                nameDevice={data.device_name}
                serialNumber={data.device_serial_number}
                onClick={() =>
                  history.push("/device/" + data.device_serial_number)
                }
              />
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}
