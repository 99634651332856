import * as types from "../actions";

const initialState = {
    employeeDeleted: [],
    loading: false,
    error: null,
    statusCode: null,
  };
  
  const employeeDeleted = (state = initialState, action) => {
    switch (action.type) {
      case types.GET_EMPLOYEE_DELETED_REQUEST:
        return { ...state, loading: true };
      case types.GET_EMPLOYEE_DELETED_SUCCESS:
        return {
          ...state,
          loading: false,
          employeeDeleted: action.employeeDeleted,
        };
      case types.GET_EMPLOYEE_DELETED_FAILED:
        return {
          ...state,
          loading: false,
          error: action.error,
          statusCode: action.statusCode,
        };
        case types.CLEAR_EMPLOYEE_DELETED:
            return {
              ...state,
              employeeDeleted: [],
              loading: false,
              error: null,
              statusCode: null,
            };
      default:
        return state;
    }
  };
  
  export default employeeDeleted;