import * as types from "../actions";

const initialState = {
    factory_id: null ,
    first_name: null ,
    is_root: null ,
    last_name: null ,
    public_uuid: null ,
    user_id: null ,
  };
  
  const userInformation = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DETAIL_USER_INFORMATION:
            return {
              ...state,
              factory_id: action.factory_id  ,
              first_name: action.first_name  ,
              is_root: action.is_root  ,
              last_name: action.last_name  ,
              public_uuid: action.public_uuid  ,
              user_id: action.user_id  ,
            };
      default:
        return state;
    }
  };
  
  export default userInformation;