const FactoryInRpc = [
    { id: 1, name: "BKF บางนา กม.21", rpc_id : 1 },
    { id: 2, name: "PTF โคราช ปักธงชัย", rpc_id : 3 },
    { id: 3, name: "RBF ราชบุรี บ้านโป่ง", rpc_id : 1 },
    { id: 4, name: "KBF ปราจีนบุรี กบิน", rpc_id : 4 },
    { id: 5, name: "TRF อยุธยา ท่าเรือ", rpc_id : 5 },
    { id: 6, name: "KRF โคราช โคกกรวด", rpc_id : 3 },
    { id: 7, name: "BPF สระบุรี หนองแค", rpc_id : 5 },
    { id: 8, name: "KTF สระบุรี ธารเกษม", rpc_id : 5 },
    { id: 9, name: "HYF หาดใหญ่", rpc_id : 4 },
    { id: 10, name: "SRF ชลบุรี ศรีราชา", rpc_id :  4},
    { id: 11, name: "PLF พิษณุโลก สมอแข", rpc_id : 2 },
    { id: 12, name: "KKF ขอนแก่น ท่าพระ", rpc_id : 3 },
    { id: 13, name: "LPF ลำพูน ถนนซุปเปอร์ไฮเวย์", rpc_id : 2 },
]

export default FactoryInRpc