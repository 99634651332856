const listMenuAll = [
  {
    nameMenu: "Dashboard",
    pathnameMenu: "/dashboard",
    activePathMenubar: ["/dashboard", "/"],
  },
  {
    nameMenu: "Employee",
    pathnameMenu: "/employee",
    activePathMenubar: ["/employee" ,"/employee/personal" ],
  },
  {
    nameMenu: "Report/Record",
    pathnameMenu: "/report-record",
    activePathMenubar: ["/report-record"],
  },
  {
    nameMenu: "Activities",
    pathnameMenu: "/activities",
    activePathMenubar: ["/activities", "/activities/train-image-activities"],
  },
  {
    nameMenu: "Device",
    pathnameMenu: "/device",
    activePathMenubar: ["/device"],
  },
];

const listMenuUser = [

  {
    nameMenu: "Employee",
    pathnameMenu: "/employee",
    activePathMenubar: ["/employee" ,"/employee/personal" ],
  },

  {
    nameMenu: "Activities",
    pathnameMenu: "/activities",
    activePathMenubar: ["/activities", "/activities/train-image-activities"],
  },

];

export { listMenuAll , listMenuUser }