import React, { useState } from "react";
import ButtonPrimary from "../../components/ButtonPrimary";
import { Row, Col } from "reactstrap";
import { AiOutlinePoweroff } from "react-icons/ai";
import { VscDebugRestart } from "react-icons/vsc";
import { Modal } from "antd";
import axios from 'axios'
import * as api from '../../Service/Api_Muthermfacesense'
import NotificationAlertAntd from '../../components/NotificationAlertAntd';

export default function ButtonCommand({ device_serial_number , getStatusOnlineDevice }) {
  const token = localStorage.getItem("token");
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [commandDevice, setCommandDevice] = useState({
    text: null,
    color: null,
    command: null,
    textSuccess : null
  });

  function sendCommand() {
    setLoading(true)
    let data = {
      command : commandDevice.command
    }
    const requestCommand = api.SEND_COMMAND_DEVICE_ONE(token , device_serial_number , data)
    axios(requestCommand)
    .then( res => {
      setLoading(false)
      closeModal()
      NotificationAlertAntd('success', commandDevice.textSuccess +" "+ device_serial_number +" success" )
      getStatusOnlineDevice()
    })
    .catch( error => {
      setLoading(false)
      NotificationAlertAntd('error', commandDevice.textSuccess +" "+ device_serial_number +" error" )
    })
  }

  function closeModal() {
    setVisible(false);
    setCommandDevice({
      ...commandDevice,
      text: null,
    color: null,
    command: null,
    textSuccess : null
    });
  }

  return (
    <div style={{ width: "100%" }}>
      <Row>
        <Col xs={12} sm={6} md={6} lg={6} xl={6}>
          <ButtonPrimary
            className="btn-turnoff-and-reboot btn-turnoff-device"
            onClick={() => {
              setVisible(true);
              setCommandDevice({
                ...commandDevice,
                text: "Confirm turnoff device",
                color: "#ea6969",
                command: "turn-off",
                textSuccess : "Turnoff device"
              });
            }}
            text={
              <span>
                <AiOutlinePoweroff color="#ea6969" size="25px" /> Turnoff
              </span>
            }
          />
        </Col>
        <Col xs={12} sm={6} md={6} lg={6} xl={6}>
          <ButtonPrimary
            className="btn-turnoff-and-reboot btn-reboot-device"
            onClick={() => {
              setVisible(true);
              setCommandDevice({
                ...commandDevice,
                text: "Confirm reboot device",
                color: "#FCB64A",
                command: "reboot",
                textSuccess : "Reboot device"
              });
            }}
            text={
              <span>
                <VscDebugRestart color="#FCB64A" size="25px" /> Reboot
              </span>
            }
          />
        </Col>
      </Row>

      <Modal
        centered
        closable={false}
        visible={visible}
        onOk={() => closeModal()}
        onCancel={() => closeModal()}
        footer={null}
        // width={800}
        className="modalStyle-ModalSelectATK"
      >
      <p className="txt-sub-header1-by-nile">  {commandDevice.text} </p>
        <Row>
          <Col xs="12" sm="12" md="6" lg="6" xl="6">
            <br />
            <ButtonPrimary
              className="button2-save-create-new-employee-by-nile"
              style={{ background: commandDevice.color }}
              text="Confirm"
              loading={loading}
              onClick={() => sendCommand()}
            />
          </Col>
          <Col xs="12" sm="12" md="6" lg="6" xl="6">
            <br />
            <ButtonPrimary
              className="icon-txt-button2-by-nile"
              text="Cancel"
              onClick={() => closeModal()}
            />
          </Col>
        </Row>
      </Modal>
    </div>
  );
}
