import React,{ useState } from 'react'
import { Row, Col } from "reactstrap";
import ButtonPrimary from '../../components/ButtonPrimary';
import { Modal } from "antd";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import axios from 'axios'
import * as api from '../../Service/Api_Muthermfacesense'
import openNotificationWithIcon from '../../components/NotificationAlertAntd';

export default function RebuildAllRpc() {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem("token");

    function rebuildAllRPC() {
        setLoading(true)
        const rebuild = api.REBUILD_ALL_RPC(token)
        axios(rebuild)
        .then( res => {
            setLoading(false)
            setVisible(false)
            openNotificationWithIcon("success", "Rebuild all rpc success");
        })
        .catch( error => {
            setLoading(false)
            setVisible(false)
            openNotificationWithIcon("error", "Rebuild all rpc error");
        })
    }

  return (
<div>
<Row>
        <Col xs="12" sm="6" md="6" lg="6" xl="6" >
        <p className="text-header-date-picker">Rebuild all rpc zone</p>
        </Col>
        <Col xs="12" sm="6" md="6" lg="6" xl="6" >
        <ButtonPrimary
            className={"btn-rebuild-all-rpc-in-system-setting"}
            text={
              <div style={{ margin: "0px 0px -15px 0px" }}>
                <p>
                Rebuild all rpc zone
                </p>
              </div>
            }
            onClick={() => setVisible(true)}
            // loading={true}
          />
    </Col>
    </Row>

    <Modal
        centered
        closable={false}
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={null}
        // width={800}
        className="modalStyle-ModalSelectATK"
      >
      <div>
              <p className="txt-confirm-create-new-employee">
                <AiOutlineQuestionCircle
                  size="30"
                  color="#faad14"
                  style={{ marginRight: "10px" }}
                />
                Confirm rebuild all rpc
              </p>

              <Row>
                <Col xs="12" sm="12" md="6" lg="6" xl="6">
                  <br />
                  <ButtonPrimary
                    className="button2-save-create-new-employee-by-nile"
                    text="Confirm"
                    loading={loading}
                    onClick={() => rebuildAllRPC()}
                  />
                </Col>
                <Col xs="12" sm="12" md="6" lg="6" xl="6">
                  <br />
                  <ButtonPrimary
                    className="icon-txt-button2-by-nile"
                    text="Cancel"
                    onClick={() => setVisible(false)}
                  />
                </Col>
              </Row>
            </div>
      </Modal>

</div>
  )
}
