import { put, takeEvery, call } from "redux-saga/effects";
import {
    GET_DEPARTMENT_REQUEST,
  GET_DEPARTMENT_SUCCESS,
  GET_DEPARTMENT_FAILED,
} from "../actions";
import * as api from "../../Service/Api_Muthermfacesense";
import axios from "axios";

const token = localStorage.getItem("token");

function departmentFetch() {
    const department = api.GET_DEPARTMENT_ALL(token);
    return axios(department)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  function* handleGetDepartment() {
    try {
      const responseDepartment = yield call(departmentFetch);
      yield put({
        type: GET_DEPARTMENT_SUCCESS,
        department: responseDepartment.message,
      });
    } catch (err) {
      yield put({
        type: GET_DEPARTMENT_FAILED,
        error: err.response.data.detail,
        statusCode: err.response.status,
      });
    }
  }
  
  function* department() {
    yield takeEvery(GET_DEPARTMENT_REQUEST, handleGetDepartment);
  }
  
  export default department;