import React, { useEffect, useState } from "react";
import SelectedField from "../../components/SelectedField";
import { face_detected } from "../../assets/icons";
import FaceDatabase from "./FaceDatabase";
import { Container } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmployeeOneAndFeatureRequest,
  getEmployeeOneAndFeatureNodata,
  getDepartment,
  getFactory,
} from "../../redux/actions";
import Profile from "./Profile";
import { Spin } from "antd";
import ButtonBackPage from "../../components/ButtonBackPage";
import axios from "axios";
import * as api from "../../Service/Api_Muthermfacesense";
import rpcData from "../../assets/data/dataRpc";
import { EditEmployeeOneContext } from "../../components/Context";

export default function PersonalEmployee() {
  const history = useHistory();
  const token = localStorage.getItem("token");
  const { employee_id } = useParams();
  const dispatch = useDispatch();
  const [rpcZone, setRpcZone] = useState([]);
  const employeeOneAndFeature = useSelector(
    (state) => state.employeeOneAndFeature
  );
  const department = useSelector((state) => state.department);
  const factory = useSelector((state) => state.factory);

  useEffect(() => {
    dispatch(getEmployeeOneAndFeatureRequest(employee_id));
    dispatch(getDepartment());
    dispatch(getFactory());
    getUserRPCZone();

    return () => {};
  }, [employee_id]);

  useEffect(() => {
    if (employeeOneAndFeature.employee === null) {
      dispatch(getEmployeeOneAndFeatureNodata());
      history.push("/employee");
    }
    return () => {};
  }, [employeeOneAndFeature]);

  function getUserRPCZone() {
    const getRPCZone = api.GET_USER_RPC_ZONE(token, employee_id);
    axios(getRPCZone)
      .then((res) => {
        if (res.data.message.data) {
          mappingUserRpc(res.data.message.data);
        }
      })
      .catch((error) => {});
  }

  function mappingUserRpc(dataRpcUser) {
    const rpcAll = [];
    rpcData.map((dataRpcData) => {
      dataRpcUser.map((dataDataRpcUser) => {
        if (dataDataRpcUser.rpc_id === dataRpcData.rpc_id) {
          rpcAll.push({
            rpc_id: dataRpcData.rpc_id,
            rpc_name: dataRpcData.rpc_name,
            employee_zone_id: dataDataRpcUser.employee_zone_id,
          });
        }
      });
    });
    setRpcZone(rpcAll);
  }

  return (
    <EditEmployeeOneContext.Provider
      value={{
        employee: employeeOneAndFeature.employee,
        employee_id: employee_id,
        rpcZone: rpcZone,
        department: department.department,
        factory: factory.factory,
        feauture: employeeOneAndFeature.feauture,
        getUserRPCZone : getUserRPCZone,
      }}
    >
      <Container fluid>
        <ButtonBackPage
          className="icon-txt-button"
          style={{ width: "80px", marginTop: "0px" }}
          pathRoute="/employee"
        />
        <br />
        {employeeOneAndFeature.loading === true && (
          <Spin size="large" className="icons-spinner-loading-center-page" />
        )}
        {employeeOneAndFeature.loading === false &&
          employeeOneAndFeature.employee !== null && (
            <>
              <Profile />
              <div className="row-jcct-pt2-2">
                <SelectedField
                  listImage={[
                    {
                      title: "Face database",
                      image: face_detected,
                      page: <FaceDatabase />,
                    },
                  ]}
                />
              </div>
            </>
          )}
        <br />
      </Container>
    </EditEmployeeOneContext.Provider>
  );
}
