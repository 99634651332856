import React from "react";
import { img_test_feature } from "../../assets/images";
import { Container, Row, Col } from "reactstrap";
import DisplayTextValueImageTrain from "../../components/DisplayTextValueImageTrain";
import { Spin } from "antd"

export default function DisplayCardLg({ userInfo, imageFeature , featureInfo , created_at }) {
  return (
    <div className="display-card-lg">
      <Container>
        <Row>
          <Col>
         <div style={{ float : "right" }}>
           {
             featureInfo.img  === null && (
                <Spin style={{ float:'right' , marginRight:'90px' , marginTop:'130px' }} />
              )
           }
           {
             featureInfo.img !== null && (
              <img
              className="img-train-image-from-activities-public-page-lg"
              style={{ width:'220px' , height:'220px' , float:'right' , marginTop:"20px" }}
              src={imageFeature}
              alt="imageFeature"
            />
             )
           }
         </div>
          </Col>
          <Col>
          <br />
            <DisplayTextValueImageTrain header="ชื่อ" value={userInfo.firstname ? userInfo.firstname : "ไม่รู้จัก"} />
            <DisplayTextValueImageTrain header="นามสกุล" value={userInfo.lastname ? userInfo.lastname : "ไม่รู้จัก"} />
            <DisplayTextValueImageTrain header="รหัสพนักงาน" value={userInfo.code ? userInfo.code : "ไม่รู้จัก"} />
            {/* <DisplayTextValueImageTrain header="โรงงาน" value={userInfo.factory ? userInfo.factory : "ไม่รู้จัก"} /> */}
            {/* <DisplayTextValueImageTrain header="ภาค" value={userInfo.region ? userInfo.region : "ไม่รู้จัก"} /> */}
            <DisplayTextValueImageTrain header="Device" value={featureInfo.device_sn ? featureInfo.device_sn : "ไม่รู้จัก"} />
            <DisplayTextValueImageTrain header="สร้างเมื่อ" value={created_at ? created_at : "N/A"} />
            
          </Col>
        </Row>
      </Container>
    </div>
  );
}
