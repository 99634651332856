import React,{ useEffect , useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { patientIcon, thermometerIcon, youthIcon } from "../../assets/icons";
import { stand_by, thermometer, youth, patient } from "../../assets/images";
import Dropdown from "../../components/Dropdown";
import BarChartTotal from "./BarChartTotal";
import GraphRegion from "./GraphRegion";
import CardStatusDashboard from "../../components/CardStatusDashboard";
import DeviceInformation from "./DeviceInformation";
import { useDispatch, useSelector } from "react-redux"
import { getDeviceRequest , getRpcRequest } from '../../redux/actions';
import DateTime from './DateTime';

export default function Dashboard() {
  const dispatch = useDispatch()
  const device = useSelector(state => state.device)
  const rpc = useSelector(state => state.rpc)

  useEffect(() => {
    dispatch(getDeviceRequest());
    dispatch(getRpcRequest());

    return () => {
      
    }
  }, [])

  return (
    <Container fluid>
      <Row>
        <Col xs={0} sm={0} md={6} lg={6} xl={6}>
          <p className="txt-header-Dashboard">Welcome to factory management</p>
        </Col>

        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
         <DateTime />
        </Col>
      </Row>

      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={5}>
          <Row>
            <Col>
              <Row>
                <p className="txt-top-title-school-by-nile">
                  CPF : Charoen Pokphand Foods
                </p>
              </Row>
              <Row>
                <p className="txt-under-title">
                  บริษัท เจริญโภคภัณฑ์อาหาร จำกัด (มหาชน)
                </p>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col xs={12} sm={12} md={12} lg={12} xl={7}>
          <Row>
            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
              <CardStatusDashboard
                imageCard={patientIcon}
                HeaderValue="210"
                FooterValue="20/day"
              />
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
              <CardStatusDashboard
                imageCard={youthIcon}
                HeaderValue="200k"
                FooterValue="100/day"
              />
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
              <CardStatusDashboard
                imageCard={thermometerIcon}
                HeaderValue="39.0"
                FooterValue="35.5/day"
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <br />

      <Row>
        <Col xs={12} sm={12} md={6} lg={6} xl={3}>
          <CardStatusDashboard
            imageCard={stand_by}
            HeaderValue="Online"
            FooterValue="14/50"
          />
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={3}>
          <CardStatusDashboard
            imageCard={thermometer}
            HeaderValue="High Temp"
            FooterValue="42.4°C"
          />
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={3}>
          <CardStatusDashboard
            imageCard={youth}
            HeaderValue="Count"
            FooterValue="350K"
          />
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={3}>
          <CardStatusDashboard
            imageCard={patient}
            HeaderValue="High Count"
            FooterValue="100"
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <DeviceInformation 
          device={device}
          />
        </Col>
      </Row>

      <br /> <br />
      
      <Row>
        <div className="box-graph-total">
          <br />
          <Row>
            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
              <p className="text-header-total-dashboard-sm-graph-by-nile">
                Total
              </p>
              <Dropdown
                items={["2565", "2564", "2563"]}
                itemCallback={(item) => {}}
              />
              <br />
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
              <p className="text-header-total-dashboard-lg-graph-by-nile">
                Total
              </p>
            </Col>
            <Col xs={0} sm={0} md={4} lg={4} xl={4} />
          </Row>
          <Row>
            <Col >
              <BarChartTotal 
              
              />
            </Col>
          </Row>
          <br />
        </div>
      </Row>
      
      <br />
      <Row>
        <GraphRegion 
          rpc={rpc}
        />
      </Row>
    </Container>
  );
}
