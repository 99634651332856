export const START_REDUX_TEST = 'START_REDUX_TEST'
export const START_REDUX_TEST_REDUCERS = 'START_REDUX_TEST_REDUCERS'

export const GET_EMPLOYEE_REQUEST = 'GET_EMPLOYEE_REQUEST'
export const GET_EMPLOYEE_SUCCESS = 'GET_EMPLOYEE_SUCCESS'
export const GET_EMPLOYEE_FAILED = 'GET_EMPLOYEE_FAILED'
export const CLEAR_EMPLOYEE = 'CLEAR_EMPLOYEE'

export const GET_EMPLOYEE_ONE_AND_FEATURE_REQUEST = 'GET_EMPLOYEE_ONE_AND_FEATURE_REQUEST'
export const GET_EMPLOYEE_ONE_AND_FEATURE_SUCCESS = 'GET_EMPLOYEE_ONE_AND_FEATURE_SUCCESS'
export const GET_EMPLOYEE_ONE_AND_FEATURE_FAILED = 'GET_EMPLOYEE_ONE_AND_FEATURE_FAILED'
export const GET_EMPLOYEE_ONE_AND_FEATURE_NO_DATA = 'GET_EMPLOYEE_ONE_AND_FEATURE_NO_DATA'

export const GET_LOG_REQUEST = 'GET_LOG_REQUEST'
export const GET_LOG_SUCCESS = 'GET_LOG_SUCCESS'
export const GET_LOG_FAILED = 'GET_LOG_FAILED'
export const CLEAR_LOG = 'CLEAR_LOG'

export const GET_DEVICE_REQUEST = 'GET_DEVICE_REQUEST'
export const GET_DEVICE_SUCCESS = 'GET_DEVICE_SUCCESS'
export const GET_DEVICE_FAILED = 'GET_DEVICE_FAILED'

export const GET_RPC_REQUEST = 'GET_RPC_REQUEST'
export const GET_RPC_SUCCESS = 'GET_RPC_SUCCESS'
export const GET_RPC_FAILED = 'GET_RPC_FAILED'

export const GET_DEVICE_AFTER_SELECT_RPC_REQUEST = 'GET_DEVICE_AFTER_SELECT_RPC_REQUEST'
export const GET_DEVICE_AFTER_SELECT_RPC_SUCCESS = 'GET_DEVICE_AFTER_SELECT_RPC_SUCCESS'
export const GET_DEVICE_AFTER_SELECT_RPC_FAILED = 'GET_DEVICE_AFTER_SELECT_RPC_FAILED'

export const GET_CUT_QR_CODE_EMPLOYEE_REQUEST = 'GET_CUT_QR_CODE_EMPLOYEE_REQUEST'
export const GET_CUT_QR_CODE_EMPLOYEE_SUCCESS = 'GET_CUT_QR_CODE_EMPLOYEE_SUCCESS'
export const GET_CUT_QR_CODE_EMPLOYEE_FAILED = 'GET_CUT_QR_CODE_EMPLOYEE_FAILED'

export const GET_DEPARTMENT_REQUEST = 'GET_DEPARTMENT_REQUEST'
export const GET_DEPARTMENT_SUCCESS = 'GET_DEPARTMENT_SUCCESS'
export const GET_DEPARTMENT_FAILED = 'GET_DEPARTMENT_FAILED'

export const GET_FACTORY_REQUEST = 'GET_FACTORY_REQUEST'
export const GET_FACTORY_SUCCESS = 'GET_FACTORY_SUCCESS'
export const GET_FACTORY_FAILED = 'GET_FACTORY_FAILED'

export const GET_LOG_FILTER_REQUEST = 'GET_LOG_FILTER_REQUEST'
export const GET_LOG_FILTER_SUCCESS = 'GET_LOG_FILTER_SUCCESS'
export const GET_LOG_FILTER_FAILED = 'GET_LOG_FILTER_FAILED'
export const CLEAR_LOG_FILTER = 'CLEAR_LOG_FILTER'

export const GET_EMPLOYEE_DELETED_REQUEST = 'GET_EMPLOYEE_DELETED_REQUEST'
export const GET_EMPLOYEE_DELETED_SUCCESS = 'GET_EMPLOYEE_DELETED_SUCCESS'
export const GET_EMPLOYEE_DELETED_FAILED = 'GET_EMPLOYEE_DELETED_FAILED'
export const CLEAR_EMPLOYEE_DELETED = 'CLEAR_EMPLOYEE_DELETED'

export const GET_DETAIL_USER_INFORMATION = 'GET_DETAIL_USER_INFORMATION'