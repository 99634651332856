import { all } from 'redux-saga/effects'
import testAddPage from './StartReduxPage.saga';
import employee from './employee.saga'
import employeeOneAndFeature from './employeeOneAndFeature.saga'
import log from './log.saga';
import device from './device.sags';
import rpc from './rpc.saga';
import deviceAfterSelectRpc from './deviceAfterSelectRpc.saga';
import cutQRCodeEmployee from './CutQRCode.saga';
import department from './department.saga'
import factory from './Factory.saga'
import logFilter from './logFilter.saga'
import GetEmployeeDeleted from './GetEmployeeDeleted.saga'
import UserInformation from './UserInformation.saga'

export default function* rootSaga() {
    yield all([
        testAddPage(),
        employee() ,
        employeeOneAndFeature(),
        log(),
        device(),
        rpc(),
        deviceAfterSelectRpc(),
        cutQRCodeEmployee(),
        department(),
        factory(),
        logFilter(),
        GetEmployeeDeleted(),
        UserInformation()
    ])
  }