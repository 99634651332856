import React from "react";
import { test_image_log, user } from "../assets/images";
import { Row, Col } from "reactstrap";
import openNotificationWithIcon from "../components/NotificationAlertAntd";

var Ip = require("../Service/Ip");

export default function ThreeImageDisplaySelect({ data }) {

  function routeEmployee(user_id) {
    if (user_id) {
      window.open("/employee/personal/" + user_id);
    } else {
      openNotificationWithIcon("error", "No data");
    }
  }

  return (
    <div style={{ width: "400px" }}>
      <Row>
        <Col xs="6" sm="6" md="6" lg="6" xl="6">
          {}
          <div style={{ position: "relative" }}>
            <span
              className="cursor-pointer"
              onClick={() => routeEmployee(data.result[0] ? data.result[0].user_id : null)}
            >
              <img
                className="image-components-display"
                src={
                  data.result[0].data
                    ? `${Ip.getImagePath()}imagestatic/${data.result[0].data}`
                    : user
                }
                alt="ImageLog"
                style={{ float: "right" }}
                onError={(e) => {
                  e.target.src = user;
                }}
              />
              <p
                style={{
                  position: "absolute",
                  right: "0",
                  backgroundColor: "#FFF",
                  padding: "3px",
                  borderRadius: "7px",
                }}
              >
                {data.result[0]
                  ? data.result[0].confident_percent
                  : 0}{" "}
                %
              </p>
            </span>
          </div>
        </Col>
        <Col xs="6" sm="6" md="6" lg="6" xl="6">
          <Row>
            <Col>
              <div style={{ position: "relative" }}>
                <span
                  className="cursor-pointer"
                  onClick={() => routeEmployee(data.result[1] ? data.result[1].user_id : null)}
                >
                  <img
                    className="image-components-display"
                    src={data.result[1] ? `${Ip.getImagePath()}imagestatic/${data.result[1].data}` : user}
                    alt="ImageLog"
                    style={{ height: "60px", float: "left", width: "60px" }}
                    onError={(e) => {
                      e.target.src = user;
                    }}
                  />
                  <p
                    style={{
                      position: "absolute",
                      left: "25px",
                      backgroundColor: "#FFF",
                      padding: "3px",
                      borderRadius: "7px",
                      fontSize: "10px",
                    }}
                  >
                    {data.result[1]
                      ? data.result[1].confident_percent
                      : 0}{" "}
                    %
                  </p>
                </span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div style={{ position: "relative" }}>
                <span
                  className="cursor-pointer"
                  onClick={() => routeEmployee(data.result[2] ? data.result[2].user_id : null)}
                >
                  <img
                    className="image-components-display"
                    src={data.result[2] ? `${Ip.getImagePath()}imagestatic/${data.result[2].data}` : user}
                    alt="ImageLog"
                    style={{
                      marginTop: "10px",
                      height: "60px",
                      float: "left",
                      width: "60px",
                    }}
                    onError={(e) => {
                      e.target.src = user;
                    }}
                  />
                  <p
                    style={{
                      position: "absolute",
                      left: "25px",
                      top: "10px",
                      backgroundColor: "#FFF",
                      padding: "3px",
                      borderRadius: "7px",
                      fontSize: "10px",
                    }}
                  >
                    {data.result[2]
                      ? data.result[2].confident_percent
                      : 0}{" "}
                    %
                  </p>
                </span>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
