import * as types from "../actions";

const initialStateEmployeeOneAndFeature = {
  employee: {},
  feauture: [],
  loading: false,
  error: null,
  statusCode: null,
};

const employeeOneAndFeature = (
  state = initialStateEmployeeOneAndFeature,
  action
) => {
  switch (action.type) {
    case types.GET_EMPLOYEE_ONE_AND_FEATURE_REQUEST:
      return { ...state, loading: true , employee: {} };
    case types.GET_EMPLOYEE_ONE_AND_FEATURE_SUCCESS:
      return {
        ...state,
        loading: false,
        employee: action.employee,
        feauture: action.feauture,
      };
    case types.GET_EMPLOYEE_ONE_AND_FEATURE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        statusCode: action.statusCode,
      };
      case types.GET_EMPLOYEE_ONE_AND_FEATURE_NO_DATA:
        return { ...state, employee: {} };
    default:
      return state;
  }
};

export default employeeOneAndFeature;
