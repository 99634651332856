import { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import SelectDateTime from "../../../components/SelectDateTime";
import moment from "moment";
import ButtonPrimary from "../../../components/ButtonPrimary";
import { BiSearch } from "react-icons/bi";
import ModalSelectEmployee from "./ModalSelectEmployee";
import SelectFilterClear from "../../../components/SelectFilterClear";
import SelectFilterNoClear from "../../../components/SelectFilterNoClear";
import { useSelector } from "react-redux";

export default function Filter({ searchLog, setSearchLog, search, device , deviceUser }) {
  const { UserInformation } = useSelector((state) => state);
  const [isRoot, setIsRoot] = useState(false);

  useEffect(() => {
    if (UserInformation) {
      if (UserInformation.is_root === true) {
        setIsRoot(true);
      } else {
        setIsRoot(false);
      }
    }

    return () => {};
  }, [UserInformation]);

  useEffect(() => {
    var localDate = new Date();
    var localDateISO = localDate.toISOString();
    var UnixTimestampStart = moment("2000-01-01T00:00:00.000Z").unix();
    var UnixTimestampEnd = moment(localDateISO).unix();

    setSearchLog({
      ...searchLog,
      timestamp_date_start: UnixTimestampStart,
      timestamp_date_end: UnixTimestampEnd,
    });

    return () => {};
  }, []);

  function onChangeStartDateTime(value, dateString) {
    if (dateString) {
      var d = new Date(dateString);
      var dateISO = d.toISOString();
      var UnixTimestamp = moment(dateISO).unix();
      setSearchLog({
        ...searchLog,
        timestamp_date_start: UnixTimestamp,
      });
    } else {
      var UnixTimestamp = moment("2000-01-01T00:00:00.000Z").unix();
      setSearchLog({
        ...searchLog,
        timestamp_date_start: UnixTimestamp,
      });
    }
  }

  function onChangeEndDateTime(value, dateString) {
    if (dateString) {
      var d = new Date(dateString);
      var dateISO = d.toISOString();
      var UnixTimestamp = moment(dateISO).unix();

      setSearchLog({
        ...searchLog,
        timestamp_date_end: UnixTimestamp,
      });
    } else {
      var d = new Date();
      var dateISO = d.toISOString();
      var UnixTimestamp = moment(dateISO).unix();
      setSearchLog({
        ...searchLog,
        timestamp_date_end: UnixTimestamp,
      });
    }
  }

  function handleChangeSelect(value) {
    setSearchLog({
      ...searchLog,
      device_serial_number: value ? value : null,
    });
  }

  return (
    <div>
      <Row>
        <Col
          xs={12}
          sm={12}
          md={isRoot === true ? 10 : 12}
          lg={isRoot === true ? 10 : 12}
          xl={isRoot === true ? 10 : 12}
        >
          <Row>
            {isRoot === true && (
              <Col xs={12} sm={12} md={6} lg={6} xl={3}>
                <ModalSelectEmployee
                  setSearchLog={setSearchLog}
                  searchLog={searchLog}
                />
                <br />
              </Col>
            )}
            <Col xs={12} sm={12} md={6} lg={6} xl={3}>
              {isRoot === true && (
                <SelectFilterClear
                  headerSelectFilterClear="Device"
                  handleChange={(value) => handleChangeSelect(value)}
                  data={device.device}
                  value="device_serial_number"
                  name="device_name"
                  require={false}
                  styleHeader={{
                    color: "#707070",
                    fontSize: "1.2rem",
                    marginTop: "-10px",
                    marginBottom: "10px",
                  }}
                />
              )}
              {isRoot === false && (
                <SelectFilterNoClear
                  value={searchLog.device_serial_number}
                  headerSelectFilterClear="Device"
                  handleChange={(value) => handleChangeSelect(value)}
                  data={deviceUser}
                  keyvalue="device_serial_number"
                  keyname="device_name"
                  require={false}
                  styleHeader={{
                    color: "#707070",
                    fontSize: "1.2rem",
                    marginTop: "-10px",
                    marginBottom: "10px",
                  }}
                />
              )}
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={3}>
              <SelectDateTime
                headerComponents="Start date"
                onChange={onChangeStartDateTime}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={3}>
              <SelectDateTime
                headerComponents="End date"
                onChange={onChangeEndDateTime}
              />
            </Col>
            {isRoot === false && (
              <Col xs={12} sm={12} md={6} lg={6} xl={3}>
                <ButtonPrimary
                  className="button-new-student-by-nile"
                  onClick={() => search()}
                  text={
                    <>
                      <span
                        className="text-in-button-employee"
                        style={{ color: "#134e96" }}
                      >
                        Search
                      </span>
                      <BiSearch
                        size="25px"
                        className="icons-in-button-new-employee"
                        style={{ color: "#134e96" }}
                      />
                    </>
                  }
                />
              </Col>
            )}
          </Row>
        </Col>
        {isRoot === true && (
          <Col xs={12} sm={12} md={2} lg={2} xl={2}>
            <ButtonPrimary
              className="button-new-student-by-nile"
              onClick={() => search()}
              text={
                <>
                  <span
                    className="text-in-button-employee"
                    style={{ color: "#134e96" }}
                  >
                    Search
                  </span>
                  <BiSearch
                    size="25px"
                    className="icons-in-button-new-employee"
                    style={{ color: "#134e96" }}
                  />
                </>
              }
            />
          </Col>
        )}
      </Row>
    </div>
  );
}
