import React, { useState } from "react";
import {
  AllCheckerCheckbox,
  Checkbox,
  CheckboxGroup,
} from "@createnl/grouped-checkboxes";
import { Spin } from "antd";

export default function TableLogComponents({
  columns,
  dataSource,
  functionStyleTableRow,
  rowSelection,
  loading,
}) {
  const [dataSelectAll, setDataSelectAll] = useState([]);
  function returnClass(index_columns, allIndex) {
    if (index_columns === 0) return "td-first-table";
    else if (index_columns === allIndex.length - 1) return "td-last-table";
    else return null;
  }

  function compareFirst(a, b) {
    if (a.indexDataCheckboxAll > b.indexDataCheckboxAll) {
      return 1;
    }
    if (a.indexDataCheckboxAll < b.indexDataCheckboxAll) {
      return -1;
    }
    return 0;
  }

  function cutDataToReturn(dataAll) {
    let dataReturnToComponents = [];

    dataAll.map((dataReturn) => {
      let data_last_return = {};
      for (const [key, value] of Object.entries(dataReturn)) {
        if (key !== "indexDataCheckboxAll") {
          data_last_return[key] = value;
        }
      }
      dataReturnToComponents.push(data_last_return);
    });

    return dataReturnToComponents;
  }

  const changAll = (e) => {
    if (e.target.checked === true) {
      let dataAll = [];
      dataSource.map((dataPushAll, index) => {
        dataAll.push({
          ...dataPushAll,
          indexDataCheckboxAll: index,
        });
      });
      let dataReturnToComponents = cutDataToReturn(dataAll);
      setDataSelectAll(dataAll);
      rowSelection.onChange(dataReturnToComponents);
    } else {
      setDataSelectAll([]);
      rowSelection.onChange([]);
    }
  };

  const changCheckbox = (e, data, index) => {
    const dataInFC = [...dataSelectAll];
    if (e.target.checked === true) {
      dataInFC.push({
        ...data,
        indexDataCheckboxAll: index,
      });
      dataInFC.sort(compareFirst);
    } else {
      const index_data = dataInFC.findIndex(
        (std) => std.indexDataCheckboxAll === index
      );
      dataInFC.splice(index_data, 1);
      dataInFC.sort(compareFirst);
    }

    let dataReturnToComponents = cutDataToReturn(dataInFC);
    setDataSelectAll(dataInFC);
    rowSelection.onChange(dataReturnToComponents);
  };

  return (
    <div className="div-table-components-by-nile">
      {loading && (
        <Spin size="large" style={{ display: "block", margin: "20px auto" }} />
      )}
      {!loading && (
        <CheckboxGroup>
          <table
            className="table-log-all-components"
            style={{ overflowX: "auto" }}
          >
            <tbody>
              <tr>
                {rowSelection !== undefined &&
                  rowSelection.type === "checkbox" && (
                    <th>
                      <AllCheckerCheckbox
                        className="cursor-pointer"
                        onChange={(e) => changAll(e)}
                      />
                    </th>
                  )}
                {columns.length !== 0 &&
                  columns.map((data, index) => {
                    return (
                      <th
                        key={index}
                        style={{ width: data.width ? data.width : null }}
                      >
                        {data.title}
                      </th>
                    );
                  })}
              </tr>
              {dataSource.length !== 0 &&
                columns.length !== 0 &&
                dataSource.map((dataSource, index) => {
                  let styleBackground = functionStyleTableRow
                    ? functionStyleTableRow(dataSource)
                    : "#FFF";
                  return (
                    <tr
                      className="tr-table-components"
                      key={index}
                      style={{
                        background: styleBackground ? styleBackground : "#FFF",
                      }}
                    >
                      {rowSelection !== undefined &&
                        rowSelection.type === "checkbox" && (
                          <td className={returnClass(0, 1)}>
                            <Checkbox
                              className="cursor-pointer"
                              onChange={(e) =>
                                changCheckbox(e, dataSource, index)
                              }
                            />
                          </td>
                        )}

                      {columns.map((dataColumns, index_columns) => {
                        if (dataColumns.render) {
                          if (dataColumns.dataIndex !== undefined) {
                            return (
                              <td
                                className={returnClass(index_columns, columns)}
                                key={index_columns}
                              >
                                {dataColumns.render(
                                  dataSource[dataColumns.dataIndex]
                                )}
                              </td>
                            );
                          } else {
                            return (
                              <td
                                className={returnClass(index_columns, columns)}
                                key={index_columns}
                              >
                                {dataColumns.render(dataSource)}
                              </td>
                            );
                          }
                        } else {
                          return (
                            <td
                              className={returnClass(index_columns, columns)}
                              key={index_columns}
                            >
                              {dataSource[dataColumns.dataIndex]
                                ? dataSource[dataColumns.dataIndex]
                                : null}
                              {dataColumns.lineRight === true && (
                                <div className="div-line-right-by-nile" />
                              )}
                            </td>
                          );
                        }
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </CheckboxGroup>
      )}
    </div>
  );
}
