const rpcData = [
  {
    rpc_id: 1,
    rpc_name: "BKF , RBF (Zone A)",
    rpc_service_name: "recognition_central",
  },
  {
    rpc_id: 2,
    rpc_name: "LPF , PLF , SKF (Zone B)",
    rpc_service_name: "recognition_north",
  },
  {
    rpc_id: 3,
    rpc_name: "KKF , KRF , PTF (Zone C)",
    rpc_service_name: "recognition_south",
  },
  {
    rpc_id: 4,
    rpc_name: "HYF , KBF , SRF (Zone D)",
    rpc_service_name: "recognition_east",
  },
  {
    rpc_id: 5,
    rpc_name: "BPF , KTF , TRF (Zone E)",
    rpc_service_name: "recognition_west",
  },
];

export default rpcData