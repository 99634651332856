import React,{ useEffect , useState } from 'react'

export default function DateTime() {
    const dateTime = new Date()
    const [dateTimeState, setDateTimeState] = useState(dateTime)

    useEffect(() => {
        let dateTimeId = setInterval(() => {
          const dateTime = new Date()
          setDateTimeState(dateTime)
        }, 1000);
    
        return(() => {
            clearInterval(dateTimeId)
        })
    },[])

  return (
 <p className="txt-header-time-Dashboard"> {dateTimeState.toDateString()} , {dateTimeState.toLocaleTimeString()} </p>
  )
}
