import React,{ useEffect , useState } from "react";
import BoxDeviceDonut from "../../components/BoxDeviceDonut";
import { Row, Col } from "reactstrap";
import Carousel from "react-elastic-carousel";
import { useSelector } from "react-redux"

export default function DeviceInformation({ device }) {
  const [itemsShow, setItemsShow] = useState(3)

  useEffect(() => {
    setImageSize()
    return () => {
      
    }
  }, [])

  const setImageSize = () => {
    // console.log(window.innerWidth);
    if (window.innerWidth < 768) {
     setItemsShow(1)
    } else if( window.innerWidth === 768) {
      setItemsShow(2)
    } else {
      setItemsShow(3)
    }
  }

  return (
 <div className="box-device-information-by-nile">
      <Row>
        <Col>
          <p className="txt-sub-header1-by-nile">Devices Information</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Carousel 
            // itemPadding={[0, 20]}
            // outerSpacing={100}
            itemsToShow={itemsShow}
            showArrows={false} 
          >
            {
              device.device.map( (data , index) => {
                return  <BoxDeviceDonut 
                key={index}
                serialNumberDevice={data.device_serial_number} 
                place={data.device_name} 
                ipAddress={data.rpc_name} 
                />
              } )
            }
          </Carousel>
        </Col>
      </Row>
      <br />
    </div>
  )
}
