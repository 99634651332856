import { useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Switch, Route, Redirect } from "react-router-dom";
import Auth from "./Service/Auth";

import "./assets/css/styles.css";
import Login from "./page/Login";

import Activities from "./page/Activities";
import Dashboard from "./page/Dashboard";
import ReportRecord from "./page/ReportRecord";
import Employee from "./page/Employee";
import Setting from "./page/Setting";
import PersonalStudentPage from "./page/PersonalEmployee";
import TrainImageFromActivities from "./page/TrainImageFromActivities";
import NewEmployee from "./page/NewEmployee";
import Device from "./page/Device";
import DeviceOne from "./page/DeviceOne";
import TrainImageFromActivities_Public from "./page/TrainImageFromActivities_Public";
import TrainImageFromActivities_Public_Success from "./page/TrainImageFromActivities_Public_Success";

import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { getDetailUserInformation } from "./redux/actions";

export default function App() {
  const dispatch = useDispatch();
  const [isRoot, setIsRoot] = useState(false);
  const token = localStorage.getItem("token");

  useEffect(() => {
    let isDecoded = false;
    if (token && isDecoded === false) {
      var decoded = jwt_decode(token);
      dispatch(getDetailUserInformation(decoded));
      setIsRoot(decoded.is_root);
    }

    return () => {
      isDecoded = true;
    };
  }, [token]);

  return (
    <Switch>
      <Route
        exact
        path="/self-service/:logDataFeature"
        component={TrainImageFromActivities_Public}
      />
      <Route
        exact
        path="/self-service-success"
        component={TrainImageFromActivities_Public_Success}
      />
      <Route exact path="/login" component={Login} />

      <Auth>
        {isRoot === false && (
          <>
            <Route
              exact
              path="/"
              component={() => <Redirect to="/employee" />}
            />
            <Route exact path="/activities" component={Activities} />

            <Route exact path="/employee" component={Employee} />
            <Route
              exact
              path="/employee/new-employee"
              component={NewEmployee}
            />
            <Route
              exact
              path="/employee/personal/:employee_id"
              component={PersonalStudentPage}
            />
            <Route exact path="/setting" component={Setting} />
          </>
        )}
        {isRoot === true && (
          <>
            <Route
              exact
              path="/"
              component={() => <Redirect to="/dashboard" />}
            />
            {/* <Route exact path="/" component={Dashboard} /> */}
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/activities" component={Activities} />
            <Route
              exact
              path="/activities/train-image-activities/:idActivities"
              component={TrainImageFromActivities}
            />
            <Route exact path="/report-record" component={ReportRecord} />

            <Route exact path="/employee" component={Employee} />
            <Route
              exact
              path="/employee/new-employee"
              component={NewEmployee}
            />
            <Route
              exact
              path="/employee/personal/:employee_id"
              component={PersonalStudentPage}
            />

            <Route exact path="/device" component={Device} />
            <Route
              exact
              path="/device/:device_serial_number"
              component={DeviceOne}
            />

            <Route exact path="/setting" component={Setting} />
          </>
        )}
      </Auth>
    </Switch>
  );
}
