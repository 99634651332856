import React, { useState } from "react";
import { UthermHalfImg, utherm_hover } from "../assets/images";
export default function BoxAllDevice({ nameDevice, serialNumber, onClick }) {
  const [imgMutherm, setImgMutherm] = useState(UthermHalfImg);

  return (
    <div
      onClick={onClick}
      className="device-details-box-by-nile"
      onMouseOver={() => setImgMutherm(utherm_hover)}
      onMouseOut={() => setImgMutherm(UthermHalfImg)}
    >
      <img
        className="img-device-details-box"
        src={imgMutherm}
        alt="imgMutherm"
      />
      <div className="pt2">
        <p className="txt-main-content1"> {serialNumber} </p>
        <p className="txt-sub-explanation1"> {nameDevice} </p>
      </div>
    </div>
  );
}
