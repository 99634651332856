var Ip = require("./Ip");

export const LOGIN = (data) => {
  return {
    method: "post",
    url: `${Ip.getIP()}api/user/login`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
};

export const LOGIN_TEST = (data) => {
  return {
    method: "post",
    url: `https://mtfs.pnall.co.th/api/auth/signup`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
};

export const GET_DYNAMIC_URL = (token, url) => {
  return {
    method: "get",
    url: `${Ip.getIP()}${url}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
};

export const GET_EMPLOYEE_FIRST = (token) => {
  return {
    method: "get",
    url: `${Ip.getIP()}api/employee/?page=1&limit=10`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
};

export const GET_EMPLOYEE_ONE_AND_FEATURE = (token, employee_id) => {
  return {
    method: "get",
    url: `${Ip.getIP()}api/employee/feature/` + employee_id,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
};

export const CREATE_NEW_EMPLOYEE = (token, data) => {
  return {
    method: "post",
    url: `${Ip.getIP()}api/employee/`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    data: data,
  };
};

export const GET_LOG_ALL = (token) => {
  return {
    method: "get",
    url: `${Ip.getIP()}api/log/`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
};

export const GET_REPORT_API = (token , timestamp_date_start , timestamp_date_end) => {
  return {
    method: "get",
    url: `${Ip.getIP()}api/log/logs_powerbi?timestamp_date_start=${timestamp_date_start}&timestamp_date_end=${timestamp_date_end}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
};

export const GET_NEW_API_LOG_ALL = (token, page, limit, device_sn) => {
  return {
    method: "get",
    url: `${Ip.getIP()}api/log/logs_pagination?page=${page}&limit=${limit}&device_sn=${device_sn}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
};

export const GET_DEVICE_ALL = (token) => {
  return {
    method: "get",
    url: `${Ip.getIP()}api/device/`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
};

export const GET_DEPARTMENT_ALL = (token) => {
  return {
    method: "get",
    url: `${Ip.getIP()}api/employee/get_all_department`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
};

export const GET_FACTORY_ALL = (token) => {
  return {
    method: "get",
    url: `${Ip.getIP()}api/employee/get_all_factory`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
};

export const GET_RPC = (token) => {
  return {
    method: "get",
    url: `${Ip.getIP()}api/rpc/`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
};

export const GET_DEVICE_AFTER_SELECT_RPC = (token, rpc_id) => {
  return {
    method: "get",
    url: `${Ip.getIP()}api/device/` + rpc_id,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
};

export const ADD_EMPLOYEE_IN_RPC_ZONE = (token, employee_id, rpc_id) => {
  return {
    method: "post",
    url: `${Ip.getIP()}api/employee/add-employee-rpc-zone/${employee_id}?rpc_id=${rpc_id}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
};

export const ADD_EMPLOYEE_FEATURE = (token, employee_id, log_object_id) => {
  return {
    method: "post",
    url: `${Ip.getIP()}api/employee/add-employee-feature/${employee_id}?log_object_id=${log_object_id}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
};

export const ADD_EMPLOYEE_FEATURE_NO_TOKEN = (employee_id, log_object_id) => {
  return {
    method: "post",
    url: `${Ip.getIP()}api/employee/add-employee-feature/${employee_id}?log_object_id=${log_object_id}&user_token=guest`,
    headers: {
      "Content-Type": "application/json",
    },
  };
};

export const REBUILD_ALL_RPC = (token) => {
  return {
    method: "get",
    url: `${Ip.getIP()}api/system/rebuild_allrpc`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
};

export const BUILD_RPC_ZONE = (token, rpc_id) => {
  return {
    method: "get",
    url: `${Ip.getIP()}api/system/download_install_last_build_model/` + rpc_id,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
};

export const REMOVE_FEATURE_EMPLOYEE = (token, employee_feature_id) => {
  return {
    method: "delete",
    url:
      `${Ip.getIP()}api/employee/feature/delete-employee-feature/` +
      employee_feature_id,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
};

export const REMOVE_FEATURE_EMPLOYEE_NEW_API = (token, employee_feature_id) => {
  return {
    method: "delete",
    url:
      `${Ip.getIP()}api/employee/employee-feature/`+ employee_feature_id,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
};

export const EDIT_EMPLOYEE_ONE = (token, employee_id , data) => {
  return {
    method: "put",
    url:
      `${Ip.getIP()}api/employee/` +employee_id ,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    data : data
  };
};

export const REMOVE_EMPLOYEE = (token, employee_id) => {
  return {
    method: "delete",
    url: `${Ip.getIP()}api/employee/` + employee_id,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
};

export const GET_FEATURE_INFO = (log_id) => {
  return {
    method: "get",
    url: `${Ip.getIP()}api/log/get_log_by_ref/` + log_id,
    headers: {
      "Content-Type": "application/json",
    },
  };
};

export const GET_EMPLOYEE_INFO_FROM_QRCODE = (code) => {
  return {
    method: "get",
    url: `${Ip.getIP()}api/employee/qrcode?qr_code_value=` + code,
    headers: {
      "Content-Type": "application/json",
    },
  };
};

export const GET_EMPLOYEE_INFO_FROM_CODE = (code) => {
  return {
    method: "get",
    url: `${Ip.getIP()}api/employee/get_employee_by_code?code=` + code,
    headers: {
      "Content-Type": "application/json",
    },
  };
};

export const GET_META_DATA = (token, device_serial_number) => {
  return {
    method: "get",
    url: `${Ip.getIP()}api/device/meta-data/get_last_one_log_by_device_sn?device_sn=${device_serial_number}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
};

export const GET_STATUS_ONLINE_DEVICE_ONE = (token, device_serial_number) => {
  return {
    method: "get",
    url: `${Ip.getIP()}api/device/device/get-device-command/${device_serial_number}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
};

export const GET_CONFIG_DEVICE_ONE = (token, device_serial_number) => {
  return {
    method: "get",
    url: `${Ip.getIP()}api/device/device/get-config/${device_serial_number}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
};

export const GET_ANNOUNCEMENT_DEVICE_ONE = (token, device_serial_number) => {
  return {
    method: "get",
    url: `${Ip.getIP()}api/device/device/get-device-announcement/${device_serial_number}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
};

export const SEND_COMMAND_DEVICE_ONE = (token, device_serial_number, data) => {
  return {
    method: "POST",
    url:
      `${Ip.getIP()}api/device/device/send-command?device_sn=` +
      device_serial_number,
    data: data,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
};

export const SEND_UPSERT_CONFIG_DEVICE_ONE = (
  token,
  device_serial_number,
  data
) => {
  return {
    method: "POST",
    url: `${Ip.getIP()}api/device/device/upsert-config/` + device_serial_number,
    data: data,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
};

export const GET_USER_RPC_ZONE = (token, employee_id) => {
  return {
    method: "get",
    url: `${Ip.getIP()}api/employee/get-employee-rpc-zone/`+employee_id ,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
};

export const GET_EMPLOYEE_DELETED = (token) => {
  return {
    method: "get",
    url: `${Ip.getIP()}api/employee/get-deleted-employee` ,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
};

export const DELETE_EMPLOYEE_RPC_ZONE = (token , employee_zone_id ) => {
  return {
    method: "delete",
    url: `${Ip.getIP()}api/employee/employee-rpc-zone/`+employee_zone_id  ,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
};