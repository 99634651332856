const fineDataFromArray = (array, id, nameArray) => {
  let name = null
    if (id) {
         array.map((data) => {
          if (data.id === id) {
            name = data[nameArray]
          }
        });
        return name
      }
}

export default fineDataFromArray