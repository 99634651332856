import { createAction } from 'redux-actions'
import * as types from './types.action'

export const startReduxTest = createAction(types.START_REDUX_TEST)

export const getEmployeeRequest = createAction(types.GET_EMPLOYEE_REQUEST)

export const getLogRequest = createAction(types.GET_LOG_REQUEST)

export const getDeviceRequest = createAction(types.GET_DEVICE_REQUEST)

export const getRpcRequest = createAction(types.GET_RPC_REQUEST)

export const getDeviceAfterSelectRpcRequest = createAction(types.GET_DEVICE_AFTER_SELECT_RPC_REQUEST)

export const getEmployeeOneAndFeatureRequest = createAction(types.GET_EMPLOYEE_ONE_AND_FEATURE_REQUEST)

export const getEmployeeOneAndFeatureNodata = createAction(types.GET_EMPLOYEE_ONE_AND_FEATURE_NO_DATA)

export const getCutQrcodeEmployee = createAction(types.GET_CUT_QR_CODE_EMPLOYEE_REQUEST)

export const getDepartment = createAction(types.GET_DEPARTMENT_REQUEST)

export const getFactory = createAction(types.GET_FACTORY_REQUEST)

export const getLogFilterRequest = createAction(types.GET_LOG_FILTER_REQUEST)

export const getEmployeeDeleted = createAction(types.GET_EMPLOYEE_DELETED_REQUEST)

export const getDetailUserInformation = createAction(types.GET_DETAIL_USER_INFORMATION)