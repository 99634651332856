import React from "react";
const listColor = [
  "#EEAD85",
  "#79C1D9",
  "#FE8E74",
  "#C0E5FE",
  "#FF5F81",
  "#6B80BE",
  "#A42822",
  "#85ABAF",
  "#F4D042",
  "#77A15E",
  "#FB6C44",
  "#8190E7",
  "#EBCEC8",
  "#8BD1B4",
  "#C081A3",
  "#B4BA7A",
  "#F9C9A0",
  "#DAFE7A",
  "#FDE490",
  "#7B7E23",
  "#DC88A5",
  "#4B7BA9",
  "#FE65DA",
  "#2D9091",
  "#B36E43",
  "#BC9DFB",
];
export default function ColorBarGraph({ text, index }) {
  return (
    <div className="row-color-bar-graph">
      <div
        style={{ backgroundColor: listColor[index] }}
        className="box-color-bar-graph"
      ></div>
      <p className="txt-color-bar-graph">{text}</p>
    </div>
  );
}
