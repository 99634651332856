import React, { useState } from "react";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export default function ButtonPrimary({
  className,
  onClick,
  text,
  style,
  onFocus,
  onMouseOver,
  onMouseOut,
  loading
}) {
  const [mouse, setMouse] = useState("unclick");
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

  function animationLoading(loading) {
    if(loading){
      return <Spin indicator={antIcon} />
    }
  }

  return (
    <button
      // id={mouse}
      onFocus={onFocus}
      onMouseOut={onMouseOut}
      className={className}
      onMouseOver={onMouseOver}
      style={style}
      onMouseDown={() => {
        setMouse("unclick");
        // console.log("onMouseDown");
      }}
      onMouseUp={() => {
        setMouse("click");
        // console.log("onMouseUp");
      }}
      onClick={onClick}
      disabled={loading ? loading : false}
    >
    {animationLoading(loading)}  {text}
    </button>
  );
}
