import { useEffect, useState } from "react";
import User from "./User";
import Systems from "./Systems";
import { Tabs } from "antd";
import { Container } from "reactstrap";
import GetDeleteEmployee from "./GetDeleteEmployee";
import { useSelector } from "react-redux";

const { TabPane } = Tabs;

export default function Setting() {
  const { UserInformation } = useSelector((state) => state);
  const [isRoot, setIsRoot] = useState(null);

  useEffect(() => {
    if (UserInformation && UserInformation.is_root !== null) {
      setIsRoot(UserInformation.is_root);
    }
    return () => {};
  }, [UserInformation]);

  return (
    <Container fluid>
      {isRoot !== null && (
        <>
          {isRoot === true && (
            <Tabs defaultActiveKey="1">
              <TabPane tab={<p className="txt-sub-header1">User</p>} key="1">
                <User />
              </TabPane>
              <TabPane tab={<p className="txt-sub-header1">System</p>} key="2">
                <Systems />
              </TabPane>
              <TabPane
                tab={<p className="txt-sub-header1">Employee deleted</p>}
                key="3"
              >
                <GetDeleteEmployee />
              </TabPane>
            </Tabs>
          )}

          {isRoot === false && (
            <>
              <User />
            </>
          )}
        </>
      )}
    </Container>
  );
}
