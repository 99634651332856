import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import TableActivities from "./TableActivities";
import SelectDateTime from "../../components/SelectDateTime";
import SelectFilterClear from "../../components/SelectFilterClear";
import InputSelect from "../../components/InputSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  getLogRequest,
  getEmployeeRequest,
  getLogFilterRequest,
  getDeviceRequest,
} from "../../redux/actions";
import DeviceAll from "../../components/DeviceAll";
import { Form } from "antd";
import Filter from "./Filter";

export default function Activities() {
  const dispatch = useDispatch();
  const { employee, device, UserInformation } = useSelector((state) => state);
  const [error, setError] = useState(null);
  const [isRoot, setIsRoot] = useState(null);
  const [deviceUser, setDeviceUser] = useState([]);
  const [searchLog, setSearchLog] = useState({
    page: 1,
    limit: 10,
    page_Size: 10,
    timestamp_date_start: null,
    timestamp_date_end: null,
    employee_id: null,
    device_serial_number: null,
  });
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  });

  useEffect(() => {
    dispatch(getDeviceRequest());

    return () => {};
  }, []);

  function mappingDeviceUser(factory_id) {
    const filterFactory = [];
    if (factory_id) {
      device.device.map((data) => {
        if (data.factory_id === factory_id) {
          filterFactory.push(data);
        }
      });
    }
    setDeviceUser(filterFactory);
    setSearchLog({
      ...searchLog,
      device_serial_number: filterFactory[0]
        ? filterFactory[0].device_serial_number
        : null,
    });
  }

  useEffect(() => {
    if (UserInformation.is_root !== null && isRoot === false) {
      mappingDeviceUser(UserInformation.factory_id);
    }
    return () => {};
  }, [device, isRoot]);

  useEffect(() => {
    if (UserInformation) {
      if (UserInformation.is_root === true) {
        setIsRoot(true);
      } else {
        setIsRoot(false);
      }
    }

    return () => {};
  }, [UserInformation]);

  const search = () => {
    dispatch(getLogFilterRequest(searchLog));
    setPage_limit({
      ...page_limit,
      page: 1,
    });
  };

  useEffect(() => {
    if (isRoot !== null) {
      if (
        searchLog.timestamp_date_start !== null &&
        searchLog.timestamp_date_end !== null
      ) {
        if (isRoot === true) {
          dispatch(getLogFilterRequest(searchLog));
          setPage_limit({
            ...page_limit,
            page: 1,
          });
        } else {
          if (searchLog.device_serial_number !== null) {
            dispatch(getLogFilterRequest(searchLog));
            setPage_limit({
              ...page_limit,
              page: 1,
            });
          }
        }
      }
    }
    return () => {};
  }, [searchLog]);

  return (
    <Container fluid>
      <Filter
        searchLog={searchLog}
        setSearchLog={setSearchLog}
        search={search}
        device={device}
        deviceUser={deviceUser}
      />

      <br />
      <Row>
        <Col>
          <TableActivities
            setError={setError}
            employee={employee}
            searchLog={searchLog}
            setSearchLog={setSearchLog}
            page_limit={page_limit}
            setPage_limit={setPage_limit}
          />
        </Col>
      </Row>
    </Container>
  );
}
