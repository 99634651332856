import { useContext } from "react";
import { user } from "../../assets/images";
import { Row, Col } from "reactstrap";
import RenderTextHeaderAndValue from "../../components/RenderTextHeaderAndValue";
import RenderTextHeaderAndMultipleValue from "../../components/RenderTextHeaderAndMultipleValue";
import fineDataFromArray from "../../components/FindDataFromArray";
import { EditEmployeeOneContext } from "../../components/Context";
import EditEmployeeRpcZone from "./EditEmployeeRpcZone";

export default function CardProfileInfo({}) {
  const { employee, rpcZone, department, factory } = useContext(
    EditEmployeeOneContext
  );
  return (
    <Row>
      <Col xs={12} sm={12} md={6} lg={4} xl={4}>
        <RenderTextHeaderAndValue
          textHeader="First name"
          textValue={employee.first_name}
        />
        <RenderTextHeaderAndValue
          textHeader="Last name"
          textValue={employee.last_name}
        />
        <RenderTextHeaderAndValue textHeader="Code" textValue={employee.code} />
      </Col>
      <Col xs={12} sm={12} md={6} lg={4} xl={4}>
        <RenderTextHeaderAndValue
          textHeader="โรงงาน"
          textValue={fineDataFromArray(
            factory,
            employee.factory_id,
            "factory_name"
          )}
        />
        <RenderTextHeaderAndValue
          textHeader="แผนก"
          textValue={fineDataFromArray(
            department,
            employee.department_id,
            "department_name"
          )}
        />
        <EditEmployeeRpcZone />
      </Col>
      <Col xs={12} sm={12} md={12} lg={4} xl={4}>
        <img
          className="box-image-profile-lg-by-nile"
          src={user}
          alt="employee"
        />
      </Col>
    </Row>
  );
}
