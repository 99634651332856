import * as types from "../actions";

const initialState = {
    device: [],
    loading: false,
    error: null,
    statusCode: null,
  };
  
  const device = (state = initialState, action) => {
    switch (action.type) {
      case types.GET_DEVICE_REQUEST:
        return { ...state, loading: true };
      case types.GET_DEVICE_SUCCESS:
        return {
          ...state,
          loading: false,
          device: action.device,
        };
      case types.GET_DEVICE_FAILED:
        return {
          ...state,
          loading: false,
          error: action.error,
          statusCode: action.statusCode,
        };
      default:
        return state;
    }
  };
  
  export default device;