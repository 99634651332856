import React from "react";
import {
  environmentIcon,
  humidityIcon,
  peopleIcon,
  thermometerIcon,
  sickIcon,
} from "../../assets/icons";

const listIcon = {
  1: peopleIcon,
  2: sickIcon,
  3: thermometerIcon,
  4: environmentIcon,
  5: humidityIcon,
};

const listTitle = {
  1: <>Count</>,
  2: (
    <>
      High <br />
      Temperature
    </>
  ),
  3: <>Temperature</>,
  4: (
    <>
      Environment <br />
      Temperature
    </>
  ),
  5: (
    <>
      Environment <br />
      Hunadity
    </>
  ),
};

export default function SummayBelowGraph({ values, type }) {
  return (
    <div className="col-icon-text-summary-below-graph">
      <img className="img-summary-below-graph" src={listIcon[type]}></img>
      <p className="txt-icon-text-summary-below-graph">{values}</p>
      <span className="txt-explanation-summary-below-graph">
        {listTitle[type]}
      </span>
    </div>
  );
}
