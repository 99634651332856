import React, { useState } from "react";
import UploadImageToDecodeQRCode from "./UploadImageToDecodeQRCode";
import InputNormal from "../../../components/InputNormal";
import ButtonPrimary from "../../../components/ButtonPrimary";
import { Container, Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux"
import { getCutQrcodeEmployee } from '../../../redux/actions';
import * as api from '../../../Service/Api_Muthermfacesense';
import axios from 'axios'
import NotificationAlertAntd from '../../../components/NotificationAlertAntd';

export default function UserIHR({ userInfo, setUserInfo, switchSelect , ihr_code_input , setIhr_code_input }) {
  const dispatch = useDispatch()
  const [uploadImageDecodeQRCode, setUploadImageDecodeQRCode] = useState(null);
  const [errorInvalidInput, setErrorInvalidInput] = useState(null)

  function handleInput(value) {
    if(value){
      setIhr_code_input(value)
    } else {
      setIhr_code_input(null)
    }
  }

  function searchIhr_code() {
    if(ihr_code_input === null ){
      setErrorInvalidInput("โปรดกรอกรหัส User ihr")
    } else {
      setErrorInvalidInput(null)
      const codeEmployee = api.GET_EMPLOYEE_INFO_FROM_CODE(ihr_code_input)
      axios(codeEmployee)
      .then( res => {
        if(res.data.employee === null){
          NotificationAlertAntd("info","ไม่พบผู้ใช้งานในระบบ")
        } else {
          setUserInfo({
            ...userInfo,
            employee_id: res.data.employee.employee_id ,
            firstname: res.data.employee.first_name ,
            lastname: res.data.employee.last_name ,
            // factory: res.data.employee. ,
            code: res.data.employee.code ,
          })
        }
      })
      .catch( error => {
        NotificationAlertAntd("error","Internet error")
      })
    }
  }

  return (
    <div>
      <UploadImageToDecodeQRCode
        userInfo={userInfo}
        setUserInfo={setUserInfo}
        uploadImageDecodeQRCode={uploadImageDecodeQRCode}
        setUploadImageDecodeQRCode={setUploadImageDecodeQRCode}
      />
      <div className="div-line-color" />
      <Container fluid>
        <Row>
          <Col xs="0" sm="0" md="4" lg="4" xl="4" />
          <Col xs="12" sm="12" md="4" lg="4" xl="4">
            <p className="text-input-user-ihr">User ihr (User ที่ใช้ลางาน) <span style={{ color:'red' }}>*</span> </p>
            <InputNormal 
            onChange={ e => handleInput(e.target.value)}
            />
            {
              errorInvalidInput && <p className="add-font-family" style={{ color:'red' , margin:'5px auto auto 5px' }}>{errorInvalidInput}</p>
            }
            <ButtonPrimary
                style={{ width:'150px' , display : 'block' , margin:'20px auto' }}
                className="icon-txt-button2-by-nile"
                text="ค้นหา"
                onClick={() => searchIhr_code()}
              />
          </Col>
          <Col xs="0" sm="0" md="4" lg="4" xl="4" />
        </Row>
      </Container>
      <br /> <br />
    </div>
  );
}
