import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import InputNormal from "../../components/InputNormal";
import SelectFilterClear from "../../components/SelectFilterClear";
import TableStudents from "./TableStudents";
import ButtonPrimary from "../../components/ButtonPrimary";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeeRequest } from "../../redux/actions";
import { BiSearch, BiUserPlus } from "react-icons/bi";
import { Form } from "antd";
import Factory from "../../assets/data/Factory";

export default function Employee() {
  var history = useHistory();
  const dispatch = useDispatch();
  const { employee, UserInformation } = useSelector((state) => state);
  const [isRoot, setIsRoot] = useState(false);
  const [search, setSearch] = useState({
    name: null,
    department_id: null,
    factory_id: null,
  });
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  });

  function handleSelectFactory(value) {
    setSearch({
      ...search,
      factory_id: value ? value : null,
    });

    const payload = {
      page: 1,
      limit: 10,
      factory_id: value ? value : null,
      name: search.name,
      department_id: search.department_id,
    };
    dispatch(getEmployeeRequest(payload));
  }

  useEffect(() => {
    let checkUserInformation = false;
    if (UserInformation && checkUserInformation === false) {
      if (UserInformation.is_root !== null) {
        setIsRoot(UserInformation.is_root);
        if (UserInformation.is_root === true) {
          const payload = {
            page: 1,
            limit: 10,
          };
          dispatch(getEmployeeRequest(payload));
        } else {
          const payload = {
            page: 1,
            limit: 10,
            factory_id: UserInformation.factory_id,
          };
          dispatch(getEmployeeRequest(payload));
          setSearch({
            ...search,
            factory_id: UserInformation.factory_id,
          });
        }
      }
    }
    return () => {
      checkUserInformation = true;
    };
  }, [UserInformation]);

  useEffect(() => {
    return () => {
      const payload = {
        clear: true,
      };
      dispatch(getEmployeeRequest(payload));
    };
  }, []);

  useEffect(() => {
    setPage_limit({
      ...page_limit,
      limit: employee.totalCount,
    });

    return () => {};
  }, [employee]);

  function searchEmployee() {
    const payload = {
      page: 1,
      limit: page_limit.page_Size,
      ...search,
    };
    dispatch(getEmployeeRequest(payload));
    setPage_limit({
      ...page_limit,
      page: 1,
    });
  }

  const onFinish = (values) => {
    searchEmployee();
  };

  return (
    <Container fluid>
      <Form name="Form-search-employee" onFinish={onFinish} autoComplete="off">
        <Row>
          <Col xs={12} sm={12} md={6} lg={3} xl={3}>
            <InputNormal
              onChange={(e) =>
                e.target.value
                  ? setSearch({
                      ...search,
                      name: e.target.value,
                    })
                  : setSearch({
                      ...search,
                      name: null,
                    })
              }
              className="text-header-date-picker"
              title="Search"
              placeholder="Firstname , Lastname"
            />
          </Col>
          {isRoot === true && (
            <Col xs={12} sm={12} md={6} lg={3} xl={3}>
              <SelectFilterClear
                classNameTextHeader=" text-header-date-picker"
                headerSelectFilterClear="Factory"
                handleChange={(value) => handleSelectFactory(value)}
                data={Factory}
                value="id"
                name="name"
                require={false}
              />
            </Col>
          )}
          <Col xs={6} sm={6} md={3} lg={3} xl={2}>
            <ButtonPrimary
              className="button-new-student-by-nile"
              style={{ width: "100%", marginTop: "60px" }}
              text={
                <>
                  <span
                    className="text-in-button-employee"
                    style={{ color: "#134e96" }}
                  >
                    Search
                  </span>
                  <BiSearch
                    size="25px"
                    className="icons-in-button-new-employee"
                    style={{ color: "#134e96" }}
                  />
                </>
              }
              onClick={() => searchEmployee()}
            />
          </Col>

          <Col xs={6} sm={6} md={3} lg={3} xl={2}>
            <ButtonPrimary
              className="button-new-student-by-nile"
              style={{ width: "100%", marginTop: "60px" }}
              text={
                <>
                  <p className="text-in-button-employee">
                    <span style={{ color: "#134e96" }}>New Employee</span>
                  </p>
                  <BiUserPlus
                    size="25px"
                    className="icons-in-button-new-employee"
                    style={{ color: "#134e96" }}
                  />
                </>
              }
              onClick={() => history.push("/employee/new-employee")}
            />
          </Col>
        </Row>
      </Form>
      <br />
      <Row>
        <Col>
          <TableStudents
            page_limit={page_limit}
            setPage_limit={setPage_limit}
            employee={employee}
            search={search}
          />
        </Col>
      </Row>
      <br /> <br />
    </Container>
  );
}
