import React from "react";
import { Input, Select } from "antd";
import { IoMdArrowDropdown } from "react-icons/io";
import "antd/dist/antd.css";
import { IoSearchOutline } from "react-icons/io5";

const { Option } = Select;

export default function InputWithoutTitle() {
  return (
    <div className="div-DatePicker-style-custom">

      <Input.Group
        compact
        style={{ background: "#ebf4ff", borderRadius: "25px", width: "100%" }}
      >
        <Input
          prefix={
            <IoSearchOutline
              style={{
                color: "rgba(0, 0, 0, 0.25)",
                fontSize: "1.5rem",
                marginRight: "15px",
              }}
            />
          }
          style={{ width: "55%" }}
          className="input-style-by-nile"
          placeholder="Search"
        />
        <div style={{ width: "5%" }}>
          <div
            style={{
              height: "50%",
              borderRight: "2px solid rgba(0, 0, 0, 0.25)",
              position: "absolute",
              top: "12px",
              left: "57%",
            }}
          />
        </div>

        <Select
          defaultValue="First Name"
          style={{ width: "40%" }}
          suffixIcon={
            <IoMdArrowDropdown
              style={{ fontSize: "1.7rem", marginLeft: "-20px" }}
            />
          }
        >
          <Option value="First Name">First Name</Option>
          <Option value="Last Name">Last Name</Option>
          <Option value="Employee ID">Employee ID</Option>
        </Select>
      </Input.Group>
    </div>
  );
}
