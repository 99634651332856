import React from "react";
import { DatePicker } from "antd";
import "antd/dist/antd.css";

export default function SelectDateTime({
  headerComponents,
  onChange,
  required,
}) {
  return (
    <div className="div-DatePicker-style-custom">
      <p className="text-header-date-picker">
        {headerComponents}
        {required && <span style={{ color: "red" }}>*</span>}
      </p>
      <DatePicker
        className="DatePicker-style-custom"
        showTime
        onChange={onChange}
      />
    </div>
  );
}
