import React from "react";
import Header from "./Header";
import LeftBarMenu from "./LeftBarMenu";

export default function Layout({ children }) {

  return (
    <div>
      <Header />
      <LeftBarMenu />
      <div className="h55px" />
      <div className="px-6per">
        <br />
        {children}
      </div>
    </div>
  );
}
