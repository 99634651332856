const factory = [
  { id: 1, name: "BKF บางนา กม.21" },
  { id: 2, name: "PTF โคราช ปักธงชัย" },
  { id: 3, name: "RBF ราชบุรี บ้านโป่ง" },
  { id: 4, name: "KBF ปราจีนบุรี กบิน" },
  { id: 5, name: "TRF อยุธยา ท่าเรือ" },
  { id: 6, name: "KRF โคราช โคกกรวด" },
  { id: 7, name: "BPF สระบุรี หนองแค" },
  { id: 8, name: "KTF สระบุรี ธารเกษม" },
  { id: 9, name: "HYF หาดใหญ่" },
  { id: 10, name: "SRF ชลบุรี ศรีราชา" },
  { id: 11, name: "PLF พิษณุโลก สมอแข" },
  { id: 12, name: "KKF ขอนแก่น ท่าพระ" },
  { id: 13, name: "LPF ลำพูน ถนนซุปเปอร์ไฮเวย์" },
  { id: 14, name: "SKF ศรีสะเกษ" },
];

export default factory