import { useEffect, useState } from "react";
import { IoChevronDownOutline, IoClose } from "react-icons/io5";
import { Modal, Pagination , Tooltip } from "antd";
import { Row, Col, Container } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeeRequest } from "../../../redux/actions";
import TableLogComponents from "../../../components/TableLogComponents";
import InputNormalHasValue from "../../../components/InputNormalHasValue";
import ButtonPrimary from "../../../components/ButtonPrimary";
import { BiSearch } from "react-icons/bi";
import { BsPersonCheck } from "react-icons/bs";

export default function ModalSelectEmployee({ searchLog, setSearchLog }) {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [employeeName, setEmployeeName] = useState("");
  const [employeeSelect, setEmployeeSelect] = useState(null);
  const [page_limit, setPage_limit] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  });
  const employee = useSelector((state) => state.employee);

  function onCancel() {
    const payload = {
        clear: true,
      };
    setEmployeeName("")
    setPage_limit({
      ...page_limit,
      page: 1,
      limit: 0,
      page_Size: 10,
    });
    dispatch(getEmployeeRequest(payload));
    setVisible(false);
  }

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 10
    };
    dispatch(getEmployeeRequest(payload));
    return () => {
      const payload = {
        clear: true,
      };
      dispatch(getEmployeeRequest(payload));
    };
  }, []);

  useEffect(() => {
    setPage_limit({
      ...page_limit,
      limit: employee.totalCount,
    });

    return () => {};
  }, [employee]);

  function searchEmployee() {
    setVisible(true)
    let payload = {
      page: 1,
      limit: page_limit.page_Size,
      name: employeeName,
    };
    dispatch(getEmployeeRequest(payload));
    setPage_limit({
      ...page_limit,
      page: 1,
    });
  }

  function changePagination(page, pageSize) {
    const payload = {
      page: page,
      limit: pageSize,
      name: employeeName,
    };
    dispatch(getEmployeeRequest(payload));
    setPage_limit({
      ...page_limit,
      page: page,
      page_Size: pageSize,
    });
  }

  const rowTableLogRealtime = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Firstname",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Lastname",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
        render: (data) => (
          <Tooltip title={`Select ${data.first_name}`}>
            <div className="cursor-pointer">
              <BsPersonCheck
                color="green"
                size="25"
                onClick={() => selectEmployee(data) }
              />
            </div>
          </Tooltip>
        ),
      },
  ];

  function selectEmployee(data) {
    setEmployeeSelect(data.first_name)
    setSearchLog({
        ...searchLog,
        employee_id : data.employee_id
    })
    onCancel()
  }

  function clearEmployeeSelected() {
    setEmployeeSelect(null)
    setSearchLog({
        ...searchLog,
        employee_id : null
    })
    onCancel()
  }

  return (
    <div>
      <p className="text-header-date-picker">Select employee</p>
      {employeeSelect === null && (
        <div
          className="cursor-pointer div-none-select-employee-modal"
          onClick={() => searchEmployee()}
        >
          <IoChevronDownOutline
            size="25px"
            color="rgba(0, 0, 0, 0.25)"
            style={{ position: "absolute", right: "10px", top: "15px" }}
          />
        </div>
      )}

      {employeeSelect !== null && (
        <div className="div-none-select-employee-modal">
          <p
            className="text-header-date-picker"
            style={{ position: "absolute", top: "10px", left: "10px" }}
          >
            {employeeSelect}
          </p>
          <IoClose
            className="cursor-pointer"
            size="25px"
            color="#FF6C5D"
            style={{ position: "absolute", right: "10px", top: "15px" }}
            onClick={() => clearEmployeeSelected()}
          />
        </div>
      )}

      <Modal
        closable={false}
        visible={visible}
        onCancel={() => onCancel()}
        footer={null}
        centered
        width={1000}
      >
        <Container fluid>
          <div
            style={{ overflowY: "auto", height: "400px", overflowX: "hidden" }}
          >
            <Row>
              <Col xs={8} sm={8} md={8} lg={8} xl={9}>
                <InputNormalHasValue
                  onChange={(e) =>
                    setEmployeeName(e.target.value ? e.target.value : "")
                  }
                  className="text-header-date-picker"
                  title="Search"
                  placeholder="Firstname , Lastname"
                  value={employeeName}
                />
              </Col>

              <Col xs={4} sm={4} md={4} lg={4} xl={3}>
                <ButtonPrimary
                  className="button-new-student-by-nile"
                  style={{ width: "100%", marginTop: "63px" }}
                  text={
                    <>
                      <span
                        className="text-in-button-employee"
                        style={{ color: "#134e96" }}
                      >
                        Search
                      </span>
                      <BiSearch
                        size="25px"
                        className="icons-in-button-new-employee"
                        style={{ color: "#134e96" }}
                      />
                    </>
                  }
                  onClick={() => searchEmployee()}
                />
              </Col>
            </Row>

            <div>
              <TableLogComponents
                columns={rowTableLogRealtime}
                dataSource={employee.employee}
                loading={employee.loading}
              />
              <div style={{ float: "right", marginTop: "10px" }}>
                <Pagination
                  current={page_limit.page}
                  total={page_limit.limit}
                  onChange={changePagination}
                />
              </div>
            </div>
          </div>
        </Container>
      </Modal>
    </div>
  );
}
