import { useState } from "react";
import { Container, Row, Col } from "reactstrap";
// import TempaturePage from "./TempaturePage/TempaturePage";
import SelectDateTime from "../../components/SelectDateTime";
import ButtonPrimary from "../../components/ButtonPrimary";
import moment from "moment";
import { VscFilePdf } from "react-icons/vsc";
import axios from 'axios';
import * as api from '../../Service/Api_Muthermfacesense';
import openNotificationWithIcon from "../../components/NotificationAlertAntd";

export default function ReportRecord() {
  const [error, setError] = useState(null);
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false)
  const [datetimeSelected, setDatetimeSelected] = useState({
    startDate: null,
    endDate: null,
  });

  function onChangeDateTime(datetime, dateString, nameState) {
    if (dateString) {
      setError(null);
      let d = new Date(dateString);
      let dateISO = d.toISOString();
      let UnixTimestamp = moment(dateISO).unix();
      setDatetimeSelected({
        ...datetimeSelected,
        [nameState]: UnixTimestamp,
      });
    } else {
      setDatetimeSelected({
        ...datetimeSelected,
        [nameState]: null,
      });
    }
  }

  const exportExcel = () => {
    switch (true) {
      case datetimeSelected.startDate === null:
        setError("Please select start date");
        break;

      case datetimeSelected.endDate === null:
        setError("Please select end date");
        break;

      case datetimeSelected.startDate !== null &&
        datetimeSelected.endDate !== null:
        getReportExcel()
        setError(null);
        break;

      default:
        break;
    }
  };

  const getReportExcel = () => {
    setLoading(true)
    // console.log(datetimeSelected);
    const getReportExcelApi = api.GET_REPORT_API(token , datetimeSelected.startDate , datetimeSelected.endDate )
    // console.log(getReportExcelApi);
    axios(getReportExcelApi)
    .then( res => {
      setLoading(false)
      openNotificationWithIcon("success", "Get report success");
      // console.log(res.data);
    })
    .catch( error => {
      setLoading(false)
      openNotificationWithIcon("error", "Get report error");
      // console.log(error);
      
    })
  }

  return (
    <Container fluid>
      <p className="txt-top-title-school-by-nile">
        Select datetime start to end
      </p>
      <br />
      <Row>
        <Col xs={12} sm={12} md={6} lg={6} xl={3}>
          <SelectDateTime
            headerComponents="Start date"
            required={true}
            onChange={(datetime, dateString) =>
              onChangeDateTime(datetime, dateString, "startDate")
            }
          />
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={3}>
          <SelectDateTime
            headerComponents="End date"
            required={true}
            onChange={(datetime, dateString) =>
              onChangeDateTime(datetime, dateString, "endDate")
            }
          />
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={3}>
          <ButtonPrimary
          loading={loading}
            className="button-new-student-by-nile"
            onClick={() => exportExcel()}
            text={
              <>
                <span
                  className="text-in-button-employee"
                  style={{ color: "#134e96" }}
                >
                  Report
                </span>
                <VscFilePdf
                  size={20}
                  style={{ color: "#134e96", marginLeft: "10px" }}
                />
              </>
            }
          />
          {error && <p style={{ color:'#FF4459' , fontSize:"20px" , marginTop:"10px" }}>{error}</p>}
        </Col>
      </Row>
    </Container>
  );
}
