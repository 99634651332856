import Factory from '../assets/data/Factory';

const FindFactoryFromID = (id) => {
    let nameFactory
    Factory.map( data => {
        if(data.id === id){
            nameFactory = data.name
        }
    } )
    return nameFactory
}

export default FindFactoryFromID