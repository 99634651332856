import * as types from "../actions";

const initialState = {
    device_after_select_rpc : [],
    loading: false,
    error: null,
    statusCode: null,
    selectAllDevice : false
  };
  
  const device_after_select_rpc = (state = initialState, action) => {
    switch (action.type) {
      case types.GET_DEVICE_AFTER_SELECT_RPC_REQUEST:
        return { ...state, loading: true };
      case types.GET_DEVICE_AFTER_SELECT_RPC_SUCCESS:
        return {
          ...state,
          loading: false,
          device_after_select_rpc: action.device_after_select_rpc,
          selectAllDevice : action.selectAllDevice
        };
      case types.GET_DEVICE_AFTER_SELECT_RPC_FAILED:
        return {
          ...state,
          loading: false,
          error: action.error,
          statusCode: action.statusCode,
          selectAllDevice : action.selectAllDevice
        };
      default:
        return state;
    }
  };
  
  export default device_after_select_rpc;