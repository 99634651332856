import React from 'react'
import { Row, Col } from "reactstrap";
import {
  DustImage,
  TemperaturesImage,
  HumidityImage,
} from "../../assets/images";
import DisplayEnvironment from "../../components/DisplayEnvironment";

export default function Environment({ meta_data }) {
 
  return (
    <div className="box-details-by-nile">
      <p className="txt-sub-header1-by-nile">Environment</p>
      {
        meta_data !== null && (
          <Row>
          <Col xs={12} sm={12} md={6} lg={6} xl={4}>
            <DisplayEnvironment 
            imageDisplayEnvironment={DustImage}
            headerDisplayEnvironment="PM 2.5"
            valueDisplayEnvironment="15 μg/m3"
            />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={4}>
            <DisplayEnvironment 
            imageDisplayEnvironment={TemperaturesImage}
            headerDisplayEnvironment="Temperature"
            valueDisplayEnvironment={`${meta_data.TemperatureRef ? meta_data.TemperatureRef.toFixed(1) : 0} °C`}
            />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={4}>
            <DisplayEnvironment 
            imageDisplayEnvironment={HumidityImage}
            headerDisplayEnvironment="Humidity"
            valueDisplayEnvironment={`${meta_data.humid ? meta_data.humid : 0} %`}
            />
          </Col>
        </Row>
        )
      }
    </div>
  )
}
