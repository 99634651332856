import { put, takeEvery, call } from "redux-saga/effects";
import {
    GET_FACTORY_REQUEST,
  GET_FACTORY_SUCCESS,
  GET_FACTORY_FAILED,
} from "../actions";
import * as api from "../../Service/Api_Muthermfacesense";
import axios from "axios";

const token = localStorage.getItem("token");

function factoryFetch() {
    const factory = api.GET_FACTORY_ALL(token);
    return axios(factory)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  function* handleGetFactory() {
    try {
      const responseFactory = yield call(factoryFetch);
      yield put({
        type: GET_FACTORY_SUCCESS,
        factory: responseFactory.message,
      });
    } catch (err) {
      yield put({
        type: GET_FACTORY_FAILED,
        error: err.response.data.detail,
        statusCode: err.response.status,
      });
    }
  }
  
  function* factory() {
    yield takeEvery(GET_FACTORY_REQUEST, handleGetFactory);
  }
  
  export default factory;