import React, { useState } from "react";
import ButtonPrimary from "./ButtonPrimary";
import { MdArrowDropDown } from "react-icons/md/index";
import { BiFilterAlt } from "react-icons/bi";
export default function Dropdown({
  items,
  itemCallback,
  hasFilter,
  styles,
  isButtom,
  // initState,
}) {
  // var init = initState && items[0];
  // console.log(init);
  const [itemNow, setItem] = useState(items[0]);
  var top = isButtom ? { bottom: "3.6rem" } : { top: "3.6rem" };
  var height = isButtom && { height: "auto" };

  return (
    <div className="box-dropdown-filter-cover">
      <ButtonPrimary
        className="box-dropdown-filter"
        text={
          <>
            {hasFilter ? <BiFilterAlt style={{ fontSize: "1.3rem" }} /> : <></>}
            {itemNow}
            <MdArrowDropDown style={{ fontSize: "1.7rem" }} />
          </>
        }
      />
      <div
        className="box-dropdown-filter-selector"
        style={{ ...styles, ...top, ...height }}
      >
        <ul>
          {items.map((item, key) => {
            return (
              <li
                key={key}
                onClick={() => {
                  setItem(item);
                  itemCallback(item, key);
                }}
              >
                <p className="txt-drop-down">{item}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
