import React from "react";

export default function InputNormalHasValue({
  className,
  title,
  onChange,
  require,
  error,
  textError,
  placeholder,
  value
}) {
  const mgTop = {
    marginTop: "10px",
  };
  function checkRequire(require) {
    if (require) {
      return <span style={{ color: "red" }}>*</span>;
    }
  }

  return (
    <div style={mgTop}>
      <p className={className ? className : "txt-sub-explanation1"}>
        {title} {checkRequire(require)}{" "}
      </p>
      <input
        className="text-field"
        placeholder={ placeholder ? placeholder : title}
        style={mgTop}
        onChange={onChange}
        value={value}
      />
      {error && <p className="txt-show-error-required"> {textError} </p>}
    </div>
  );
}
