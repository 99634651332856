import React from "react";

const SwitchToggleComponents = ({ textRight, textLeft, checked, onChange }) => {
  let displayStyle = checked ? "btn-success-test" : "btn-offstyle-test";

  return (
    <>
      <label>
        <span className="switch-label-text-left add-font-family">{textLeft}</span>
        <span className={`default switch-wrapper`}>
          <input
            type="checkbox"
            checked={checked}
            onChange={(e) => onChange(e)}
          />
          <span className={`${displayStyle} switch`}>
            <span className="switch-handle" />
          </span>
        </span>
        <span className="switch-label-text-right add-font-family">{textRight}</span>
      </label>
    </>
  );
};

export default SwitchToggleComponents;
