import React from "react";

export default function DisplayTextValueImageTrain({ header , value }) {
  return (
    <div>
      <p className="text-header-train-image-from-activities-public-page" style={{ color:'black' , textAlign:'left' }}>
        {header} : <span className="text-header-train-image-from-activities-public-page"> {value} </span>
      </p>
    </div>
  );
}
