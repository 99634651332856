import React,{ useEffect } from "react";
import { UthermHalfImg } from "../assets/images";
import { Row, Col } from "reactstrap";
import CircleIndicator from "./CircleIndicator";

export default function BoxDeviceDonut({ serialNumberDevice , place , ipAddress }) {

  return (
    <div className="box-device-by-nile">
      <Row>
        <Col>
          <div className="row-jcst-atfs">
            <div className="col-st-gab1">
              <img src={UthermHalfImg} alt="UthermHalfImg" />
            </div>
            <div>
              <p className="txt-main-content1">{serialNumberDevice}</p>
              <p className="txt-sub-explanation1">{place}</p>
              <p className="txt-sub-explanation1">{ipAddress}</p>
            </div>
          </div>
        </Col>
      </Row>
      <br />      <br />
      <Row>
        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
          <CircleIndicator percent={0.25} mode={0} value={"35C ํ"} />
        </Col>
        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
          <CircleIndicator percent={0.5} mode={1} value={"125RPM"} />
        </Col>
        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
          <CircleIndicator percent={0.75} mode={2} value={"55MHz"} />
        </Col>
        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
          <CircleIndicator percent={0.125} mode={3} value={"150W"} />
        </Col>
      </Row>
    </div>
  );
}