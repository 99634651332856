import { put, takeEvery, call } from "redux-saga/effects";
import {
  GET_EMPLOYEE_REQUEST,
  GET_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE_FAILED,
  CLEAR_EMPLOYEE,
} from "../actions";
import * as api from "../../Service/Api_Muthermfacesense";
import axios from "axios";

const token = localStorage.getItem("token");

const checkParameter = (params, data) => {
  if (data) {
    return params + data;
  } else {
    return "";
  }
};

function employeeFetch(payload) {
  const url = `api/employee/?page=${payload.page}&limit=${
    payload.limit
  }${checkParameter("&name=", payload.name)}${checkParameter(
    "&department_id=",
    payload.department_id
  )}${checkParameter("&factory_id=", payload.factory_id)}`;

  const employee = api.GET_DYNAMIC_URL(token, url);
  return axios(employee)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

function* handleGetEmployee({ payload }) {
  if (payload.clear) {
    yield put({
      type: CLEAR_EMPLOYEE,
    });
  } else {
    try {
      const responseEmployee = yield call(employeeFetch, payload);
      yield put({
        type: GET_EMPLOYEE_SUCCESS,
        employee: responseEmployee.message.data,
        totalCount: responseEmployee.message.totalcount.count,
        totalPage: responseEmployee.message.totalpage.count,
      });
    } catch (err) {
      yield put({
        type: GET_EMPLOYEE_FAILED,
        error: err.response.data.detail,
        statusCode: err.response.status,
      });
    }
  }
}

function* employee() {
  yield takeEvery(GET_EMPLOYEE_REQUEST, handleGetEmployee);
}

export default employee;
