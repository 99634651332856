import React from "react";
import { no_image } from '../assets/images'

export default function DisplayImageOneTable({ Image }) {
  return (
    <div className="div-display-image-table-activities" style={{ justifyContent : "center" }} >
           <div className="div-image-log">
        <img className="image-components-display" src={Image ? Image : no_image } alt="Image" />
        <div className="text-in-image-components-display">
            Log
        </div>
      </div>
    </div>
  );
}
