import React from 'react'
import { Row, Col } from "reactstrap";
import DisplayValueBoxCPU from '../../components/DisplayValueBoxCPU';

export default function CPU() {
  return (
<div className="box-details-by-nile">
      <p className="txt-sub-header1-by-nile">CPU</p>

      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <DisplayValueBoxCPU
            textHeader={<p className="txt-main-content1">Temperature</p>}
            textValue={
              <p className="txt-main-content1 mb-10px">
                <span className="col-part-lg-by-nile">:</span>
                <span className="col-part-sm-by-nile">-</span> 35.5 °C
              </p>
            }
          />
          <DisplayValueBoxCPU
            textHeader={<p className="txt-main-content1">Fan Speed</p>}
            textValue={
              <p className="txt-main-content1 mb-10px">
                <span className="col-part-lg-by-nile">:</span>
                <span className="col-part-sm-by-nile">-</span> 125 RPM
              </p>
            }
          />

          <DisplayValueBoxCPU
            textHeader={<p className="txt-main-content1">Frequentcy</p>}
            textValue={
              <p className="txt-main-content1 mb-10px">
                <span className="col-part-lg-by-nile">:</span>
                <span className="col-part-sm-by-nile">-</span> 15 MHz
              </p>
            }
          />

          <DisplayValueBoxCPU
            textHeader={<p className="txt-main-content1">Power</p>}
            textValue={
              <p className="txt-main-content1 mb-10px">
                <span className="col-part-lg-by-nile">:</span>
                <span className="col-part-sm-by-nile">-</span> 150 W
              </p>
            }
          />
        </Col>
      </Row>
    </div>
  )
}
