import { useState, useEffect, useContext } from "react";
import { user } from "../../assets/images";
import { Row, Col } from "reactstrap";
import InputNormalHasValue from "../../components/InputNormalHasValue";
import ButtonPrimary from "../../components/ButtonPrimary";
import SelectFilterClear from "../../components/SelectFilterClear";
import fineDataFromArray from "../../components/FindDataFromArray";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { Modal } from "antd";
import axios from "axios";
import { useDispatch } from "react-redux";
import { getEmployeeOneAndFeatureRequest } from "../../redux/actions";
import * as api from "../../Service/Api_Muthermfacesense";
import NotificationAlertAntd from "../../components/NotificationAlertAntd";
import { useSelector } from "react-redux";
import { EditEmployeeOneContext } from "../../components/Context";

export default function EditCardProfileInfo({
  editProfile,
  employeeProfile,
  setEmployeeProfile,
  setEditProfile,
  editRpcZoneEmployee,
  setEditRpcZoneEmployee,
  defaultRpcZoneEmployee,
  setDefaultRpcZoneEmployee,

}) {
  const { employee, employee_id, department, factory } = useContext(
    EditEmployeeOneContext
  );

  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isRoot, setIsRoot] = useState(false);
  const [error, setError] = useState({
    errorCode: false,
    errorFirst_name: false,
    errorLast_name: false,
    errorDepartment_id: false,
    errorFactory_id: false,
  });
  const { UserInformation } = useSelector((state) => state);

  useEffect(() => {
    if (UserInformation && UserInformation.is_root !== null) {
      setIsRoot(UserInformation.is_root);
    }

    return () => {};
  }, [UserInformation]);

  function onChange(value, nameState, nameError) {
    if (value) {
      setEmployeeProfile({
        ...employeeProfile,
        [nameState]: value,
      });
      setError({
        ...error,
        [nameError]: false,
      });
    } else {
      setEmployeeProfile({
        ...employeeProfile,
        [nameState]: "",
      });
      setError({
        ...error,
        [nameError]: true,
      });
    }
  }

  function onChangeDataCode(value) {
    let valueCode = value;
    valueCode = value.replace(/[^\x00-\x7F]+/gi, "");
    if (valueCode) {
      setEmployeeProfile({
        ...employeeProfile,
        code: valueCode,
      });
      setError({
        ...error,
        errorCode: false,
      });
    } else {
      setEmployeeProfile({
        ...employeeProfile,
        code: "",
      });
      setError({
        ...error,
        errorCode: true,
      });
    }
  }

  function handleChangeSelect(value, nameState, nameError) {
    if (value) {
      setEmployeeProfile({
        ...employeeProfile,
        [nameState]: value,
      });
      setError({
        ...error,
        [nameError]: false,
      });
    } else {
      setEmployeeProfile({
        ...employeeProfile,
        [nameState]: null,
      });
      setError({
        ...error,
        [nameError]: true,
      });
    }
  }

  function checkEditData() {
    if (
      employeeProfile.code === "" ||
      employeeProfile.first_name === "" ||
      employeeProfile.last_name === "" ||
      employeeProfile.department_id === null ||
      employeeProfile.factory_id === null
    ) {
      setVisibleAlert(true);
    } else {
      setVisibleEdit(true);
    }
  }

  function editEmployee() {
    setLoading(true);
    const edit = api.EDIT_EMPLOYEE_ONE(token, employee_id, employeeProfile);
    axios(edit)
      .then((res) => {
        setLoading(false);
        setVisibleEdit(false);
        NotificationAlertAntd("success", "แก้ไขพนักงานสำเร็จ");
        dispatch(getEmployeeOneAndFeatureRequest(employee_id));
        setEditProfile(false);
      })
      .catch((error) => {
        setLoading(false);
        setVisibleEdit(false);

        if (error) {
          if (error.response) {
            if (error.response.status === 409) {
              NotificationAlertAntd("error", "รหัสพนักงานนี้มีการใช้งานแล้ว");
            } else {
              NotificationAlertAntd(
                "error",
                error.response.data.detail
                  ? error.response.data.detail
                  : "แก้ไขพนักงานไม่สำเร็จ (4xx) : " +
                      error.response.status +
                      " : " +
                      error.response.statusText
              );
            }
          }
        } else {
          NotificationAlertAntd("error", "แก้ไขพนักงานไม่สำเร็จ (500)");
        }
      });
  }

  return (
    <>
      <Row>
        <Col xs={12} sm={12} md={6} lg={4} xl={4}>
          <InputNormalHasValue
            title="Firstname"
            onChange={(e) =>
              onChange(e.target.value, "first_name", "errorFirst_name")
            }
            require={true}
            error={error.errorFirst_name}
            textError="Firstname is require"
            placeholder="firstname"
            value={employeeProfile.first_name}
          />
          <InputNormalHasValue
            title="Lastname"
            onChange={(e) =>
              onChange(e.target.value, "last_name", "errorLast_name")
            }
            require={true}
            error={error.errorLast_name}
            textError="Lastname is require"
            placeholder="lastname"
            value={employeeProfile.last_name}
          />

          <InputNormalHasValue
            title="Code"
            onChange={(e) => onChangeDataCode(e.target.value)}
            require={true}
            error={error.errorCode}
            textError="Code is require"
            placeholder="code"
            value={employeeProfile.code}
          />
        </Col>

        <Col xs={12} sm={12} md={6} lg={4} xl={4}>
          {isRoot === true && (
            <SelectFilterClear
              headerSelectFilterClear="Factory"
              handleChange={(value) =>
                handleChangeSelect(value, "factory_id", "errorFactory_id")
              }
              data={factory}
              value="id"
              name="factory_name"
              require={true}
              error={error.errorFactory_id}
              textError="Factory is require"
              defaultValue={fineDataFromArray(
                factory,
                employeeProfile.factory_id,
                "factory_name"
              )}
            />
          )}

          <SelectFilterClear
            headerSelectFilterClear="Department"
            handleChange={(value) =>
              handleChangeSelect(value, "department_id", "errorDepartment_id")
            }
            data={department}
            value="id"
            name="department_name"
            require={true}
            error={error.errorDepartment_id}
            textError="Department is require"
            defaultValue={fineDataFromArray(
              department,
              employeeProfile.department_id,
              "department_name"
            )}
          />
        </Col>

        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
          <img
            className="box-image-profile-lg-by-nile"
            src={user}
            alt="employee"
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={12} sm={12} md={6} lg={4} xl={4}>
          <ButtonPrimary
            className="button-new-student-by-nile"
            style={{ width: "100%", background: "#6DD765" }}
            text={
              <>
                <p style={{ marginTop: "10px" }}>
                  <span style={{ color: "#FFF" }}>Save</span>
                </p>
              </>
            }
            onClick={() => checkEditData()}
          />
        </Col>
        <Col xs={12} sm={12} md={6} lg={4} xl={4}>
          <ButtonPrimary
            className="button-new-student-by-nile"
            style={{ width: "100%" }}
            text={
              <>
                <p style={{ marginTop: "10px" }}>
                  <span style={{ color: "gray" }}>Cancel</span>
                </p>
              </>
            }
            onClick={() => setEditProfile(false)}
          />
        </Col>
      </Row>

      <Modal
        centered
        closable={false}
        visible={visibleEdit}
        onOk={() => setVisibleEdit(false)}
        onCancel={() => setVisibleEdit(false)}
        footer={null}
        className="modalStyle-ModalSelectATK"
      >
        <br />
        <p className="txt-confirm-create-new-employee">
          <AiOutlineQuestionCircle
            size="30"
            color="#faad14"
            style={{ marginRight: "10px" }}
          />
          ยืนยันการแก้ไขข้อมูลพนักงาน
        </p>
        <br />
        <Row>
          <Col xs="12" sm="12" md="6" lg="6" xl="6">
            <br />
            <ButtonPrimary
              className="button2-save-create-new-employee-by-nile"
              text="ยืนยัน"
              onClick={() => editEmployee()}
              loading={loading}
            />
          </Col>
          <Col xs="12" sm="12" md="6" lg="6" xl="6">
            <br />
            <ButtonPrimary
              className="icon-txt-button2-by-nile"
              text="ยกเลิก"
              onClick={() => setVisibleEdit(false)}
            />
          </Col>
        </Row>
      </Modal>

      <Modal
        centered
        closable={false}
        visible={visibleAlert}
        onOk={() => setVisibleAlert(false)}
        onCancel={() => setVisibleAlert(false)}
        footer={null}
        className="modalStyle-ModalSelectATK"
      >
        <br />
        <p className="txt-confirm-create-new-employee">
          <AiOutlineQuestionCircle
            size="30"
            color="#1890ff"
            style={{ marginRight: "10px" }}
          />
          โปรดกรอกข้อมูลพนักงานให้ครบ
        </p>
        <br />
        <Row>
          <Col>
            <ButtonPrimary
              className="icon-txt-button2-by-nile"
              text="close"
              onClick={() => setVisibleAlert(false)}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
}
