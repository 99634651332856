import React from "react";
import { Bar } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  BarController,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  BarController
);

ChartJS.defaults.font.family = "Kanit";

export default function BarChartTotal() {
  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September ",
    "October",
    "November",
    "December ",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "ทั้งหมด",
        data: [10, 20, 30, 40, 50, 50, 30, 10, 15, 12, 11, 30],
        backgroundColor: "rgba(186, 178, 244, 1)",
        borderRadius: {
          topLeft: Number.MAX_VALUE,
          topRight: Number.MAX_VALUE,
          bottomLeft: Number.MAX_VALUE,
          bottomRight: Number.MAX_VALUE,
        },
        barPercentage: 0.3,
        borderSkipped: "false",
      },
      {
        label: "อุณหภูมิเกิน",
        data: [2, 3, 9, 1, 2, 3, 5, 7, 9, 4, 3, 8],
        backgroundColor: "rgba(255, 132, 132, 0.5)",
        borderRadius: Number.MAX_VALUE,
        barPercentage: 0.3,
        borderSkipped: "false",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        align: "end",
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart",
        align: "center",
      },
    },
  };

  return <Bar style={{ maxHeight:'250px' }} options={options} data={data} />;
}
