import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRpcRequest } from "../../../redux/actions";
import { Row, Col } from "reactstrap";
import ButtonPrimary from "../../../components/ButtonPrimary";
import { Modal } from "antd";
import SelectRpcTable from "./SelectRpcTable";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import axios from 'axios'
import * as api from '../../../Service/Api_Muthermfacesense';

export default function RpcZoneID() {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [visibleRpcEmpty, setVisibleRpcEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rpc_zone_id, setRpc_zone_id] = useState([]);
  const token = localStorage.getItem("token");

  const rpc = useSelector((state) => state.rpc);

  useEffect(() => {
    dispatch(getRpcRequest());

    return () => {};
  }, []);

  function closeModal() {
    setVisible(false);
  }

  function checkRpcSelectEmpty() {
    if (rpc_zone_id.length === 0) {
      setVisibleRpcEmpty(true);
    } else {
      setVisible(true);
    }
  }

async  function buildRpcZoneAll() {
    setLoading(true)
    const promises = rpc_zone_id.map( data => {
      buildRpcOne(data.rpc_id);
    } )
    await Promise.all(promises)
    setLoading(false)
    closeModal()
  }

  async  function buildRpcOne(rpc_id) {
    const buildRpc = api.BUILD_RPC_ZONE(token , rpc_id)
    await axios(buildRpc)
    .then(res => {
      return res.data
    })
    .catch(error => {
      return error.response
    })
  }

  return (
    <div>
      <Row>
        <Col xs="12" sm="6" md="6" lg="6" xl="6">
          <p className="text-header-date-picker">Select rpc zone</p>
        </Col>
        <Col xs="12" sm="6" md="6" lg="6" xl="6">
          <SelectRpcTable setRpc_zone_id={setRpc_zone_id} rpc={rpc.rpc} />
          <ButtonPrimary
            className="btn-rebuild-all-rpc-in-system-setting"
            text="Build rpc zone"
            onClick={() => checkRpcSelectEmpty()}
          />
        </Col>
      </Row>

      <Modal
        centered
        closable={false}
        visible={visible}
        onOk={() => closeModal()}
        onCancel={() => closeModal()}
        footer={null}
        // width={800}
        className="modalStyle-ModalSelectATK"
      >
        <p className="txt-confirm-create-new-employee">
          <AiOutlineQuestionCircle
            size="30"
            color="#faad14"
            style={{ marginRight: "10px" }}
          />
          Confirm build rpc zone
        </p>
        <p>
          <span className="txt-confirm-create-new-employee">
            rpc zone select :{" "}
          </span>
          {rpc_zone_id.map((data, index) => {
            if (data.check === true && index + 1 !== rpc_zone_id.length) {
              return <span key={index}>{data.rpc_name} , </span>;
            } else if (
              data.check === true &&
              index + 1 === rpc_zone_id.length
            ) {
              return <span key={index}>{data.rpc_name} </span>;
            }
          })}
        </p>
        <Row>
          <Col xs="12" sm="12" md="6" lg="6" xl="6">
            <br />
            <ButtonPrimary
              className="button2-save-create-new-employee-by-nile"
              text="Confirm"
              loading={loading}
              onClick={() => buildRpcZoneAll()}
            />
          </Col>
          <Col xs="12" sm="12" md="6" lg="6" xl="6">
            <br />
            <ButtonPrimary
              className="icon-txt-button2-by-nile"
              text="Cancel"
              onClick={() => closeModal()}
            />
          </Col>
        </Row>
      </Modal>

      <Modal
        centered
        closable={false}
        visible={visibleRpcEmpty}
        onOk={() => setVisibleRpcEmpty(false)}
        onCancel={() => setVisibleRpcEmpty(false)}
        footer={null}
        width={350}
        className="modalStyle-ModalSelectATK"
      >
        <p className="txt-confirm-create-new-employee">
          <AiOutlineQuestionCircle
            size="30"
            color="#348dfe"
            style={{ marginRight: "10px" }}
          />
          Please select rpc zone
        </p>
        <br />
        <ButtonPrimary
          style={{ width: "100px", display: "block", margin: "auto" }}
          className="icon-txt-button2-by-nile"
          text="Ok"
          onClick={() => setVisibleRpcEmpty(false)}
        />
      </Modal>
    </div>
  );
}
