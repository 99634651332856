import React from 'react'
import { Row, Col } from "reactstrap";

export default function DisplayTextHeaderAndValue({ headerText, valueText }) {
    return (
        <Row>
            <Col xs={5} sm={5} md={5} lg={4} xl={4}>
            <p className="text-in-card-detail-user-log-by-nile"> {headerText} </p>
            </Col>
            <Col xs={7} sm={7} md={7} lg={8} xl={8}>
            <p className="text-in-card-detail-user-log-by-nile"> {valueText} </p>
            </Col>
        </Row>
    )
}
