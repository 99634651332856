import { put, takeEvery, call } from "redux-saga/effects";
import {
    GET_EMPLOYEE_ONE_AND_FEATURE_REQUEST,
    GET_EMPLOYEE_ONE_AND_FEATURE_SUCCESS,
    GET_EMPLOYEE_ONE_AND_FEATURE_FAILED,
} from "../actions";
import * as api from "../../Service/Api_Muthermfacesense";
import axios from "axios";

const token = localStorage.getItem("token");

function employeeOneFetch(payload) {
  const employeeOne = api.GET_EMPLOYEE_ONE_AND_FEATURE(token , payload);
  return axios(employeeOne)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

function* handleGetEmployeeOne({payload}) {
  try {
    const responseEmployeeOne = yield call(employeeOneFetch , payload);
    yield put({
      type: GET_EMPLOYEE_ONE_AND_FEATURE_SUCCESS,
      employee: responseEmployeeOne.message.employee,
      feauture : responseEmployeeOne.message.feauture
    });
  } catch (err) {
    yield put({
      type: GET_EMPLOYEE_ONE_AND_FEATURE_FAILED,
      error: err.response.data.detail,
      statusCode: err.response.status,
    });
  }
}

function* employeeOne() {
  yield takeEvery(GET_EMPLOYEE_ONE_AND_FEATURE_REQUEST, handleGetEmployeeOne);
}

export default employeeOne;
