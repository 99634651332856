import { put, takeEvery, call } from "redux-saga/effects";
import {
  GET_LOG_REQUEST,
  GET_LOG_SUCCESS,
  GET_LOG_FAILED,
  CLEAR_LOG
} from "../actions";
import * as api from "../../Service/Api_Muthermfacesense";
import axios from "axios";

const token = localStorage.getItem("token");

function logFetch(payload) {
  const logAll = api.GET_NEW_API_LOG_ALL(token , payload.page , payload.limit , payload.deviceSN);
  return axios(logAll)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

function* handleGetLog({payload}) {
  if(payload.clear){
    yield put({
      type: CLEAR_LOG
    });
  } else {
    try {
      const responseLog = yield call(logFetch , payload);
      yield put({
        type: GET_LOG_SUCCESS,
        log: responseLog.message , 
        totalCount : responseLog.total , 
        totalPage : responseLog.total_page , 
      });
    } catch (err) {
      if(err.response){
        yield put({
          type: GET_LOG_FAILED,
          error: err.response.data.detail,
          statusCode: err.response.status
        });
      } else {
        yield put({
          type: GET_LOG_FAILED,
          error: err.message
        });
      }

    }
  }
}

function* log() {
  yield takeEvery(GET_LOG_REQUEST, handleGetLog);
}

export default log;
