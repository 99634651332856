import React from 'react'
import { Tooltip } from "antd";

export default function IconButtonTooltip({
  title,style,onClick , icon, className
}) {
  return (
    <Tooltip title={title}>
    <div
      className={className}
      style={style}
      onClick={onClick}
    >
     {icon}
    </div>
  </Tooltip>
  )
}
