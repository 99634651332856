import { put, takeEvery, call } from "redux-saga/effects";
import {
  GET_RPC_REQUEST,
  GET_RPC_SUCCESS,
  GET_RPC_FAILED,
} from "../actions";
import * as api from "../../Service/Api_Muthermfacesense";
import axios from "axios";

const token = localStorage.getItem("token");

function rpcFetch() {
  const rpc = api.GET_RPC(token);
  return axios(rpc)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

function* handleGetRpc() {
  try {
    const responseRpc = yield call(rpcFetch);
    yield put({
      type: GET_RPC_SUCCESS,
      rpc: responseRpc.result.data,
    });
  } catch (err) {
    yield put({
      type: GET_RPC_FAILED,
      error: err.response.data.detail,
      statusCode: err.response.status,
    });
  }
}

function* rpc() {
  yield takeEvery(GET_RPC_REQUEST, handleGetRpc);
}

export default rpc;
