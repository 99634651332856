import React from "react"
import Dropzone from "react-dropzone"
import { AiOutlineCloudUpload } from "react-icons/ai"
import Resizer from "react-image-file-resizer"
import Compress from "compress.js"
import ButtonPrimary from '../../../components/ButtonPrimary'
import {Buffer} from 'buffer';

const DropzoneTrainImage = ({ setUploadImageDecodeQRCode, setLoadingUpload }) => {

  const compress = new Compress()
  const resizeFile = (file, typeFile) =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        900,
        900,
        typeFile,
        100,
        0,
        uri => {
          resolve(uri)
        },
        "base64"
      )
    })

    const fileToDataUri = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = event => {
        resolve(event.target.result)
      }
      reader.readAsDataURL(file)
    })

  function filterType(type) {
    if (type === "image/jpeg") {
      return "JPEG"
    } else if (type === "image/png") {
      return "PNG"
    }
  }

  const onChangeImage = async e => {
    if (e[0]) {
      if (e[0].type === "image/jpeg" || e[0].type === "image/png") {
        const file = e[0]
        setLoadingUpload(true)
        if(e[0].size > 1500000){
          const image = await resizeFile(file, filterType(e[0].type))
          const buffer = Buffer.from(image.substring(image.indexOf(',') + 1));
          var blob = b64toBlob(image, filterType(e[0].type) , buffer.length);
          var blobUrl = URL.createObjectURL(blob);
          setUploadImageDecodeQRCode(blobUrl)
        } else {
          fileToDataUri(file)
          .then( dataUri => {
            const buffer = Buffer.from(dataUri.substring(dataUri.indexOf(',') + 1));
            var blob = b64toBlob(dataUri, filterType(e[0].type) , buffer.length);
            var blobUrl = URL.createObjectURL(blob);
            setUploadImageDecodeQRCode(blobUrl)
          })
        }
      }
    }
  }

  function b64toBlob(b64Data, contentType, sliceSize) {
    let splitTextB64 = b64Data.split(',')[1]
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
    var byteCharacters = atob(splitTextB64);
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  return (
    <Dropzone
      onDrop={acceptedFiles => onChangeImage(acceptedFiles)}
      multiple={false}
      accept={'image/jpeg, image/png'}
    >
      {({ getRootProps, getInputProps }) => (
        <section>
          <div
            {...getRootProps()}
            style={{
              padding: "10px",
              borderStyle: "dotted",
              borderColor: "#D4D2D2",
              width:'500px',
              maxWidth:'100%',
              display:'block',
              margin:'auto'
            }}
          >
            <input onClick={e => (e.target.value = null)} {...getInputProps()} accept="image/jpeg" />
            <p className="add-font-family" style={{ textAlign: "center", fontSize: "20px" }}>
              อัพโหลดรูปภาพ QR Code
            </p>
            <p className="add-font-family" style={{ textAlign: "center" }}>
              <AiOutlineCloudUpload
                style={{ fontSize: "50px", color: "#134e96" }}
              />
            </p>
            <p className="add-font-family" style={{ textAlign: "center", fontSize: "20px" }}>
              ลากและวางไฟล์รูปที่นี่
            </p>
            <p
            className="add-font-family"
              style={{ textAlign: "center", color: "gray", marginTop: "-20px" }}
            >
              หรือ
            </p>
            <ButtonPrimary
             className="icon-txt-button2-by-nile"
                text="เลือกรูป"
                style={{ width:'150px' , display:'block' , margin:'auto' }}
            /> 
          </div>
        </section>
      )}
    </Dropzone>
  )
}

export default DropzoneTrainImage
