import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import ButtonPrimary from "../../components/ButtonPrimary";
import { useHistory } from "react-router-dom";
import axios from "axios";
import * as api from "../../Service/Api_Muthermfacesense";
import { Modal } from "antd";
import { CgDanger } from "react-icons/cg";
import openNotificationWithIcon from "../../components/NotificationAlertAntd";

export default function RemoveEmployee({ employee }) {
  const history = useHistory();
  const token = localStorage.getItem("token");
  const [loadingRemoveEmployee, setLoadingRemoveEmployee] = useState(false);
  const [visibleRemoveEmployee, setVisibleRemoveEmployee] = useState(false);

  function removeEmployee(employee_id) {
    setLoadingRemoveEmployee(true);
    const remove = api.REMOVE_EMPLOYEE(token, employee_id);
    axios(remove)
      .then((res) => {
        setLoadingRemoveEmployee(false);
        openNotificationWithIcon("success", "Remove employee success");
        history.push("/employee");
      })
      .catch((error) => {
        setLoadingRemoveEmployee(false);
        if (error) {
          if (error.response) {
            if (error.response.status === 403) {
            } else {
              openNotificationWithIcon("error", "Remove employee error");
            }
          }
        } else {
          openNotificationWithIcon("error", "Remove employee error");
        }
      });
  }
  return (
    <div>
      <ButtonPrimary
        style={{
          maxWidth: "200px",
          width: "100%",
          margin: "0px auto 20px auto",
          background : "#ff4d4f"
        }}
        text={
          <>
            <p style={{ marginTop: "10px" }}>
              <span style={{ color: "#FFF" }}>Remove employee</span>
            </p>
          </>
        }
        className="button-new-student-by-nile"
        onClick={() => setVisibleRemoveEmployee(true)}
      />

      <Modal
        centered
        closable={false}
        visible={visibleRemoveEmployee}
        onOk={() => setVisibleRemoveEmployee(false)}
        onCancel={() => setVisibleRemoveEmployee(false)}
        footer={null}
        className="modalStyle-ModalSelectATK"
      >
        <br />
        <p className="txt-confirm-create-new-employee">
          <CgDanger size="30" color="red" style={{ marginRight: "10px" }} />
          Confirm Remove employee
        </p>
        <p style={{ color: "gray", marginLeft: "40px" }}>
          This action cannot be undone
        </p>
        <Row>
          <Col xs="12" sm="12" md="6" lg="6" xl="6">
            <br />
            <ButtonPrimary
              className="button2-save-create-new-employee-by-nile"
              text="Confirm"
              style={{ background: "red" }}
              loading={loadingRemoveEmployee}
              onClick={() => removeEmployee(employee.employee_id)}
            />
          </Col>
          <Col xs="12" sm="12" md="6" lg="6" xl="6">
            <br />
            <ButtonPrimary
              className="icon-txt-button2-by-nile"
              text="Cancel"
              onClick={() => setVisibleRemoveEmployee(false)}
            />
          </Col>
        </Row>
      </Modal>
    </div>
  );
}
