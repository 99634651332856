import React from "react";
import { AiFillSetting } from "react-icons/ai/index";

export default function ButtonSetting({ classNameButtonSetting, routePath }) {
  return (
    <div className="box-relative-setting">
      <button
        //   className="button-setting"
        className={classNameButtonSetting}
        style={{ float: "right" }}
        onClick={() => routePath("/setting")}
      >
        <AiFillSetting />
      </button>
    </div>
  );
}
