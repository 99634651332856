import React, { useState, useEffect } from "react";
import BarchartMonth from "./BarchartMonth";
import InputDropdown from "../../components/InputDropdown";
import WholeThaiMap from "./WholeThaiMap";
import ColorBarGraph from "./ColorBarGraph";
import SummayBelowGraph from "./SummayBelowGraph";
import { Row, Col } from "reactstrap";
import Dropdown_Has_Key_Value from "../../components/Dropdown_Has_Key_Value";
import { getDeviceAfterSelectRpcRequest } from "../../redux/actions";
import InputDropdown_Has_Key from "../../components/InputDropdown_Has_Key";
import { useDispatch, useSelector } from "react-redux";

const listMonths = {
  0: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December ",
  ],
  1: ["January", "February", "March"],
  2: ["April", "May", "June"],
  3: ["July", "August", "September"],
  4: ["October", "November", "December "],
};
const listFactory = {
  0: [
    "ทั้งหมด",
    "โรงงานบางนา กม.21",
    "โรงงานลำพูน",
    "โรงงานศรีราชา",
    "โรงงานหาดใหญ่",
    "โรงงานโคกกรวด",
    "โรงงานพิษณุโลก",
    "โรงงานธารเกษม",
    "โรงงานราชบุรี",
    "โรงงานหนองแค",
    "โรงงานท่าเรือ",
    "โรงงานปักธงชัย",
    "โรงงานขอนแก่น",
  ],
  1: [
    "ทั้งหมด",
    "โรงงานท่าเรือ",
    "โรงงานหนองแค",
    "โรงงานราชบุรี",
    "โรงงานธารเกษม",
    "โรงงานบางนา กม.21",
  ],
  2: ["ทั้งหมด", "โรงงานลำพูน", "โรงงานพิษณุโลก"],
  3: ["ทั้งหมด", "โรงงานขอนแก่น", "โรงงานปักธงชัย", "โรงงานโคกกรวด"],
  4: ["ทั้งหมด", "โรงงานหาดใหญ่"],
  5: ["ทั้งหมด", "โรงงานศรีราชา"],
};
const listUtherm = {
  0: {
    0: [
      "NT0052",
      "NT0053",
      "NT0068",
      "NT0069",
      "NT0068",
      "NT0069",
      "NT0051",
      "NT0057",
      "NT0054",
      "NT0055",
      "NT0062",
      "NT0063",
      "NT0070",
      "NT0071",
      "NT0066",
      "NT0065",
      "NT0075",
      "NT0076",
      "NT0079",
      "NT0050",
      "NT0077",
      "NT0078",
      "NT0072",
      "NT0073",
      "NT0060",
      "NT0061",
    ],
    1: ["NT0052", "NT0053"], //โรงงานบางนา
    2: ["NT0068", "NT0069"], //โรงงานลำพูน
    3: ["NT0051", "NT0057"], //โรงงานศรีราชา
    4: ["NT0054", "NT0055"], //โรงงานหาดใหญ่
    5: ["NT0062", "NT0063"], //KRF โรงงานโคกกรวด
    6: ["NT0070", "NT0071"], //โรงงานพิษณุโลก
    7: ["NT0066", "NT0065"], //โรงงานธารเกษม
    8: ["NT0075", "NT0076"], //RBF โรงงานราชบุรี
    9: ["NT0079", "NT0050"], // โรงงานหนองแค
    10: ["NT0077", "NT0078"], //TRF โรงงานท่าเรือ
    11: ["NT0072", "NT0073"], // โรงงานปักธงชัย
    12: ["NT0060", "NT0061"], // โรงงานขอนแก่น
  },
  1: {
    0: [
      "NT0077",
      "NT0078",
      "NT0077",
      "NT0078",
      "NT0079",
      "NT0050",
      "NT0075",
      "NT0076",
      "NT0066",
      "NT0065",
      "NT0052",
      "NT0053",
    ],
    1: ["NT0077", "NT0078"],
    2: ["NT0079", "NT0050"],
    3: ["NT0075", "NT0076"],
    4: ["NT0066", "NT0065"],
    5: ["NT0052", "NT0053"],
  },
  2: {
    0: ["NT0068", "NT0069", "NT0070", "NT0071"],
    1: ["NT0068", "NT0069"],
    2: ["NT0070", "NT0071"],
  },
  3: {
    0: ["NT0060", "NT0061", "NT0072", "NT0073", "NT0062", "NT0063"],
    1: ["NT0060", "NT0061"],
    2: ["NT0072", "NT0073"],
    3: ["NT0062", "NT0063"],
  },
  4: {
    0: ["NT0054", "NT0055"],
    1: ["NT0054", "NT0055"],
  },
  5: {
    0: ["NT0051", "NT0057"],
    1: ["NT0051", "NT0057"],
  },
};

export default function GraphRegion({ rpc }) {
  const [region, setRegion] = useState(0);
  const [factory, setFactory] = useState(listFactory[0]);
  const [monthMap, setMonthMap] = useState(listMonths[0]);
  const [uthermRegion, setUthermRegion] = useState(listUtherm[0]);
  const [uTherm, setUTherm] = useState(listUtherm[0][0]);
  const [isTotalFactory, setIsTotalFactory] = useState(0);
  const { faker } = require("@faker-js/faker");
  const [rpcName, setRpcName] = useState("ทั้งหมด");
  
  const dispatch = useDispatch();
  const DeviceAfterSelectRpc = useSelector(
    (state) => state.DeviceAfterSelectRpc
  );
  const device = useSelector((state) => state.device);

  useEffect(() => {
    setFactory(listFactory[region]);
    setUTherm(uthermRegion[0]);
    setIsTotalFactory(0);
    return () => {};
  }, [region]);

  useEffect(() => {
    dispatch(getDeviceAfterSelectRpcRequest("isDefault"));

    return () => {};
  }, []);

  return (
    <div className="box-graph-total">
      <div className="mt-2"></div>
      <Row>
        <Col xs={0}></Col>
        <Col xs={12} xl={4} lg={4} md={5} sm={6}>
          <p className="txt-sub-explanation1"> ภาค </p>
          <Dropdown_Has_Key_Value
            defaultKey="isDefault"
            defaultName="ทั้งหมด"
            items={rpc.rpc}
            id_Dropdown="rpc_id"
            name_Dropdown="rpc_name"
            itemCallback={(item, key) => {
              dispatch(getDeviceAfterSelectRpcRequest(item));
              setRpcName(key);
            }}
          />
        </Col>
        <Col xs={0}></Col>
      </Row>

      {DeviceAfterSelectRpc.device_after_select_rpc.length !== 0 && (
        <>
          <Row className="justify-content-center">
            <Col sm={3} md={3} lg={3} xl={2}>
              <InputDropdown
                title={"ไตรมาส"}
                item={["Total", "1", "2", "3", "4"]}
                isButtom={false}
                itemCallback={(item, key) => {
                  setMonthMap(listMonths[key]);
                }}
              />
            </Col>
            <Col sm={3} md={3} lg={3} xl={2}>
              <InputDropdown
                title={"ปี"}
                item={["2563", "2564", "2565", "2566"]}
                isButtom={false}
                itemCallback={(item) => {}}
              />
            </Col>
            {DeviceAfterSelectRpc.selectAllDevice === false && (
              <Col sm={5} md={4} lg={4} xl={3}>
                <InputDropdown_Has_Key
                  title="โรงงาน"
                  id_Dropdown="device_id"
                  name_Dropdown="device_name"
                  item={DeviceAfterSelectRpc.device_after_select_rpc}
                  isButtom={false}
                  itemCallback={(item, key) => {
                    console.log("โรงงาน");
                    console.log(item);
                    console.log(key);
                  }}
                />
              </Col>
            )}
          </Row>

          <div className="mt-2" />

          <Row>
            <Col md={6} lg={6} xl={6}>
              <WholeThaiMap region={region} />
            </Col>

            <Col md={6} lg={6} xl={6}>

              <Row xl={6} lg={5} md={4} sm={6} xs={5}>
                {uTherm.map((text, index) => (
                  <ColorBarGraph key={index} index={index} text={text} />
                ))}
              </Row>

              <Row xs={2} sm={3} md={2} lg={2} xl={3}>
                {monthMap.map((month, index) => {
                  var line = uTherm.map((_, index) =>
                    faker.datatype.number({ min: 0, max: 20 })
                  );
                  var bar = uTherm.map((_, index) =>
                    faker.datatype.number({ min: 20, max: 100 })
                  );
                  return (
                    <Col key={index}>
                      <BarchartMonth
                        month={month}
                        lineData={line}
                        barData={bar}
                        labels={uTherm}
                        isTotal={isTotalFactory === 0 && uTherm.length > 2}
                      />
                    </Col>
                  );
                })}
              </Row>
              
            </Col>

          </Row>

          <div className="mt-2" />
          <Row>
            <Col>
              <Row className="justify-content-center">
                <Col xl={8} lg={9}>
                  <div className="box-summary-below-graph">
                    <Row xl={5} lg={5} md={3} sm={3} xs={2}>
                      <SummayBelowGraph values={"1,234,434 คน"} type={1} />
                      <SummayBelowGraph values={"25 คน"} type={2} />
                      <SummayBelowGraph values={"35.9C"} type={3} />
                      <SummayBelowGraph values={"25.0C"} type={4} />
                      <SummayBelowGraph values={"10 % RH"} type={5} />
                    </Row>
                  </div>
                  <br />
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}

      {DeviceAfterSelectRpc.device_after_select_rpc.length === 0 && (
        <div>
          <br />
          <p
            className="txt-sub-explanation1"
            style={{ textAlign: "center", fontSize: "25px" }}
          >
            {rpcName} not device
          </p>
          <br />
        </div>
      )}
    </div>
  );
}
