import React from "react";

export default function ContentOfConsent() {
  const styleParagraphSpacing = { textIndent: "50px" };
  const styleHeader = { fontWeight: "bold", textIndent: "50px" };
  return (
    <div className="div-content-consent-modal" style={{ overflow: "auto" }}>
      <p style={styleParagraphSpacing}>
        บริษัท นก ซอฟต์ จำกัด ต่อจากนี้ เรียกว่า “บริษัทฯ”
        ตระหนักถึงความสำคัญและหน้าที่ภายใต้ พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
        พ.ศ.2562 โดยให้ความสำคัญในการเคารพสิทธิความเป็นส่วนตัว
        และมุ่งมั่นเป็นอย่างยิ่งที่จะคุ้มครองข้อมูลส่วนบุคคลของทุกท่านให้มีความปลอดภัย
        นโยบายคุ้มครองข้อมูลส่วนบุคคลฉบับนี้ ให้ใช้กับข้อมูลส่วนบุคคลของลูกค้า
        หรือผู้รับบริการต่างๆ ของบริษัทฯ เพื่อแจ้งและอธิบายให้ทราบว่า บริษัทฯ
        เก็บรวบรวมข้อมูลส่วนบุคคลของท่านอย่างไร ข้อมูลใดบ้างที่บริษัทฯ จัดเก็บ
        มีวัตถุประสงค์ใดในการประมวลผลข้อมูล มีการเปิดเผยข้อมูลของท่านอย่างไร
        มีมาตรการในการรักษาปกป้องข้อมูลของท่านอย่างไร
        รวมถึงสิทธิของท่านในฐานะเจ้าของข้อมูลส่วนบุคคล
        โดยมีรายละเอียดดังต่อไปนี้
      </p>
      {/* ข้อ 1 */}
      <p style={styleHeader}>1. นโยบายนี้มีผลบังคับใช้กับใครบ้าง</p>
      <p style={styleParagraphSpacing}>
        นโยบายนี้ครอบคลุมถึงข้อมูลส่วนบุคคลของลูกค้า ผู้รับบริการต่างๆ
        หรือคู่ค้า ของบริษัทฯ เช่น ผู้สมัครใช้บริการ ลูกค้าปัจจุบัน
        คู่ค้าปัจจุบัน รวมถึงบุคคลใดๆ
        ที่มีความเกี่ยวข้องกับการให้บริการของบริษัทฯ ตัวอย่างเช่น บุคคลอ้างอิง
        หรือบุคคลติดต่อกรณีฉุกเฉิน เป็นต้น
      </p>
      {/* ข้อ 2 */}
      <p style={styleHeader}>2. ข้อมูลส่วนบุคคล หมายถึง</p>
      <p style={styleParagraphSpacing}>
        2.1 ข้อมูลส่วนบุคคล (Personal Data) หมายถึง
        ข้อมูลเกี่ยวกับบุคคลซึ่งทำให้สามารถระบุตัวบุคคลนั้นได้
        ไม่ว่าทางตรงหรือทางอ้อม แต่ไม่รวมถึงข้อมูลของผู้ถืงแก่กรรม ตัวอย่างเช่น
        ชื่อ นามสกุล หมายเลขโทรศัพท์ ที่อยู่ อีเมล หมายเลขบัตรประจำตัวประชาชน
        เป็นต้น
      </p>
      <p style={styleParagraphSpacing}>
        2.2 ข้อมูลส่วนบุคคลที่มีความอ่อนไหว (Sensitive Personal Data) หมายถึง
        ข้อมูลส่วนบุคคลที่กฎหมายกำหนดเป็นการเฉพาะ เช่น เชื้อชาติ
        ความคิดเห็นทางการเมือง ความเชื่อในลัทธิ ศาสนา หรือปรัชญา พฤติกรรมทางเพศ
        ประวัติอาชญากรรม ข้อมูลสุขภาพ ความพิการ ข้อมูลสหภาพแรงงาน
        ข้อมูลพันธุกรรม ข้อมูลชีวภาพ (Biometric)
        หรือข้อมูลอื่นใดในทำนองเดียวกันที่กฎหมายกำหนด ซึ่งบริษัทฯ
        ต้องดำเนินการด้วยความระมัดระวังเป็นพิเศษ โดยบริษัทฯ จะเก็บรวบรวม ใช้
        และ/หรือเปิดเผยข้อมูลส่วนบุคคลที่มีความอ่อนไหว
        ต่อเมื่อได้รับความยินยอมโดยชัดแจ้งจากท่าน หรือในกรณีที่บริษัทฯ
        มีความจำเป็นต้องดำเนินการตามที่กฎหมายอนุญาต
      </p>
      <p style={styleParagraphSpacing}>
        กรณีบริษัทฯ ได้รับสำเนาบัตรประจำตัวประชาชนของลูกค้า หรือบริษัทฯ
        นำข้อมูลของลูกค้าออกจากบัตรประจำตัวประชาชนด้วยวิธีการทางอิเล็กทรอนิกส์
        เพื่อวัตถุประสงค์ในการพิสูจน์ตัวตนของลูกค้าที่สมัครใช้บริการ
        และ/หรือทำธุรกรรมใดๆ กับบริษัทฯ ข้อมูลที่ได้รับจะมีข้อมูลศาสนาด้วย
        ซึ่งถือว่าเป็นข้อมูลส่วนบุคคลที่มีความอ่อนไหว (Sensitive Personal Data)
        บริษัทฯ ไม่มีนโยบายจัดเก็บข้อมูลส่วนบุคคลที่มีความอ่อนไหวจากลูกค้า
        ยกเว้นในกรณีที่บริษัทฯ ได้รับความยินยอมจากลูกค้า ทั้งนี้บริษัทฯ
        จะกำหนดวิธีการจัดการตามแนวทางปฏิบัติ และเป็นไปตามที่กฎหมายอนุญาต
      </p>
      {/* ข้อ 3 */}
      <p style={styleHeader}> 3. ข้อมูลส่วนบุคคลที่บริษัทฯ เก็บรวบรวม</p>
      <p style={styleParagraphSpacing}>
        3.1 รายละเอียดส่วนบุคคลของท่าน หรือของบุคคลอื่น ที่ท่านให้ไว้แก่บริษัทฯ
        หรือที่บริษัทฯ ได้รับอนุญาตให้เข้าถึงโดยท่าน
      </p>
      <p style={styleParagraphSpacing}>
        3.2 ข้อมูลความน่าเชื่อถือ ซึ่งรวมถึงแต่ไม่จำกัดเพียงสถานะความน่าเชื่อถือ
        (Credit standing) และสถาบันทางการเงิน จากบริษัทข้อมูลเครดิตแห่งชาติ
        ผู้ให้บริการประเมินความน่าเชื่อถือ ซึ่งใช้เพื่อการประเมินความน่าเชื่อถือ
        การบริหารความเสี่ยง และการทำแบบจำลองด้านเครดิต (Credit modelling)
      </p>
      {/* ข้อ 4 */}
      <p style={styleHeader}>4. แหล่งที่มาของข้อมูลส่วนบุคคล</p>
      <p style={styleParagraphSpacing}>
        4.1 บริษัทฯ จะรวบรวมข้อมูลที่ท่านได้ให้ไว้ขณะสมัครใช้บริการ
        หรือทำธุรกรรมออนไลน์ ไม่ว่าช่องทางใดๆ ก็ตาม และข้อมูลการร่วมกิจกรรมต่างๆ
        บนสื่อออนไลน์ของบริษัทฯ หรือข้อมูลที่ได้จากการที่ท่านติดต่อกับบริษัทฯ
        หรือทีมงานของบริษัทฯ
      </p>
      <p style={styleParagraphSpacing}>
        4.2 เมื่อท่านให้ข้อมูลส่วนบุคคลของบุคคลอื่นแก่บริษัทฯ
        ท่านขอยืนยันว่าท่านได้แจ้ง และได้รับอนุญาตจากเจ้าของข้อมูล
        เพื่อการเก็บรวบรวม การใช้ และการเปิดเผยข้อมูลส่วนบุคคลดังกล่าว
        ซึ่งจะถูกใช้ตามวัตถุประสงค์ของบริษัทฯ ต่อไป
      </p>
      {/* ข้อ 5 */}
      <p style={styleHeader}>
        {" "}
        5. วัตถุประสงค์ในการเก็บรวบรวม ใช้ เปิดเผยข้อมูลส่วนบุคคล
      </p>
      <p style={styleParagraphSpacing}>
        บริษัทฯ ดำเนินการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน
        โดยมีวัตถุประสงค์ภายใต้ฐานในการประมวลผลข้อมูล ดังต่อไปนี้
      </p>
      <p style={styleParagraphSpacing}>
        5.1 สร้าง ปรับปรุง จัดการ และส่งมอบผลิตภัณฑ์และบริการ ผลประโยชน์
        และสิทธิพิเศษต่างๆ
      </p>
      <p style={styleParagraphSpacing}>
        5.2 ให้การบริการของบริษัทฯ แก่ท่าน
        รวมถึงแต่ไม่จำกัดเพียงการประมวลผลธุรกรรมใดๆ ของท่าน
      </p>
      <p style={styleParagraphSpacing}>
        5.3 ดำเนินการตามคำขอ ท่านเป็นผู้ยื่นคำร้องขอแก่บริษัทฯ เอง
      </p>
      <p style={styleParagraphSpacing}>
        5.4 นำส่งจดหมาย หรือเอกสารที่เกี่ยวข้อง
        ซึ่งหมายรวมถึงการนำส่งในรูปแบบสื่อทางอิเล็กทรอนิกส์ (SMS, Email)
      </p>
      <p style={styleParagraphSpacing}>
        5.5 ปฏิบัติตามกฎหมาย และข้อบังคับของหน่วยงานที่กำกับดูแลบริษัทฯ
      </p>
      <p style={styleParagraphSpacing}>
        5.6 ปกป้องผลประโยชน์โดยชอบของบริษัทฯ
        ซึ่งรวมถึงแต่ไม่จำกัดเพียงการใช้สิทธิเรียกร้องตามกฎหมาย
        การรักษาความปลอดภัยของสถานที่ และทรัพย์สิน การวิเคราะห์ข้อมูล
        การรับประกันคุณภาพของบริการ การตรวจสอบของผู้ตรวจสอบทั้งภายในและภายนอก
        การรักษาความปลอดภัยของเทคโนโลยีสารสนเทศและเครือข่าย
        และการบริหารสินเชื่อและความเสี่ยง
      </p>
      <p style={styleParagraphSpacing}>
        5.7 การจัดการข้อร้องเรียนที่ท่านมีต่อบริษัทฯ
      </p>
      <p style={styleParagraphSpacing}>
        5.8 ประโยชน์ในการจัดทำฐานข้อมูล และใช้ข้อมูลเพื่อเสนอสิทธิประโยชน์ต่างๆ
        หรือเพื่อประโยชน์ในการวิเคราะห์ และนำเสนอบริการ หรือผลิตภัณฑ์ใดๆ
        ของบริษัทฯ หรือเพื่อนำมาพัฒนา ปรับปรุง ด้านการตลาด ผลิตภัณฑ์
        และการให้บริการเพื่อตอบสนองต่อความต้องการของลูกค้า
      </p>
      <p style={styleParagraphSpacing}>
        5.9 เพื่อการจำเป็นเพื่อประโยชน์โดยชอบด้วยกฎหมายของบริษัทฯ หรือของบุคคล
        หรือนิติบุคคลอื่นที่ไม่ใช่บริษัทฯ ซึ่งจะไม่ละเมิดสิทธิพื้นฐาน
        หรือสิทธิทางเสรีภาพของท่าน
      </p>
      <p style={styleParagraphSpacing}>
        บริษัทฯ จะขอความยินยอมจากท่าน กรณีที่มีกฎหมายกำหนดให้ขอความยินยอม
        หรือบริษัทฯ
        ไม่มีเหตุผลให้ใช้ฐานการประมวลผลข้างต้นเพื่อประมวลผลข้อมูลส่วนบุคคลที่เก็บรวบรวมมาจากท่านได้
      </p>
      {/* ข้อ 6 */}
      <p style={styleHeader}>6. การเปิดเผยข้อมูลส่วนบุคคลของท่าน</p>
      <p style={styleParagraphSpacing}>
        บริษัทฯ จำเป็นต้องเปิดเผยข้อมูลส่วนบุคคลของท่านให้แก่บุคคลที่สาม
        ไม่ว่าจะมีสภาพเป็นนิติบุคคล หรือบุคคลธรรมดา
        ภายใต้ขอบเขตที่จำเป็นตามวัตถุประสงค์
        หรือการให้บริการอันเกี่ยวกับผลิตภัณฑ์เท่านั้น โดยบริษัทฯ
        อาจเปิดเผยข้อมูลแก่
      </p>
      <p style={styleParagraphSpacing}>
        6.1 คู่ค้าทางธุรกิจ ซึ่งอาจมีสถานะเป็นนิติบุคคล หรือบุคคลธรรมดา
        เพื่อดำเนินการให้เป็นไปตามการให้บริการแก่ท่าน
      </p>
      <p style={styleParagraphSpacing}>
        6.2 ผู้ให้บริการ หมายถึง นิติบุคคล หรือบุคคลธรรมดา ที่บริษัทฯ
        พิจารณาแล้วว่าเป็นผู้ที่มีคุณสมบัติที่เหมาะสมในการให้บริการแก่ท่าน
        ซึ่งผู้ให้บริการที่บริษัทฯ มีการใช้บริการอยู่ เช่น
        ผู้ประกอบธุรกิจติดตามทวงถามหนี้ ผู้ให้บริการจัดเก็บเอกสาร
        ผู้ให้บริการจัดพิมพ์เอกสาร ผู้ให้บริการจัดส่งเอกสาร เป็นต้น
      </p>
      <p style={styleParagraphSpacing}>
        6.3 หน่วยงาน หรือ บุคคลอื่นใด ที่เป็นไปตามที่กฎหมายกำหนด เช่น สถานีตำรวจ
        ศาล และหน่วยงานอื่นๆ ที่บริษัทฯ มีหน้าที่ต้องนำส่งข้อมูลตามกฎหมาย
      </p>
      {/* ข้อ 7 */}
      <p style={styleHeader}>
        {" "}
        7. การส่งหรือโอนข้อมูลส่วนบุคคลไปยังต่างประเทศ{" "}
      </p>
      <p style={styleParagraphSpacing}>
        บริษัทฯ
        อาจมีความจำเป็นต้องส่งหรือโอนข้อมูลส่วนบุคคลของท่านไปยังบริษัทในเครือเดียวกันที่อยู่ต่างประเทศ
        ส่งหรือโอนข้อมูลไปยังผู้รับข้อมูลอื่น เช่น
        การส่งหรือโอนข้อมูลส่วนบุคคลเพื่อจัดเก็บไว้บน Server หรือ Cloud
        ในต่างประเทศ ซึ่งเป็นส่วนหนึ่งของการดำเนินธุรกิจของบริษัทฯ โดยบริษัทฯ
        จะพิจารณาให้มั่นใจได้ว่าประเทศปลายทางนั้นมีมาตรฐานการคุ้มครองข้อมูลส่วนบุคคลที่เพียงพอตามที่กฎหมายกำหนด
      </p>
      {/* ข้อ 8 */}
      <p style={styleHeader}>
        8. การเก็บรักษาและระยะเวลาในการเก็บรักษาข้อมูลส่วนบุคคลของท่าน
      </p>
      <p style={styleParagraphSpacing}>
        บริษัทฯ
        จะจัดเก็บข้อมูลส่วนบุคคลของท่านไว้ตราบเท่าที่จำเป็นเพื่อการใช้ตามวัตถุประสงค์ที่บริษัทฯ
        และเพื่อการปฏิบัติตามข้อกำหนดทางกฎหมาย และการกำกับดูแล ที่ให้บริษัทฯ
        จัดเก็บข้อมูลของท่านไว้เป็นเวลา 10 ปี
        นับแต่วันที่ท่านสิ้นสุดสัญญากับบริษัทฯ เว้นแต่บริษัทฯ
        ใช้สิทธิเรียกร้องตามกฎหมาย หรืออยู่ระหว่างกระบวนการตามกฎหมาย
        จนกว่าคดีจะถึงที่สุด ทั้งนี้หากบริษัทฯ
        ไม่จำเป็นต้องใช้ข้อมูลส่วนบุคคลอีกต่อไป บริษัทฯ จะลบข้อมูลนั้นออกจากระบบ
        และ/หรือดำเนินการให้ข้อมูลนั้นกลายเป็นข้อมูลที่ไม่สามารถบ่งชี้ตัวบุคคล
        เพื่อที่ท่านจะไม่สามารถถูกบ่งชี้ตัวตนจากข้อมูลนั้นได้อีกต่อไป
        ทั้งนี้ภายใต้ภาระหน้าที่การดำรงข้อมูลไว้ตามกฎหมายที่เกี่ยวข้อง
      </p>
      {/* ข้อ 9 */}
      <p style={styleHeader}>
        9. บริษัทฯ คุ้มครองข้อมูลส่วนบุคคลของท่านอย่างไร
      </p>
      <p style={styleParagraphSpacing}>
        บริษัทฯ
        จะเก็บรักษาข้อมูลส่วนบุคคลของท่านไว้เป็นอย่างดีตามมาตรการเชิงเทคนิค
        (Technical Measure) และมาตรการเชิงบริหารจัดการ (Organizational Measure)
        เพื่อรักษาความปลอดภัยในการประมวลผลข้อมูลส่วนบุคคลที่เหมาะสม
        และเพื่อป้องกันการละเมิดข้อมูลส่วนบุคคล โดยบริษัทฯ ได้กำหนดนโยบาย
        ระเบียบ และหลักเกณฑ์ในการคุ้มครองข้อมูลส่วนบุคคล
        รวมถึงมาตรการเพื่อป้องกันไม่ให้ผู้รับข้อมูลไปจากบริษัทฯ ใช้
        หรือเปิดเผยข้อมูลนอกวัตถุประสงค์ หรือโดยไม่มีอำนาจหรือโดยมิชอบ
        และบริษัทฯ ได้มีการปรับปรุงนโยบาย
        ระเบียบและหลักเกณฑ์ดังกล่าวเป็นระยะตามความจำเป็น และเหมาะสม
        นอกจากนี้ผู้บริหาร พนักงาน ผู้รับจ้าง ตัวแทน ที่ปรึกษา
        และผู้รับข้อมูลจากบริษัทฯ
        มีหน้าที่ต้องรักษาความลับของข้อมูลส่วนบุคคลตามมาตรการรักษาความลับที่บริษัทฯ
        กำหนด
      </p>
      {/* ข้อ 10 */}
      <p style={styleHeader}>10. สิทธิของเจ้าของข้อมูล</p>
      <p style={styleParagraphSpacing}>
        สิทธิในส่วนนี้หมายถึงสิทธิตามกฎหมายที่เกี่ยวกับข้อมูลส่วนบุคคลของท่าน
        ซึ่งท่านอาจขอใช้สิทธิเหล่านี้กับบริษัทฯ
        ภายใต้เงื่อนไขที่กฎหมายกำหนดและกระบวนการจัดการสิทธิของบริษัทฯ
      </p>
      <p style={styleParagraphSpacing}>10.1 สิทธิในการเพิกถอนความยินยอม</p>
      <p style={styleParagraphSpacing}>
        หากท่านได้ให้ความยินยอมให้บริษัทฯ เก็บรวบรวม ใช้
        และเปิดเผยข้อมูลส่วนบุคคลของท่าน
        ท่านมีสิทธิในการเพิกถอนความยินยอมได้ตลอดระยะเวลาที่ข้อมูลส่วนบุคคลของท่านอยู่กับบริษัทฯ
        เว้นแต่มีข้อจำกัดสิทธินั้นโดยกฎหมายหรือมีสัญญาที่ให้ประโยชน์แก่ท่านอยู่
        ทั้งนี้ การถอนความยินยอมของท่านอาจส่งผลกระทบต่อท่านจากการใช้ผลิตภัณฑ์
        และ/หรือบริการต่างๆ เช่น ท่านจะไม่ได้รับสิทธิประโยชน์
        โปรโมชั่นหรือข้อเสนอใหม่ๆ
        ไม่ได้รับผลิตภัณฑ์หรือบริการที่ดียิ่งขึ้นและสอดคล้องกับความต้องการของท่าน
        หรือไม่ได้รับข้อมูลข่าวสารอันเป็นประโยชน์แก่ท่าน เป็นต้น
        เพื่อประโยชน์ของท่านจึงควรศึกษา และสอบถามถึงผลกระทบก่อนเพิกถอนความยินยอม
      </p>
      <p style={styleParagraphSpacing}>10.2 สิทธิในการเข้าถึงข้อมูลส่วนบุคคล</p>
      <p style={styleParagraphSpacing}>
        ท่านมีสิทธิในการเข้าถึงข้อมูลส่วนบุคคลของท่าน และขอให้บริษัทฯ
        ทำสำเนาข้อมูลส่วนบุคคลดังกล่าวให้กับท่าน รวมถึงขอให้บริษัทฯ
        เปิดเผยการได้มาซึ่งข้อมูลส่วนบุคคลที่ท่านไม่ได้ให้ความยินยอมไว้กับบริษัทฯ
      </p>
      <p style={styleParagraphSpacing}>
        10.3 สิทธิในการให้โอนย้ายข้อมูลส่วนบุคคล
      </p>
      <p style={styleParagraphSpacing}>
        ท่านมีสิทธิขอรับข้อมูลส่วนบุคคลของท่านในกรณีที่บริษัทฯ
        ได้ทำให้ข้อมูลนั้นอยู่ในรูปแบบที่สามารถอ่านหรือใช้งานโดยทั่วไปได้ด้วยเครื่องมือหรืออุปกรณ์ที่ทำงานได้โดยอัตโนมัติ
        และสามารถใช้หรือเปิดเผยข้อมูลส่วนบุคคลได้ด้วยวิธีการอัตโนมัติ
        รวมทั้งมีสิทธิขอให้บริษัทฯ
        ส่งหรือโอนข้อมูลส่วนบุคคลในรูปแบบดังกล่าวไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่นที่สามารถทำได้ด้วยวิธีการอัตโนมัติ
        และมีสิทธิขอรับข้อมูลส่วนบุคคลที่บริษัทฯ
        ส่งหรือโอนข้อมูลส่วนบุคคลในรูปแบบดังกล่าวไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่นโดยตรง
        เว้นแต่ไม่สามารถดำเนินการได้เพราะเหตุทางเทคนิค
      </p>
      <p style={styleParagraphSpacing}>
        10.4 สิทธิในการคัดค้านการประมวลผลข้อมูล
      </p>
      <p style={styleParagraphSpacing}>
        ท่านมีสิทธิในการคัดค้านการประมวลผลข้อมูลส่วนบุคคลของท่าน
        หากการเก็บรวบรวม ใช้
        และเปิดเผยข้อมูลส่วนบุคคลของท่านนั้นทำขึ้นเพื่อประโยชน์โดยชอบด้วยกฎหมายของบริษัทฯ
        โดยไม่เกินขอบเขตที่ท่านสามารถคาดหมายได้อย่างสมเหตุสมผล
        เว้นแต่เป็นกรณีที่บริษัทฯ
        สามารถแสดงเหตุผลตามกฎหมายได้ว่ามีความสำคัญยิ่งกว่าสิทธิขั้นพื้นฐานของท่าน
        หรือเป็นไปเพื่อการยืนยันการปฏิบัติตามกฎหมาย
        หรือการต่อสู้ในการฟ้องร้องตามกฎหมายตามแต่กรณี
        นอกจากนี้ท่านยังมีสิทธิขอคัดค้านการเก็บรวบรวม ใช้
        และ/หรือเปิดเผยข้อมูลส่วนบุคคลของท่านที่ทำขึ้นเพื่อวัตถุประสงค์เกี่ยวกับการตลาด
        หรือเพื่อวัตถุประสงค์เกี่ยวกับการศึกษาวิจัยทางวิทยาศาสตร์ ประวัติศาสตร์
        หรือสถิติได้อีกด้วย
      </p>
      <p style={styleParagraphSpacing}>
        10.5 สิทธิในการขอให้ลบหรือทำลายข้อมูลส่วนบุคคล
      </p>
      <p style={styleParagraphSpacing}>
        ท่านมีสิทธิในการขอให้บริษัทฯ ลบหรือทำลายข้อมูลส่วนบุคคลของท่าน
        หรือทำให้เป็นข้อมูลที่ไม่สามารถระบุตัวตนได้
        หากท่านเชื่อว่าข้อมูลส่วนบุคคลของท่านถูกเก็บรวบรวม ใช้ และเปิดเผย
        โดยไม่ชอบด้วยกฎหมาย หรือเห็นว่าบริษัทฯ
        หมดความจำเป็นในการเก็บรักษาไว้ตามวัตถุประสงค์ที่เกี่ยวข้องในนโยบายฉบับนี้
        หรือเมื่อท่านได้ใช้สิทธิขอเพิกถอนความยินยอมหรือใช้สิทธิขอคัดค้านการประมวลผลข้อมูล
      </p>
      <p style={styleParagraphSpacing}>
        10.6 สิทธิในการขอระงับการใช้ข้อมูลส่วนบุคคล
      </p>
      <p style={styleParagraphSpacing}>
        ท่านมีสิทธิในการขอให้บริษัทฯ
        ระงับการใช้ข้อมูลส่วนบุคคลของท่านชั่วคราวในกรณีที่บริษัทฯ
        อยู่ระหว่างการตรวจสอบคำร้องขอใช้สิทธิแก้ไขหรือขอคัดค้านการประมวลผลข้อมูลส่วนบุคคลของท่าน
        หรือกรณีที่บริษัทฯ
        หมดความจำเป็นและต้องลบหรือทำลายข้อมูลส่วนบุคคลของท่านตามกฎหมายที่เกี่ยวข้อง
        แต่ท่านขอให้บริษัทฯ ระงับการใช้แทน
      </p>
      <p style={styleParagraphSpacing}>
        10.7 สิทธิในการแก้ไขข้อมูลส่วนบุคคลให้ถูกต้อง
      </p>
      <p style={styleParagraphSpacing}>
        ท่านมีสิทธิในการขอให้บริษัทฯ แก้ไขข้อมูลส่วนบุคคลของท่านให้ถูกต้อง
        เป็นปัจจุบัน สมบูรณ์ และไม่ก่อให้เกิดความเข้าใจผิด
        โดยท่านสามารถปรับปรุงข้อมูลส่วนบุคคลของท่านตามขั้นตอนของบริษัทฯ
      </p>
      <p style={styleParagraphSpacing}>10.8 สิทธิร้องเรียน</p>
      <p style={styleParagraphSpacing}>
        ท่านมีสิทธิที่จะร้องเรียนต่อผู้มีอำนาจตามกฎหมายที่เกี่ยวข้อง
        หากท่านเชื่อว่าการเก็บรวบรวม ใช้
        และ/หรือเปิดเผยข้อมูลส่วนบุคคลของท่านเป็นการกระทำในลักษณะที่ฝ่าฝืน
        หรือไม่ปฏิบัติตามกฎหมายที่เกี่ยวข้อง
      </p>
      <p style={styleParagraphSpacing}>
        การใช้สิทธิของท่านตามข้างต้นอาจถูกจำกัดภายใต้กฎหมายที่เกี่ยวข้อง
        และมีบางกรณีที่มีเหตุจำเป็นที่บริษัทฯ อาจปฏิเสธ
        หรือไม่สามารถดำเนินการตามคำขอใช้สิทธิของท่านได้ เช่น บริษัทฯ
        จำเป็นต้องปฏิบัติตามกฎหมายหรือคำสั่งศาล หรือการใช้สิทธินั้นๆ
        อาจละเมิดสิทธิหรือเสรีภาพของบุคคลอื่น หรือบริษัทฯ
        จำเป็นต้องใช้สิทธิเรียกร้องตามกฎหมายที่เกี่ยวข้องในการเก็บรักษาข้อมูลดังกล่าว
        โดยบริษัทฯ จะแจ้งเหตุผลของการปฏิเสธให้ท่านทราบ
      </p>
      <p style={styleParagraphSpacing}>
        หากท่านต้องการใช้สิทธิตามข้างต้น ท่านสามารถยื่นคำขอมายังบริษัทฯ
        โดยคลิกที่ แบบคำขอใช้สิทธิของเจ้าของข้อมูลส่วนบุคคล
      </p>
      {/* ข้อ 11 */}
      <p style={styleHeader}>11. กิจกรรมทางการตลาด และการส่งเสริมการตลาด</p>
      <p style={styleParagraphSpacing}>
        บริษัทฯ ใช้ข้อมูลบางอย่างของท่านเพื่อทำการตลาดที่ตรงตามความสนใจของท่าน
        เพื่อที่ท่านจะได้รับแจ้งเกี่ยวกับกิจกรรม ข้อเสนอการส่งเสริมการขาย
        ผลิตภัณฑ์ และบริการที่ท่านอาจมีความสนใจ
        โดยท่านสามารถดำเนินการยกเลิกการรับแจ้งข้อมูลข่าวสารดังกล่าวจากทางบริษัทฯ
        ได้
      </p>
      {/* ข้อ 12 */}
      <p style={styleHeader}>
        {" "}
        12. การเปลี่ยนแปลงนโยบายคุ้มครองข้อมูลส่วนบุคคล{" "}
      </p>
      <p style={styleParagraphSpacing}>
        บริษัทฯ
        จะทำการพิจารณาทบทวนนโยบายคุ้มครองข้อมูลส่วนบุคคลเป็นประจำเพื่อให้สอดคล้องกับแนวทางปฏิบัติ
        และกฎหมาย ข้อบังคับที่เกี่ยวข้อง ทั้งนี้
        หากมีการเปลี่ยนแปลงนโยบายคุ้มครองข้อมูลส่วนบุคคล บริษัทฯ
        จะแจ้งให้ท่านทราบด้วยการประกาศข้อมูลลงในเว็บไซต์ของบริษัทฯ
      </p>
      {/* ข้อ 13 */}
      <p style={styleHeader}>
        13. ช่องทางการติดต่อบริษัทฯ และเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล
      </p>
      <p style={styleParagraphSpacing}>
        ท่านสามารถติดต่อบริษัทฯ เพื่อเสนอแนะ
        หรือสอบถามรายละเอียดเพิ่มเติมเกี่ยวกับนโยบายคุ้มครองข้อมูลส่วนบุคคลได้ที่
        info@noksoft.co.th
      </p>
    </div>
  );
}
