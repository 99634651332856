import React from "react";
import InputWithoutTitle from "../../components/InputWithoutTitle";
import { AiOutlineQuestion } from "react-icons/ai";
import ButtonPrimary from "../../components/ButtonPrimary";
import { Row, Col } from "reactstrap";
import DisplayTextHeaderAndValueInFronSystems from "../../components/DisplayTextHeaderAndValueInFronSystems";

export default function AlreadyInSystemField() {
  return (
    <div>
      <div className="box-already-in-system-field-by-nile">
        <div className="row-jcct-py">
          <p
            style={{
              color: "#134e96",
              fontSize: "1.2rem",
              marginBottom: "10px",
            }}
            className="txt-sub-explanation1"
          >
            From Systems
          </p>
        </div>

        <InputWithoutTitle />

        <div className="box-insystem-field-by-nile">
          <Row>
            <Col xs={12} sm={12} md={6} lg={6} xl={5}>
              <DisplayTextHeaderAndValueInFronSystems
                headerText="First Name"
                valueText="Pataraphon"
              />
              <DisplayTextHeaderAndValueInFronSystems
                headerText="Last Name"
                valueText="Threekan"
              />
              <DisplayTextHeaderAndValueInFronSystems
                headerText="ID"
                valueText="533442"
              />
              <DisplayTextHeaderAndValueInFronSystems
                headerText="Factory"
                valueText="โรงงานโคกกรวด"
              />
              <DisplayTextHeaderAndValueInFronSystems
                headerText="Regoin"
                valueText="ภาคอีสาน"
              />
            </Col>
            <Col>
              <Row xl={2}>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <div
                    className="box-image-train"
                    style={{ display: "block", margin: "auto" }}
                  ></div>
                  <p
                    className="txt-sub-explanation1"
                    style={{ textAlign: "center", marginTop: "10px" }}
                  >
                    Profile Pic
                  </p>
                  <br />
                </Col>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <div
                    className="box-image-train"
                    style={{ display: "block", margin: "auto" }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <AiOutlineQuestion
                        size={"2.6rem"}
                        style={{ marginTop: "40px" }}
                      />
                      <p
                        className="txt-unfocus1"
                        style={{ textAlign: "center" }}
                      >
                        No Train Image
                      </p>
                    </div>
                  </div>
                  <p
                    className="txt-sub-explanation1"
                    style={{ textAlign: "center", marginTop: "10px" }}
                  >
                    Train Pic
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <p
          className="txt-sub-explanation1"
          style={{ marginLeft: "10px", marginTop: "10px" }}
        >
          Use this employee data for this Log data
        </p>
      </div>

      {/* <div className="row-jcct">
        <ButtonPrimary
          style={{ margin: "2rem 0", padding: "0.6rem 10rem" }}
          className={"primary-button"}
          text={"Save"}
        />
      </div> */}
    </div>
  );
}
