import React from "react";
import AlreadyInSystemField from "./AlreadyInSystemField";
import AddNewStudent from "./AddNewStudent";
import {
  serverIcon,
  employeeIcon,
} from "../../assets/icons";
import SelectedField from "../../components/SelectedField";
import CardDetailUserLog from "./CardDetailUserLog";
import { Container } from "reactstrap";
import ButtonPrimary from "../../components/ButtonPrimary";

export default function TrainImageFromActivities() {
  return (
    <Container fluid>
      <CardDetailUserLog />

      <div>
        <div className="row-jcct-py2">
          <p style={{ color: "#134e96" }} className="txt-main-content1">
            Add New Train Image data to Server
          </p>
        </div>

        <SelectedField
          listImage={[
            {
              title: "From Systems",
              image: serverIcon,
              page: <AlreadyInSystemField />,
            },
            {
              title: "New Employee",
              image: employeeIcon,
              page: <AddNewStudent />,
            },
          ]}
        />

        <div style={{ display:'flex' , justifyContent:'center' }}>
        <div className="button-save-TrainImageFromActivities">
          <ButtonPrimary
            style={{ width:'100%' }}
            className={"primary-button"}
            text={"Save"}
          />
        </div>
        </div>
      </div>
    </Container>
  );
}

// import React from "react";
// import { left } from "../../assets/images";
// import AlreadyInSystemField from "./AlreadyInSystemField";
// import AddNewStudent from "./AddNewStudent";
// import {
//   no_image_in_systems,
//   serverIcon,
//   studentIcon,
// } from "../../assets/icons";
// import SelectedField from "../../components/SelectedField";

// export default function TrainImageFromActivities() {
//   return (
//     <div>
//       <div className="box-top-trainimage">
//         <div className="row-gab2">
//           <div className="col-jcct-atct">
//             <img src={left} alt="left" />
//             <p className="txt-sub-explanation1">Image from Log</p>
//           </div>
//           <div className="row-jcst-atct">
//             <div className="col-gab1">
//               <p className="txt-sub-explanation1">First Name</p>
//               <p className="txt-sub-explanation1">Last Name</p>
//               <p className="txt-sub-explanation1">Student ID</p>
//             </div>
//             <div className="col-gab1">
//               <p className="txt-sub-explanation1">?????</p>
//               <p className="txt-sub-explanation1">?????</p>
//               <p className="txt-sub-explanation1">?????</p>
//             </div>
//           </div>
//         </div>
//         <div className="row-jcst-atfs">
//           <div className="col-gab1">
//             <p className="txt-sub-explanation1">Device SN</p>
//             <p className="txt-sub-explanation1">Data/Time</p>
//             <p className="txt-sub-explanation1">Status</p>
//           </div>
//           <div className="col-gab1">
//             <p className="txt-sub-explanation1">?????</p>
//             <p className="txt-sub-explanation1">00.00 Yyyy-mm-dd</p>
//             <img src={no_image_in_systems} className="img-icon-train" />
//           </div>
//         </div>
//       </div>
//       <div>
//         <div className="row-jcct-py2">
//           <p style={{ color: "#134e96" }} className="txt-main-content1">
//             Add New Train Image data to Server
//           </p>
//         </div>
//         <SelectedField
//           listImage={[
//             {
//               title: "From Systems",
//               image: serverIcon,
//               page: <AlreadyInSystemField />,
//             },
//             {
//               title: "New Student",
//               image: studentIcon,
//               page: <AddNewStudent />,
//             },
//           ]}
//         />
//       </div>
//     </div>
//   );
// }
