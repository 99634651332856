import React from "react";
import { Row, Col } from "reactstrap";

export default function RenderTextHeaderAndValue({ textHeader, textValue }) {
  return (
    <Row>
      <Col xs={12} sm={5} md={5} lg={5} xl={5}>
        <p className="text-personal-detail"> {textHeader} </p>
      </Col>
      <Col xs={12} sm={7} md={7} lg={7} xl={7}>
        <p className="text-personal-detail"> 
        <span className="tick-personal-detail">-</span>
        {textValue} </p>
      </Col>
    </Row>
  );
}
