import { combineReducers } from 'redux'

import startReduxPage from './StartReduxTest.reducers'
import employee from './Employee.reducers';
import employeeOneAndFeature from './employeeOneAndFeature.reducers';
import log from './log.reducers';
import device from './Device.reducers';
import rpc from './Rpc.reducers';
import DeviceAfterSelectRpc from './DeviceAfterSelectRpc.reducers';
import qrCodeCut from './CutQRCode.reducers';
import department from './Department.reducers'
import factory from './Factory.reducers'
import logFilter from './logFilter.reducers'
import employeeDeleted from './GetEmployeeDeleted.reducers'
import UserInformation from './UserInformation.reducers'

  const rootReducer = combineReducers({
    startReduxPage,
    employee,
    employeeOneAndFeature,
    log,
    device,
    rpc,
    DeviceAfterSelectRpc,
    qrCodeCut,
    department,
    factory,
    logFilter,
    employeeDeleted,
    UserInformation
  });
  
  export default rootReducer;