import React, { useEffect, useState } from "react";
import { Select } from "antd";
import "antd/dist/antd.css";
import { IoMdArrowDropdown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { getRpcRequest } from "../redux/actions";

const { Option } = Select;

export default function RegionAll({ handleChange }) {
  const dispatch = useDispatch();
  const rpc = useSelector((state) => state.rpc);

  useEffect(() => {
    dispatch(getRpcRequest());

    return () => {};
  }, []);
  
  return (
    <div className="div-DatePicker-style-custom">
      <p className="text-header-date-picker"> Region </p>
      <Select
        onChange={handleChange}
        style={{ width: "100%" }}
        allowClear
        suffixIcon={
          <IoMdArrowDropdown
            style={{ fontSize: "1.7rem", marginLeft: "-20px" }}
          />
        }
      >
        {rpc.rpc.map((data) => {
          return (
            <Option key={data.rpc_id} value={data.rpc_id}>
              {data.rpc_name}
            </Option>
          );
        })}
      </Select>
    </div>
  );
}
