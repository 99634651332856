import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import * as api from "../../Service/Api_Muthermfacesense";
import { logo_remove_bg } from "../../assets/images";
import TextField from "../../components/TextField";
import ButtonPrimary from "../../components/ButtonPrimary";

import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { getDetailUserInformation } from "../../redux/actions";

export default function Login() {
  var history = useHistory();
  const dispatch = useDispatch();

  const [username, setUsername] = useState({
    username: null,
    password: null,
  });
  const [error, setError] = useState({
    error_username: false,
    error_password: false,
  });
  const [login_Error, setLogin_Error] = useState(null);

  const styleError = { color:'red', margin:'10px 0px 0px 10px' , fontWeight:'500' }

  function setUsernamePassword(value, state, errorValue) {
    if (value) {
      setUsername({
        ...username,
        [state]: value,
      });
      setError({
        ...error,
        [errorValue]: false,
      });
    } else {
      setUsername({
        ...username,
        [state]: null,
      });
      setError({
        ...error,
        [errorValue]: true,
      });
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    if(username.username === null || username.password === null){
      setError({
        ...error,
        error_username: username.username ? false : true,
        error_password: username.password ? false : true,
      });
    } else if(username.username !== null && username.password !== null){
      let userLogin = {
        email :  username.username,
        password : username.password
      }
      const login = api.LOGIN(userLogin);
      axios(login)
        .then((res) => {
          localStorage.setItem("token", res.data.message);
          var decoded = jwt_decode(res.data.message);
          dispatch(getDetailUserInformation(decoded));
          if(decoded){
            history.push("/");
          }
        })
        .catch((error) => {
          if(error && error.response){
            setLogin_Error(error.response.statusText)
          } else {
            setLogin_Error("Internet error");
          }
        });
    }
  }

  return (
    <div className="row-jcse-login">
    <div className="col-jcct-aict-login">
      <img className="img-logo-login" src={logo_remove_bg} />
      <p className="txt-logo">Bizzly</p>
    </div>
    <div className="col-jcct-aict-login">
      <p className="txt-header">Login</p>
      <form onSubmit={handleSubmit} style={{width:'80%'}}>
      <TextField
          error={error.error_username ? "Username is required" : null}
          styleError={styleError}
        title={"Username"}
        placeholder={"Username"}
        type="email"
        onChange={e => setUsernamePassword(e.target.value,'username',"error_username") }
      />
      <br />
      <TextField
        error={error.error_password ? "Password is required" : null}
        styleError={styleError}
        title={"Password"}
        placeholder={"Password"}
        type="password"
        onChange={e => setUsernamePassword(e.target.value,'password',"error_password") }
      />
      {/* <div className="space-login-4rem" /> */}
     <br />
     {
       login_Error && (
         <p style={{ color:'red' , textAlign:'center' , fontSize:'20px' }}>{login_Error}</p>
       )
     }
      <ButtonPrimary
      style={{ display:'block', margin:'auto' }}
        className="primary-button2"
        text={"Login"}
      />
      </form>
    </div>
  </div>
  );
}
