import React, { useEffect, useState } from "react";
import { Select } from "antd";
import "antd/dist/antd.css";
import { IoMdArrowDropdown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { getDeviceRequest } from "../redux/actions";

const { Option } = Select;

export default function DeviceAll({handleChange , defaultValue , valueSelect }) {
  const dispatch = useDispatch();
  const device = useSelector((state) => state.device);

  useEffect(() => {
    dispatch(getDeviceRequest());

    return () => {};
  }, []);

  return (
    <div className="div-DatePicker-style-custom">
      <p className="text-header-date-picker"> Device </p>
      <Select
        onChange={handleChange}
        defaultValue={defaultValue ? defaultValue : null}
        style={{ width: "100%" }}
        allowClear
        suffixIcon={
          <IoMdArrowDropdown
            style={{ fontSize: "1.7rem", marginLeft: "-20px" }}
          />
        }
      >
        {device.device.map((data) => {
          return (
            <Option key={data.device_id} value={data[valueSelect]}>
              {data.device_name}
            </Option>
          );
        })}
      </Select>
    </div>
  );
}
