import React from "react";
import ReactTypingEffect from "react-typing-effect";
import {
  image_background_mtfd_device,
  icon_image_background_mtfd_device,
} from "../../../assets/images";

export default function DisplayComponents({ announcement }) {
  
  return (
    <div
      style={{
        border: "3px solid #7aa6dd",
        marginBottom: "20px",
        borderRadius: "7px",
        backgroundColor: "#fefefe"
      }}
    >
      <p className="txt-sub-header1-by-nile">Example</p>
      <span>
        <img
          src={icon_image_background_mtfd_device}
          alt="icon_image_background_mtfd_device"
          style={{ height: "50px" }}
        />
          <ReactTypingEffect
            className="txt-sub-header1-by-nile"
            style={{ textAlign: "left", color: "#FDD30B", fontSize: "30px" }}
            text={announcement}
            speed={100}
            eraseDelay={200}
          />
      </span>
      <img
        src={image_background_mtfd_device}
        alt="image_background_mtfd_device"
        style={{ width: "100%" }}
      />
    </div>
  );
}
