import { useEffect, useState } from "react";
import { left, BizzlyMainPic, user } from "../../assets/images/index";
import { Row, Col } from "reactstrap";
import Dropdown from "../../components/Dropdown";
import SliderSwittch from "./SliderSwittch";
import ButtonPrimary from "../../components/ButtonPrimary";
import { MdArrowBack } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const regionList = ["ภาคกลาง", "ภาคเหนือ", "ภาคอีสาน", "ภาคใต้", "ภาคตะวันออก"];

export default function User() {
  const { UserInformation } = useSelector((state) => state);
  var history = useHistory();
  const [nameUser, setNameUser] = useState({
    firstname: null,
    lastname: null,
  });

  function logout() {
    history.push("/login");
    localStorage.removeItem("token");
  }

  useEffect(() => {
    if (UserInformation && UserInformation.first_name !== null) {
      setNameUser({
        ...nameUser,
        firstname: UserInformation.first_name,
        lastname: UserInformation.last_name,
      });
    }
    return () => {};
  }, [UserInformation]);

  return (
    <div>
      <Row >
        <Col >
          <div className="col-setting justify-content-center">
            <div className="box-img-avatar">
              <img src={user} />
            </div>
            <p
              style={{ marginTop: "20px", fontSize: "1.4rem" , textAlign:'center' }}
              className="txt-sub-header1"
            >
              {nameUser.firstname} {" "} {nameUser.lastname}
            </p>
          </div>
        </Col>
      </Row>

      {/* <Row>
        <div className="row-jcct">
          <Col xs={12} sm={12} md={12} lg={10} xl={10}>
            <div className="box-setting-dashline">
              <Row className="justify-content-center">
                <div className="row-jcct">
                  <p style={{ fontSize: "0.9rem" }} className="txt-higlight1">
                    Setting
                  </p>
                </div>
              </Row>
              <Row>
                <Col>
                  <p
                    style={{ marginLeft: "2rem" }}
                    className="txt-header-setting"
                  >
                    Change Region Color
                  </p>
                </Col>
              </Row>
              <Row className="justify-content-space-between">
                <Col xl={12}>
                  <div
                    className="row-jcsb"
                    style={{ margin: "1rem 2rem", alignItems: "center" }}
                  >
                    <Col xs={6}>
                      <div className="ml-2" style={{ width: "100%" }}>
                        <Dropdown
                          itemCallback={(item, key) => {}}
                          items={regionList}
                        />
                      </div>
                    </Col>
                    <Col xs={6} sm={6} md={5} lg={4} xl={3}>
                      <div className="row-jcct">
                        <div className="box-color-region" />
                      </div>
                    </Col>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div
                    className="row-jcsb"
                    style={{
                      padding: "1rem 2rem",
                      alignItems: "center",
                    }}
                  >
                    <Col>
                      <p className="txt-header-setting">
                        Change Temperature Color
                      </p>
                    </Col>
                    <Col xs={6} sm={6} md={5} lg={4} xl={3}>
                      <div className="row-jcct">
                        <div
                          style={{ backgroundColor: "#8D8D8D" }}
                          className="box-color-region"
                        ></div>
                      </div>
                    </Col>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div
                    className="row-jcsb"
                    style={{
                      padding: "1rem 2rem",
                      alignItems: "center",
                    }}
                  >
                    <Col>
                      <p className="txt-header-setting">Dark Mode</p>
                    </Col>
                    <Col xs={6} sm={6} md={5} lg={4} xl={3}>
                      <div className="row-jcct">
                        <SliderSwittch
                          itemLeft={"Light"}
                          itemRight={"Dark"}
                          leftColor={"#FFFFFF"}
                          RightColor={"#7B7B7B"}
                          itemCallback={(item) => {}}
                        />
                      </div>
                    </Col>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div
                    className="row-jcsb"
                    style={{
                      padding: "1rem 2rem",
                      alignItems: "center",
                    }}
                  >
                    <Col>
                      <p className="txt-header-setting">Language</p>
                    </Col>
                    <Col xs={6} sm={6} md={5} lg={4} xl={3}>
                      <div className="row-jcct">
                        <SliderSwittch
                          itemLeft={"Thai"}
                          itemRight={"Eng"}
                          leftColor={"#134E96"}
                          RightColor={"#134E96"}
                          itemCallback={(item) => {}}
                        />
                      </div>
                    </Col>
                  </div>
                </Col>
              </Row>
              <div className="mt-1" />
            </div>
          </Col>
        </div>
      </Row> */}
      {/* <div className="mt-1" />
      <div className="mt-1" /> */}

      {/* <Row>
        <div className="row-jcct">
          <Col xs={12} sm={12} md={12} lg={10} xl={10}>
            <ButtonPrimary
              text={
                <>
                  <div>
                    <MdArrowBack />
                    {"Back To Bizzly"}
                  </div>
                  <img src={BizzlyMainPic}></img>
                </>
              }
              className={"box-button-back-to-bizzly"}
            />
          </Col>
        </div>
      </Row> */}

      <div className="mt-1" />
      <div className="mt-1" />
      <Row className="justify-content-center">
        <Col xs={8} sm={6} md={6} lg={5} xl={5}>
          <div className="row-jcct">
            <ButtonPrimary
              style={{ width: "150px" }}
              text={"Log out"}
              className={"btn-rebuild-all-rpc-in-system-setting"}
              onClick={() => logout()}
            />
          </div>
        </Col>
      </Row>
      <div className="mt-1" />
      <div className="mt-1" />
    </div>
  );
}
