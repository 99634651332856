const department = [
  { id: 6, name: "BKF" },
  { id: 7, name: "PlantControl" },
  { id: 8, name: "Premixและอาหารทดลอง" },
  { id: 9, name: "จัดซื้อ" },
  { id: 10, name: "บริหารโรงงาน" },
  { id: 11, name: "บุคคล" },
  { id: 12, name: "ผลิต" },
  { id: 13, name: "สำนักวิเคราะห์ข้อมูลและเทคโนโลยีดิจิตอล" },
  { id: 14, name: "คลังวัตถุดิบ" },
  { id: 15, name: "ธุรการ" },
  { id: 16, name: "สำนักหุ่นยนต์" },
  { id: 17, name: "หน้าร้าน" },
  { id: 18, name: "ปฏิบัติการทดสอบ" },
  { id: 19, name: "ควบคุมคุณภาพ" },
  { id: 20, name: "ฝ่ายวิศวกรรม" },
  { id: 21, name: "หน่วยรักษาความปลอดภัย" },
  { id: 22, name: "PNALL" },
  { id: 23, name: "ผู้ติดต่อ" },
  { id: 24, name: "อื่นๆ" },
  { id: 25, name: "ไม่ทราบ" },
  { id: 26, name: "ขายหน้าร้าน" },
  { id: 27, name: "บัญชี" },
  { id: 28, name: "ผู้รับเหมา" },
  { id: 29, name: "ฝ่ายผลิตอาหารสัตว์" },
  { id: 30, name: "TPM" },
  { id: 31, name: "ไดนามิคทรานสปอร์ต" },
  { id: 32, name: "Supplier" },
  { id: 33, name: "ฝ่ายวิชาการอาหารสัตว์" },
  { id: 34, name: "คลังอาหารสำเร็จรูป (อสร.)" },
];

export default department