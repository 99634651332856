import React from "react";
import { Row, Col } from "reactstrap";

export default function DisplayValueBoxCPU({ textHeader, textValue }) {
  return (
    <Row>
      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
      {textHeader}
      </Col>
      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
      {textValue}
      </Col>
    </Row>
  );
}
