import { put, takeEvery, call } from "redux-saga/effects";
import { GET_DETAIL_USER_INFORMATION } from "../actions";

function* handleGetUserInformationOne({ payload }) {
  if (payload) {
    yield put({
      type: GET_DETAIL_USER_INFORMATION,
      factory_id: payload.factory_id,
      first_name: payload.first_name,
      is_root: payload.is_root,
      last_name: payload.last_name,
      public_uuid: payload.public_uuid,
      user_id: payload.user_id,
    });
  }
}

function* userInformationOne() {
  yield takeEvery(GET_DETAIL_USER_INFORMATION, handleGetUserInformationOne);
}

export default userInformationOne;
