import { put, takeEvery, call } from "redux-saga/effects";
import {
  GET_DEVICE_REQUEST,
  GET_DEVICE_SUCCESS,
  GET_DEVICE_FAILED,
} from "../actions";
import * as api from "../../Service/Api_Muthermfacesense";
import axios from "axios";

const token = localStorage.getItem("token");

function deviceFetch() {
    const device = api.GET_DEVICE_ALL(token);
    return axios(device)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  function* handleGetDevice() {
    try {
      const responseDevice = yield call(deviceFetch);
      yield put({
        type: GET_DEVICE_SUCCESS,
        device: responseDevice.result.data,
      });
    } catch (err) {
      yield put({
        type: GET_DEVICE_FAILED,
        error: err.response.data.detail,
        statusCode: err.response.status,
      });
    }
  }
  
  function* device() {
    yield takeEvery(GET_DEVICE_REQUEST, handleGetDevice);
  }
  
  export default device;