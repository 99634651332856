import * as types from "../actions";

const initialState = {
    rpc: [],
    loading: false,
    error: null,
    statusCode: null,
};

const rpc = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_RPC_REQUEST:
      return { ...state, loading: true };
    case types.GET_RPC_SUCCESS:
      return {
        ...state,
        loading: false,
        rpc: action.rpc,
      };
    case types.GET_RPC_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        statusCode: action.statusCode,
      };
    default:
      return state;
  }
};

export default rpc;
