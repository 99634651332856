import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import InputNormal from "../../components/InputNormal";
import SelectFilterClear from "../../components/SelectFilterClear";
import TableLogComponents from "../../components/TableLogComponents";
import { useDispatch, useSelector } from "react-redux";
import DeviceAll from "../../components/DeviceAll";
import RegionAll from "../../components/RegionAll";
import { BiSearch } from "react-icons/bi";
import ButtonPrimary from "../../components/ButtonPrimary";
import { getEmployeeRequest } from "../../redux/actions";
import { Pagination, Tooltip } from "antd";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BsPersonCheck } from "react-icons/bs";

export default function SelectEmployee({
  setAddFeatureEmployee,
  addFeatureEmployee,
  employee,
  searchEmployee,
  page_limit_select_employee,
  setPage_limit_select_employee,
}) {
  const dispatch = useDispatch();
  const { UserInformation } = useSelector((state) => state);
  const [search, setSearch] = useState({
    name: null,
    department_id: null,
    factory_id: null,
  });

  useEffect(() => {
    if (UserInformation) {
      if (UserInformation.is_root === true) {
        const payload = {
          page: 1,
          limit: 10,
        };
        dispatch(getEmployeeRequest(payload));
      } else {
        const payload = {
          page: 1,
          limit: 10,
          factory_id: UserInformation.factory_id,
        };
        dispatch(getEmployeeRequest(payload));
        setSearch({
          ...search,
          factory_id: UserInformation.factory_id,
        });
      }
    }

    return () => {};
  }, [UserInformation]);

  useEffect(() => {
    // const payload = {
    //   page: 1,
    //   limit: 10,
    // };
    // dispatch(getEmployeeRequest(payload));

    return () => {
      const payload = {
        clear: true,
      };
      dispatch(getEmployeeRequest(payload));
    };
  }, []);

  useEffect(() => {
    setPage_limit_select_employee({
      ...page_limit_select_employee,
      limit: employee.totalCount,
    });

    return () => {};
  }, [employee]);

  function searchEmployee() {
    const payload = {
      page: 1,
      limit: page_limit_select_employee.page_Size,
      ...search,
    };
    dispatch(getEmployeeRequest(payload));
    setPage_limit_select_employee({
      ...page_limit_select_employee,
      page: 1,
    });
  }

  const rowTableLogRealtime = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Firstname",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Lastname",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      render: (data) => (
        <Tooltip title={`Select ${data.first_name}`}>
          <div className="cursor-pointer">
            <BsPersonCheck
              color="green"
              size="25"
              onClick={() =>
                setAddFeatureEmployee({
                  ...addFeatureEmployee,
                  employee_id: data.employee_id,
                  first_name: data.first_name,
                  last_name: data.last_name,
                })
              }
            />
          </div>
        </Tooltip>
      ),
    },
  ];

  function changePagination(page, pageSize) {
    const payload = {
      page: page,
      limit: pageSize,
      ...search,
    };
    dispatch(getEmployeeRequest(payload));
    setPage_limit_select_employee({
      ...page_limit_select_employee,
      page: page,
      page_Size: pageSize,
    });
  }

  return (
    <Container fluid>
      <Row>
        <Col xs={8} sm={8} md={8} lg={8} xl={9}>
          <InputNormal
            onChange={(e) =>
              e.target.value
                ? setSearch({
                    ...search,
                    name: e.target.value,
                  })
                : setSearch({
                    ...search,
                    name: null,
                  })
            }
            className="text-header-date-picker"
            title="Search"
            placeholder="Firstname , Lastname"
          />
        </Col>

        <Col xs={4} sm={4} md={4} lg={4} xl={3}>
          <ButtonPrimary
            className="button-new-student-by-nile"
            style={{ width: "100%", marginTop: "63px" }}
            text={
              <>
                <span
                  className="text-in-button-employee"
                  style={{ color: "#134e96" }}
                >
                  Search
                </span>
                <BiSearch
                  size="25px"
                  className="icons-in-button-new-employee"
                  style={{ color: "#134e96" }}
                />
              </>
            }
            onClick={() => searchEmployee()}
          />
        </Col>
      </Row>

      <br />
      {/* <Row>
        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <br />
          <DeviceAll valueSelect="device_id" handleChange={(e) => handleChange(e, "department_id")} />
        </Col>

        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <br />
          <RegionAll handleChange={(e) => handleChange(e, "factory_id")} />
        </Col>
      </Row> */}

      <Row>
        <Col>
          <div className="box-table-by-nile" style={{ height: "280px" }}>
            <TableLogComponents
              columns={rowTableLogRealtime}
              dataSource={employee.employee}
              loading={employee.loading}
            />
            <div style={{ float: "right", marginTop: "10px" }}>
              <Pagination
                current={page_limit_select_employee.page}
                total={page_limit_select_employee.limit}
                onChange={changePagination}
              />
            </div>
            <p
              className="text-header-date-picker"
              style={{ marginTop: "15px" }}
            >
              total data : {page_limit_select_employee.limit}
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
