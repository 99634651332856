import React, { useEffect, useState } from "react";
import ThaiWhole from "../../assets/images/thai-whole";
import EastMap from "../../assets/images/east-map";
import SouthMap from "../../assets/images/south-map";
import NorthEastMap from "../../assets/images/north-east-map";
import NorthMap from "../../assets/images/north-map";
import MidMap from "../../assets/images/mid-map";
import SouthMapPart from "../../assets/images/south-map-part";
import FactoryIcon from "../../assets/images/factory_icon";
import { Row } from "reactstrap";
import EChartMap from "./EChartMap";
import { DataFactory } from "../../assets/data/DataFactory";

const listMap = {
  0: <ThaiWhole />,
  1: <MidMap />,
  2: <NorthMap />,
  3: <NorthEastMap />,
  4: <SouthMapPart />,
  5: <EastMap />,
};
const regionList = [
  { 0: "ทั้งหมด" },
  { 1: "ภาคกลาง" },
  { 2: "ภาคเหนือ" },
  { 3: "ภาคอีสาน" },
  { 4: "ภาคใต้" },
  { 5: "ภาคตะวันออก" },
];

export default function WholeThaiMap({ region }) {
  // console.log(`region init ${region}`);
  // const [regionSet, setRegionSet] = useState(region);
  useEffect(() => {
    // setRegionSet(region);
    // console.log(`region changed ${region}`);
    return () => {};
  }, [region]);

  return (
    <div className="pos-center-div">
      {DataFactory["data"][region].map((item , index) => {
        return (
          <FactoryIcon
            key={index}
            region={region}
            province={item["number_province"]}
            factory={item["factory"]}
          />
        );
      })}
      {listMap[region]}
    </div>
  );
}
