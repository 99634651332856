import React from "react"
import Lottie from "lottie-react-web"
import success from "../../assets/animation/success.json"

export default function index() {
  return (
    <div className="center-animation-success" >
      <Lottie
        height={200}
        options={{
          animationData: success,
        }}
      />
      <p className="text-add-feature-center-animation-success">เทรนรูปภาพสำเร็จ</p>
    </div>
  )
}
