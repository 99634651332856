import * as types from "../actions";

const initialStateEmployee = {
  employee: [],
  loading: false,
  error: null,
  statusCode: null,
  totalCount: 0,
  totalPage: 0,
};

const employee = (state = initialStateEmployee, action) => {
  switch (action.type) {
    case types.GET_EMPLOYEE_REQUEST:
      return { ...state, loading: true };
    case types.GET_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        employee: action.employee,
        totalCount: action.totalCount,
        totalPage: action.totalPage,
      };
    case types.GET_EMPLOYEE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        statusCode: action.statusCode,
      };
      case types.CLEAR_EMPLOYEE:
        return {
          ...state,
          employee: [],
          loading: false,
          error: null,
          statusCode: null,
          totalCount: 0,
          totalPage: 0,
        };
    default:
      return state;
  }
};

export default employee;
