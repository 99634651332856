import React from "react";
import { BiLeftArrowAlt } from "react-icons/bi";
import { useHistory } from "react-router-dom";

export default function ButtonBackPage({
  className,
  pathRoute,
  style
}) {
    const history = useHistory();

  return (
    <button
      className={className}
      style={style}
      onClick={() => history.push(pathRoute)}
    >
 <BiLeftArrowAlt size={30} color="#7b7b7b" />
    </button>
  );
}
