import React from "react";
import Dropdown from "./Dropdown";
export default function InputDropdown({
  title,
  item,
  itemCallback,
  isButtom,
  initState,
}) {
  const mgTop = {
    marginTop: "10px",
  };

  return (
    <div style={mgTop}>
      <p className="txt-sub-explanation1">{title}</p>
      <Dropdown
        items={item}
        itemCallback={itemCallback}
        hasFilter={false}
        isButtom={isButtom}
        // initState={item[0]}
      />
    </div>
  );
}
