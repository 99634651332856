import React from "react";
import { Row, Col } from "reactstrap";

export default function DisplayEnvironment({
  imageDisplayEnvironment,
  headerDisplayEnvironment,
  valueDisplayEnvironment,
}) {
  return (
    <div>
      <div className="display-environment-lg-by-nile">
        <div>
          <img
            className="img-display-environment"
            src={imageDisplayEnvironment}
            alt={imageDisplayEnvironment}
          />
          <p className="txt-header-display-environment">
            {" "}
            {valueDisplayEnvironment}
          </p>
          <p className="txt-sub-display-environment">
            {headerDisplayEnvironment}
          </p>
        </div>
      </div>

      <div className="display-environment-sm-by-nile">
        <Row>
          <Col xs={4} sm={4} md={4} lg={4} xl={4}>
            <img
              className="img-display-environment"
              src={imageDisplayEnvironment}
              alt={imageDisplayEnvironment}
            />
          </Col>
          <Col xs={8} sm={8} md={8} lg={8} xl={8}>
            <p className="txt-header-display-environment">
              {" "}
              {valueDisplayEnvironment}
            </p>
            <p className="txt-sub-display-environment">
              {headerDisplayEnvironment}
            </p>
          </Col>
        </Row>
      </div>
    </div>
  );
}
