import EastRegionImg1 from "./east-3x3.jpeg";
import EastRegionImg2 from "./east-ex1.jpeg";
import EastRegionImg3 from "./east-ex2.jpeg";
import EastRegionImg4 from "./east-ex4.jpeg";
import MiddleRegionImg1 from "./middle-ex1.jpeg";
import MiddleRegionImg2 from "./middle-ex2.jpeg";
import MiddleRegionImg3 from "./middle-ex3.jpeg";
import MiddleRegionImg4 from "./middle-ex4.jpeg";
import NorthEastRegionImg1 from "./north-east-ex1.jpeg";
import NorthEastRegionImg2 from "./north-east-ex2.jpeg";
import NorthEastRegionImg3 from "./north-east-ex3.jpeg";
import NorthEastRegionImg4 from "./north-east-ex4.jpeg";
import NorthRegionImg1 from "./north-ex1.jpeg";
import NorthRegionImg2 from "./north-ex2.jpeg";
import NorthRegionImg3 from "./north-ex3.png";
import NorthRegionImg4 from "./north-ex4.jpeg";
import SouthRegionImg1 from "./south-ex1.jpeg";
import SouthRegionImg2 from "./south-ex2.jpeg";
import SouthRegionImg3 from "./south-exx3.jpeg";
import SouthRegionImg4 from "./south-ex4.jpeg";
import EastMapMini from "./east-map-mini.svg";
import MiddleMapMini from "./middle-map-mini.svg";
import NorthEastMapMini from "./north-east-map-mini.svg";
import NorthMapMini from "./north-map-mini.svg";
import SouthMapMini from "./south-map-mini.svg";
const EastListImg = [
  EastRegionImg1,
  EastRegionImg2,
  EastRegionImg3,
  EastRegionImg4,
  EastMapMini,
];
const MiddleListImg = [
  MiddleRegionImg1,
  MiddleRegionImg2,
  MiddleRegionImg3,
  MiddleRegionImg4,
  MiddleMapMini,
];
const NorthEastListImg = [
  NorthEastRegionImg1,
  NorthEastRegionImg2,
  NorthEastRegionImg3,
  NorthEastRegionImg4,
  NorthEastMapMini,
];
const NorthListImg = [
  NorthRegionImg1,
  NorthRegionImg2,
  NorthRegionImg3,
  NorthRegionImg4,
  NorthMapMini,
];
const SouthListImg = [
  SouthRegionImg1,
  SouthRegionImg2,
  SouthRegionImg3,
  SouthRegionImg4,
  SouthMapMini,
];
const ImgRegionMap = {
  0: MiddleListImg,
  1: NorthListImg,
  2: EastListImg,
  3: SouthListImg,
  4: NorthEastListImg,
  5: NorthListImg,
  6: MiddleListImg,
  7: MiddleListImg,
  8: MiddleListImg,
  9: MiddleListImg,
  10: NorthEastListImg,
  11: NorthEastListImg,
};

const ImgRegionText = {
  0: "ภาคกลาง",
  1: "ภาคเหนือ",
  2: "ภาคตะวันออก",
  3: "ภาคใต้",
  4: "ภาคอีสาน",
  5: "ภาคเหนือ",
  6: "ภาคกลาง",
  7: "ภาคกลาง",
  8: "ภาคกลาง",
  9: "ภาคกลาง",
  10: "ภาคอีสาน",
  11: "ภาคอีสาน",
};
export {
  EastListImg,
  MiddleListImg,
  NorthEastListImg,
  NorthListImg,
  SouthListImg,
  ImgRegionMap,
  ImgRegionText,
  // EastRegionImg1,
  // EastRegionImg2,
  // EastRegionImg3,
  // EastRegionImg4,
  // MiddleRegionImg1,
  // MiddleRegionImg2,
  // MiddleRegionImg3,
  // MiddleRegionImg4,
  // NorthEastRegionImg1,
  // NorthEastRegionImg2,
  // NorthEastRegionImg3,
  // NorthEastRegionImg4,
  // NorthRegionImg1,
  // NorthRegionImg2,
  // NorthRegionImg3,
  // NorthRegionImg4,
  // SouthRegionImg1,
  // SouthRegionImg2,
  // SouthRegionImg3,
  // SouthRegionImg4,
  // EastMapMini,
  // MiddleMapMini,
  // NorthEastMapMini,
  // NorthMapMini,
  // SouthMapMini,
};
