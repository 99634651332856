import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux'
import store from './redux/store';
import reportWebVitals from './reportWebVitals';

// import "./assets/css/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { BrowserRouter } from "react-router-dom";

console.log("Edit % : 21/07/2566 , 12:00");

ReactDOM.render(
  // <React.StrictMode>
       <Provider store={store}>
       <BrowserRouter>
      <App />
    </BrowserRouter>
       </Provider>
  //  </React.StrictMode>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
