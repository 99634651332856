import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import InputNormal from "../../components/InputNormal";
import InputDropdown from "../../components/InputDropdown";
import ButtonPrimary from "../../components/ButtonPrimary";
import {
  ImgRegionMap,
  ImgRegionText,
} from "../../assets/images/region-img/IndexRegionImage";
import { useHistory } from "react-router-dom";
import axios from "axios";
import * as api from "../../Service/Api_Muthermfacesense";
import { Modal } from "antd";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import openNotificationWithIcon from "../../components/NotificationAlertAntd";
import { useDispatch, useSelector } from "react-redux";
import { getRpcRequest, getDepartment, getFactory } from "../../redux/actions";
import {
  AllCheckerCheckbox,
  Checkbox,
  CheckboxGroup,
} from "@createnl/grouped-checkboxes";
import ButtonBackPage from "../../components/ButtonBackPage";
import InputNormalHasValue from "../../components/InputNormalHasValue";
import SelectFilterClear from "../../components/SelectFilterClear";
import rpcData from "../../assets/data/dataRpc";
import FactoryInRpc from "../../assets/data/FactoryInRpc";

export default function NewEmployee() {
  const token = localStorage.getItem("token");
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [regionImgMap, setregionImgMap] = useState(ImgRegionMap[0]);
  const [regionImgText, setregionImgText] = useState(ImgRegionText[0]);
  const [createEmployee, setCreateEmployee] = useState({
    code: "",
    first_name: null,
    last_name: null,
    department_id: null,
    factory_id: null,
  });
  const [error, setError] = useState({
    errorCode: false,
    errorFirst_name: false,
    errorLast_name: false,
    errorDepartment_id: false,
    errorFactory_id: false,
  });
  const [databaseZoneSelect, setDatabaseZoneSelect] = useState([]);
  const [errorDatabaseZone, setErrorDatabaseZone] = useState(false);
  const [errorLoop, setErrorLoop] = useState(null);
  const [isRoot, setIsRoot] = useState(false);

  const dispatch = useDispatch();
  const { department, factory, UserInformation } = useSelector(
    (state) => state
  );

  useEffect(() => {
    if (UserInformation && UserInformation.is_root !== null) {
      if (UserInformation.is_root === true) {
        setIsRoot(UserInformation.is_root);
      } else {
        setIsRoot(UserInformation.is_root);
        setCreateEmployee({
          ...createEmployee,
          factory_id: UserInformation.factory_id,
        });
        findRpcZoneFromFactoryId(UserInformation.factory_id);
      }
    }

    return () => {};
  }, [UserInformation]);

  function findRpcZoneFromFactoryId(factory_id) {
    const rpcDefault = [];
    FactoryInRpc.map((data) => {
      if (data.id === factory_id) {
        rpcDefault.push(data.rpc_id);
      }
    });
    setDatabaseZoneSelect(rpcDefault);
  }

  useEffect(() => {
    dispatch(getRpcRequest());
    dispatch(getDepartment());
    dispatch(getFactory());

    return () => {};
  }, []);

  useEffect(() => {
    setErrorDatabaseZone(false);

    return () => {};
  }, [databaseZoneSelect]);

  useEffect(() => {
    if(createEmployee.factory_id !== null){
      findRpcZoneFromFactoryId(createEmployee.factory_id);
    } else {
      setDatabaseZoneSelect([])
    }
  
    return () => {
      
    }
  }, [createEmployee.factory_id])

  function onChangeDataCode(value) {
    let valueCode = value;
    valueCode = value.replace(/[^\x00-\x7F]+/gi, "");
    if (valueCode) {
      setCreateEmployee({
        ...createEmployee,
        code: valueCode,
      });
      setError({
        ...error,
        errorCode: false,
      });
    } else {
      setCreateEmployee({
        ...createEmployee,
        code: "",
      });
      setError({
        ...error,
        errorCode: true,
      });
    }
  }

  function onChangeData(value, nameState, nameError) {
    if (value) {
      setCreateEmployee({
        ...createEmployee,
        [nameState]: value,
      });
      setError({
        ...error,
        [nameError]: false,
      });
    } else {
      setCreateEmployee({
        ...createEmployee,
        [nameState]: null,
      });
      setError({
        ...error,
        [nameError]: true,
      });
    }
  }

  const onCheckboxChange = (checkboxes) => {
    const checkZone = [];
    checkboxes.map((data) => {
      if (data.checked === true) {
        checkZone.push(data.value);
      }
    });
    setDatabaseZoneSelect(checkZone);
  };

  function submitCreateNewEmployee() {
    if (
      createEmployee.code === "" ||
      createEmployee.first_name === null ||
      createEmployee.last_name === null ||
      createEmployee.department_id === null ||
      createEmployee.factory_id === null
    ) {
      const errorInLoop = error;
      for (var key in createEmployee) {
        if (createEmployee[key] === null || createEmployee[key] === "") {
          switch (key) {
            case "code":
              errorInLoop.errorCode = true;
              break;
            case "first_name":
              errorInLoop.errorFirst_name = true;
              break;
            case "last_name":
              errorInLoop.errorLast_name = true;
              break;
            case "department_id":
              errorInLoop.errorDepartment_id = true;
              break;
            case "factory_id":
              errorInLoop.errorFactory_id = true;
              break;
            default:
              break;
          }
        }
      }
      setErrorLoop(errorInLoop);
    } else if (databaseZoneSelect.length === 0) {
      setErrorDatabaseZone(true);
    } else {
      setVisible(true);
    }
  }

  function createNewEmployee() {
    setLoading(true);
    const create = api.CREATE_NEW_EMPLOYEE(token, createEmployee);
    axios(create)
      .then((res) => {
        if (databaseZoneSelect.length === 0) {
          setLoading(false);
          openNotificationWithIcon("success", "Create new employee success");
          history.push("/employee/personal/"+res.data.message.employee_id);
        } else {
          addEmployeeInRPCZone(res.data.message.employee_id);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error) {
          if (error.response) {
            if (error.response.status === 409) {
              openNotificationWithIcon(
                "error",
                "รหัสพนักงานนี้มีการใช้งานแล้ว"
              );
            } else {
              openNotificationWithIcon(
                "error",
                error.response.data.detail
                  ? error.response.data.detail
                  : "Create new employee error : " +
                      error.response.status +
                      " : " +
                      error.response.statusText
              );
            }
          }
        } else {
          openNotificationWithIcon("error", "Internet error");
        }
      });
  }

  async function addEmployeeInRPCZone(employee_id) {
    const promises = databaseZoneSelect.map((rpc_id) =>
      addEmployeeOne(rpc_id, employee_id)
    );
    await Promise.all(promises);
    setLoading(false);
    openNotificationWithIcon("success", "Create new employee success");
    history.push("/employee/personal/"+employee_id);
  }

  async function addEmployeeOne(rpc_id, employee_id) {
    const addEmployeeOne = api.ADD_EMPLOYEE_IN_RPC_ZONE(
      token,
      employee_id,
      rpc_id
    );
    await axios(addEmployeeOne)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return error.response;
      });
  }

  function handleChangeSelect(value, nameState, nameError) {
    if (value) {
      setCreateEmployee({
        ...createEmployee,
        [nameState]: value,
      });
      setError({
        ...error,
        [nameError]: false,
      });
    } else {
      setCreateEmployee({
        ...createEmployee,
        [nameState]: null,
      });
      setError({
        ...error,
        [nameError]: true,
      });
    }
  }

  function checkDefaultCheckbox(indexCheckbox) {
    let dataIsCheck = false;
    databaseZoneSelect.map((data) => {
      if (indexCheckbox === data) {
        dataIsCheck = true;
      }
    });
    return dataIsCheck;
  }

  function returnFactoryFromID(idFactory) {
    let nameFactory = null;
    if (idFactory) {
      factory.factory.map((data) => {
        if (data.id === idFactory) {
          nameFactory = data.factory_name;
        }
      });
    }
    return nameFactory;
  }

  return (
    <div>
      <ButtonBackPage
        className="icon-txt-button"
        style={{ width: "80px", marginTop: "0px" }}
        pathRoute="/employee"
      />
      {/* <button onClick={() => debugFunc()}>debug</button> */}
      <div style={{ border: "none" }} className="box-add-new-student-by-nile">
        <div className="row-jcct-py">
          <p
            style={{
              color: "#134e96",
              fontSize: "1.2rem",
              marginBottom: "10px",
            }}
            className="txt-sub-explanation1"
          >
            New Employee
          </p>
        </div>

        <Row>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <div className="box-main-name-add-new-student">
              <InputNormal
                error={error.errorFirst_name}
                textError="Firstname is required"
                require={true}
                title="Firstname"
                onChange={(e) =>
                  onChangeData(e.target.value, "first_name", "errorFirst_name")
                }
              />
              <InputNormal
                error={error.errorLast_name}
                textError="Lastname is required"
                require={true}
                title="Lastname"
                onChange={(e) =>
                  onChangeData(e.target.value, "last_name", "errorLast_name")
                }
              />
              <InputNormalHasValue
                error={error.errorCode}
                textError="Code is required"
                require={true}
                title="Code"
                value={createEmployee.code}
                onChange={(e) => onChangeDataCode(e.target.value)}
              />
            </div>
          </Col>

          <Col xs={12} sm={12} md={6} lg={3} xl={3}>
            <div className="padding-div-border-input-field-add-new-student">
              {isRoot === true && (
                <SelectFilterClear
                  headerSelectFilterClear="Factory"
                  handleChange={(value) =>
                    handleChangeSelect(value, "factory_id", "errorFactory_id")
                  }
                  data={factory.factory}
                  value="id"
                  name="factory_name"
                  require={true}
                  error={error.errorFactory_id}
                  textError="Factory is require"
                />
              )}

              {isRoot === false && (
                <>
                  <p className="txt-sub-explanation1"> Factory </p>
                  <p
                    className="txt-sub-explanation1"
                    style={{ fontSize: "20px" }}
                  >
                    {" "}
                    {returnFactoryFromID(createEmployee.factory_id)}{" "}
                  </p>
                </>
              )}

              <SelectFilterClear
                headerSelectFilterClear="Department"
                handleChange={(value) =>
                  handleChangeSelect(
                    value,
                    "department_id",
                    "errorDepartment_id"
                  )
                }
                data={department.department}
                value="id"
                name="department_name"
                require={true}
                error={error.errorDepartment_id}
                textError="Department is require"
              />
            </div>
          </Col>

          <Col xs={12} sm={12} md={6} lg={3} xl={3}>
            <div style={{ padding: "10px" }}>
              <p className="txt-sub-explanation1">
                {" "}
                Database zone <span style={{ color: "red" }}>*</span>{" "}
              </p>
              <br />
              <CheckboxGroup onChange={onCheckboxChange}>
                <p
                  className="txt-sub-explanation1"
                  style={{ marginBottom: "10px" }}
                >
                  <AllCheckerCheckbox style={{ marginRight: "10px" }} /> Select
                  all database zone
                </p>
                {rpcData.map((data, index) => {
                  return (
                    <p
                      key={index}
                      className="txt-sub-explanation1"
                      style={{ marginBottom: "10px" }}
                    >
                      <Checkbox
                        style={{ marginRight: "10px" }}
                        value={data.rpc_id}
                        checked={checkDefaultCheckbox(index + 1)}
                      />{" "}
                      {data.rpc_name}
                    </p>
                  );
                })}
              </CheckboxGroup>
            </div>
            {errorDatabaseZone === true && (
              <p style={{ color: "red" }}>Database zone is required</p>
            )}
          </Col>
        </Row>

        <div className="mt-1" />
      </div>

      <div className="mt-2" />
      <Row className="justify-content-center">
        <Col xs={8} sm={6} md={6} lg={5} xl={5}>
          <div className="row-jcct">
            <ButtonPrimary
              text="Save"
              className="primary-button2"
              onClick={() => submitCreateNewEmployee()}
            />
          </div>
        </Col>
      </Row>

      <div className="mt-2" />

      <Modal
        centered
        closable={false}
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={null}
        className="modalStyle-ModalSelectATK"
      >
        <br />
        <p className="txt-confirm-create-new-employee">
          <AiOutlineQuestionCircle
            size="30"
            color="#faad14"
            style={{ marginRight: "10px" }}
          />
          Confirm create new employee
        </p>
        <br />
        <Row>
          <Col xs="12" sm="12" md="6" lg="6" xl="6">
            <br />
            <ButtonPrimary
              className="button2-save-create-new-employee-by-nile"
              text="Confirm"
              onClick={() => createNewEmployee()}
              loading={loading}
            />
          </Col>
          <Col xs="12" sm="12" md="6" lg="6" xl="6">
            <br />
            <ButtonPrimary
              className="icon-txt-button2-by-nile"
              text="Cancel"
              onClick={() => setVisible(false)}
            />
          </Col>
        </Row>
      </Modal>
    </div>
  );
}
