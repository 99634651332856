import React, { useState } from "react";
import ButtonPrimary from "../../../components/ButtonPrimary";
import { Row, Col } from "reactstrap";
import InputNoHeader from "../../../components/InputNoHeader";
import { AiFillDelete } from "react-icons/ai";
import { IoMdAddCircle } from "react-icons/io";

export default function EditComponents({
  announcement,
  setEdit,
  edit,
  removeAnnouncement,
  newInputAnnouncement,
  setNewInputAnnouncement,
  addNewAnnouncement,
  errorInput,
  EditAnnouncement,
  submitNewAnnouncement
}) {

  return (
    <div>
      <Row>
        <Col>
          <div className="float-right">
            {edit === false && (
              <ButtonPrimary
                className="icon-txt-button2-by-nile float-right"
                text="Edit config"
                style={{
                  width: "150px",
                  marginBottom: "10px",
                  marginRight: "10px",
                }}
                onClick={() => setEdit(true)}
              />
            )}
            {edit === true && (
              <ButtonPrimary
                className="icon-txt-button2-by-nile float-right"
                text="Cancel"
                style={{
                  width: "150px",
                  marginBottom: "10px",
                  marginRight: "10px",
                }}
                onClick={() => setEdit(false)}
              />
            )}
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <p className="txt-sub-header1-by-nile">Announcement</p>
        </Col>
      </Row>

      {edit === true && (
        <>
          <Row>
            <Col xs={9} sm={10} md={11} lg={11} xl={11}>
              <InputNoHeader
                onChange={(e) => setNewInputAnnouncement(e.target.value)}
                placeholder="New announcement"
                style={{ width: "100%", margin: "0px 0px 10px 5px" }}
                value={newInputAnnouncement ? newInputAnnouncement : ""}
              />
              {
                errorInput && (
                  <p style={{ color:'red' }}>Please input announcement</p>
                )
              }
            </Col>
            <Col xs={3} sm={2} md={1} lg={1} xl={1}>
              <ButtonPrimary
                className="btn-add-and-remove-announcement-component btn-add-new-announcement-component"
                text={<IoMdAddCircle color="#FFF" size="25px" />}
                style={{ float: "right", marginRight: "5px" }}
                onClick={() => addNewAnnouncement()}
              />
            </Col>
          </Row>

          <hr />
          <Row>
            <Col>
              {announcement.map((data, index) => {
                return (
                  <div
                    className="text-announcement-display"
                    key={index}
                    style={{ width: "100%" }}
                  >
                    <Row>
                      <Col xs={9} sm={10} md={11} lg={11} xl={11}>
                        <InputNoHeader
                          onChange={(e) => EditAnnouncement(index ,e.target.value)}
                          placeholder="Announcement"
                          value={data}
                          style={{ width: "100%", margin: "0px 0px 10px 5px" }}
                        />
                      </Col>
                      <Col xs={3} sm={2} md={1} lg={1} xl={1}>
                        <ButtonPrimary
                          className="btn-add-and-remove-announcement-component btn-remove-announcement-component"
                          text={<AiFillDelete color="#FFF" size="25px" />}
                          onClick={() => removeAnnouncement(index)}
                          style={{ float: "right", marginRight: "5px" }}
                        />
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </Col>
          </Row>
          <Row>
            <Col>
              <ButtonPrimary
                className="btn-submit-config"
                text="Submit"
                onClick={() => submitNewAnnouncement()}
              />
            </Col>
          </Row>
        </>
      )}

      {edit === false && (
        <Row>
          <Col>
            <ul>
              {announcement.map((data, index) => {
                return (
                  <li className="text-announcement-display" key={index}>
                    {data}
                  </li>
                );
              })}
            </ul>
          </Col>
        </Row>
      )}
    </div>
  );
}
