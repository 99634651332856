import React,{ useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import Dropdown_Has_Key_Value from "../../../components/Dropdown_Has_Key_Value";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlinePlusCircle } from "react-icons/ai";
import {
  AllCheckerCheckbox,
  Checkbox,
  CheckboxGroup,
  NoneCheckerCheckbox
} from "@createnl/grouped-checkboxes";

export default function SelectRpcTable({ setRpc_zone_id, rpc }) {

  const onCheckboxChange = (checkboxes) => {
    const checkZone = [];
    checkboxes.map((data) => {
      if (data.checked === true) {
        checkZone.push({
          rpc_id: data.rpc_id,
          rpc_name: data.rpc_name,
          check: data.checked,
        });
      }
    });
    setRpc_zone_id(checkZone);
  };

  return (
    <div style={{ padding: "10px" }}>
      <CheckboxGroup onChange={onCheckboxChange}>
        <p className="txt-sub-explanation1" style={{ marginBottom: "10px" }}>
          <AllCheckerCheckbox
            style={{ marginRight: "10px" }}
          />{" "}
          Select all rpc zone
        </p>
        {rpc.map((data, index) => {
          return (
            <p
              key={index}
              className="txt-sub-explanation1"
              style={{ marginBottom: "10px" }}
            >
              <Checkbox
                style={{ marginRight: "10px" }}
                rpc_name={data.rpc_name}
                rpc_id={data.rpc_id}
              />{" "}
              {data.rpc_name}
            </p>
          );
        })}
      </CheckboxGroup>
    </div>
  );
}
