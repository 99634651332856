import React from "react";
import { BsPlus } from "react-icons/bs";

export default function ButtonTrainImage_When_No_ID({ no , textButton , onClick }) {
  //   console.log(dataUser);
  return (
    <div>
      {
        no && (
          <div className="div-border-button-train-image-user">
          <p className="text-p-no-user">{no}</p>
        </div>
        )
      }
      <div className="div-button-train-image border-cursor-pointer"  onClick={onClick}>
        <div className="div-border-icon-train-image">
          <BsPlus className="class-icon-train-image" size={30} />
        </div>
        <p className="front-icon-train-image">{textButton}</p>
      </div>
    </div>
  );
}
