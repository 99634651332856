import React, { useState, useEffect } from "react";
import ButtonPrimary from "./ButtonPrimary";
import { MdArrowDropDown } from "react-icons/md/index";
import { BiFilterAlt } from "react-icons/bi";
export default function Dropdown({
  items,
  itemCallback,
  hasFilter,
  styles,
  isButtom,
  id_Dropdown,
  name_Dropdown,
  textHeader,
  defaultKey,
  defaultName,
}) {
  const [itemNow, setItem] = useState(defaultName ? defaultName : null);
  var top = isButtom ? { bottom: "3.6rem" } : { top: "3.6rem" };
  var height = isButtom && { height: "auto" };

  // useEffect(() => {
  //   if(items.length > 0) {
  //     setItem(items[0][name_Dropdown])
  //     itemCallback(items[0][id_Dropdown], items[0][name_Dropdown])
  //   }

  //   return () => {

  //   }
  // }, [items])

  return (
    <div className="box-dropdown-filter-cover">
      <ButtonPrimary
        className="box-dropdown-filter"
        text={
          <>
            {hasFilter ? <BiFilterAlt style={{ fontSize: "1.3rem" }} /> : <></>}
            {itemNow}
            <MdArrowDropDown style={{ fontSize: "1.7rem" }} />
          </>
        }
      ></ButtonPrimary>
      <div
        className="box-dropdown-filter-selector"
        style={{ ...styles, ...top, ...height }}
      >
        <ul>
          <li
            onClick={() => {
              setItem(defaultName);
              itemCallback(defaultKey);
            }}
          >
            <p className="txt-drop-down">{defaultName}</p>
          </li>
          {items.map((item, index) => {
            return (
              <li
                key={index}
                onClick={() => {
                  setItem(item[name_Dropdown]);
                  itemCallback(item[id_Dropdown], item[name_Dropdown]);
                }}
              >
                <p className="txt-drop-down">{item[name_Dropdown]}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
