import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmployeeDeleted,
  getDepartment,
  getFactory,
} from "../../../redux/actions";
import TableLogComponents from "../../../components/TableLogComponents";
import fineDataFromArray from "../../../components/FindDataFromArray";

export default function GetEmployeeDeleted() {
  const dispatch = useDispatch();
  const { employeeDeleted, department, factory } = useSelector(
    (state) => state
  );

  useEffect(() => {
    dispatch(getEmployeeDeleted());
    dispatch(getDepartment());
    dispatch(getFactory());

    return () => {
      clear();
    };
  }, []);

  function clear() {
    let payload = {
      clear: true,
    };
    dispatch(getEmployeeDeleted(payload));
  }

  function convertUTCDateToLocalDate(date) {
    var newDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000
    );
    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();
    newDate.setHours(hours - offset);
    return newDate;
  }

  function renderDateTime(dateTime) {
    var date = convertUTCDateToLocalDate(new Date(dateTime));
    return date.toLocaleString();
  }

  const rowTableEmployeeDeleted = [
    {
      title: "ID",
      dataIndex: 0,
      key: 0,
    },
    {
      title: "Code",
      dataIndex: 1,
      key: 1,
    },
    {
      title: "Firstname",
      dataIndex: 2,
      key: 2,
    },
    {
      title: "Lastname",
      dataIndex: 3,
      key: 3,
    },
    {
      title: "Department",
      dataIndex: 4,
      key: 4,
      render: (data) => (
        <div>
          {fineDataFromArray(department.department, data, "department_name")}
        </div>
      ),
    },
    {
      title: "Factory",
      dataIndex: 5,
      key: 5,
      render: (data) => (
        <div>{fineDataFromArray(factory.factory, data, "factory_name")}</div>
      ),
    },
    {
      title: "Create at",
      dataIndex: 6,
      key: 6,
      render: (time) => <div>{renderDateTime(time)}</div>,
    },
    {
      title: "Delete at",
      dataIndex: 8,
      key: 8,
      render: (time) => <div>{renderDateTime(time)}</div>,
    },
  ];

  return (
    <div>
      <div className="box-table-by-nile">
        <TableLogComponents
          columns={rowTableEmployeeDeleted}
          dataSource={employeeDeleted.employeeDeleted}
          loading={employeeDeleted.loading}
        />
      </div>
      <p className="text-header-date-picker">
        Total : {employeeDeleted.employeeDeleted.length}
      </p>
    </div>
  );
}
