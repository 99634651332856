import React, { useState, useEffect } from "react"
import DropzoneImage from "./DropzoneImage"
import { Decoder } from "@nuintun/qrcode"
import { Spin, Button } from "antd"
import ButtonPrimary from '../../../components/ButtonPrimary';
import { useDispatch, useSelector } from "react-redux"
import { getCutQrcodeEmployee } from '../../../redux/actions';

const UploadImageToDecodeQRCode = ({
setUserInfo,
  userInfo,
  setUploadImageDecodeQRCode,
  uploadImageDecodeQRCode,
}) => {
  const dispatch = useDispatch()
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [error, setError] = useState(null)

  const qrcode = new Decoder()
  const fileToDataUri = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = event => {
        resolve(event.target.result)
      }
      reader.readAsDataURL(file)
    })

  useEffect(() => {
    if (uploadImageDecodeQRCode !== null) {
      qrcode
        .scan(uploadImageDecodeQRCode)
        .then(result => {
          checkQRCode(result.data)
        })
        .catch(error => {
          setError("ไม่สามารถอ่าน qr code ได้ โปรดอัพโหลดรูปภาพใหม่")
          setLoadingUpload(false)
          setUploadImageDecodeQRCode(null)
        })
    }
    return () => {}
  }, [uploadImageDecodeQRCode])

  function checkQRCode(dataQRCode) {
    dispatch(getCutQrcodeEmployee(dataQRCode))
    setLoadingUpload(false)
  }

  useEffect(() => {
  if(userInfo.firstname === null){
    cancelUpload()
  }
    return () => {

    };
  }, [userInfo]);

  function cancelUpload() {
    setUploadImageDecodeQRCode(null)
    setLoadingUpload(false)
    setError(null)
  }

  return (
    <div
      style={{
        backgroundColor: "#FFF",
        padding: "10px"
      }}
    >
      {loadingUpload === false && (
        <>
          {error !== null && <p style={{ color: "red", fontSize:'15px', padding:'10px' }}>{error}</p>}
          <DropzoneImage
            setUploadImageDecodeQRCode={setUploadImageDecodeQRCode}
            setLoadingUpload={setLoadingUpload}
          />
        </>
      )}
      {loadingUpload === true && (
        <div>
           <Spin style={{ display: "block", margin: "0px auto -25px auto" }} />
            <ButtonPrimary
                className="icon-txt-button2-by-nile"
                style={{ float: "right", width:'150px' }}
                text="ยกเลิก"
                onClick={() => cancelUpload()}
              />
              <br />
              <br />
        </div>
      )}
    </div>
  )
}

export default UploadImageToDecodeQRCode
