import React from "react";
import Dropdown_In_Input from "./Dropdown_In_Input";

export default function InputDropdown({
  title,
  item,
  itemCallback,
  isButtom,
  initState,
  id_Dropdown,
  name_Dropdown
}) {
  const mgTop = {
    marginTop: "10px",
  };

//   console.log(item);
  return (
    <div style={mgTop}>
      <p className="txt-sub-explanation1">{title}</p>
    <Dropdown_In_Input
        items={item}
        itemCallback={itemCallback}
        hasFilter={false}
        isButtom={isButtom}
        id_Dropdown={id_Dropdown}
        name_Dropdown={name_Dropdown}
        // initState={item[0]}
      /> 
    </div>
  );
}
