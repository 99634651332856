import React, { useState, useEffect } from "react";
import TableLogComponents from "../../components/TableLogComponents";
import { left, right, user } from "../../assets/images";
import DisplayImageTableActivities from "../../components/DisplayImageTableActivities";
import { face_detected, no_image_in_systems } from "../../assets/icons";
import ButtonTrainImage_When_No_ID from "../../components/ButtonTrainImage_When_No_ID";
import { useHistory } from "react-router-dom";
import { Tooltip, Modal, Pagination } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeeRequest, getLogFilterRequest } from "../../redux/actions";
import SelectEmployee from "./SelectEmployee";
import ButtonPrimary from "../../components/ButtonPrimary";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { Row, Col } from "reactstrap";
import axios from "axios";
import * as api from "../../Service/Api_Muthermfacesense";
import openNotificationWithIcon from "../../components/NotificationAlertAntd";
import ConvertTimezoneLocal from "../../components/ConvertTimezoneLocal";
import ThreeImageDisplaySelect from "../../components/ThreeImageDisplaySelect";

var Ip = require("../../Service/Ip");

export default function TableActivities({
  employee,
  setError,
  searchLog,
  setSearchLog,
  page_limit,
  setPage_limit,
}) {
  const history = useHistory();
  const token = localStorage.getItem("token");
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addFeatureEmployee, setAddFeatureEmployee] = useState({
    _id: null,
    employee_id: null,
    first_name: null,
    last_name: null,
    img: null,
  });
  const [page_limit_select_employee, setPage_limit_select_employee] = useState({
    page: 1,
    limit: 0,
    page_Size: 10,
  });

  const dispatch = useDispatch();
  const { logFilter } = useSelector((state) => state);

  useEffect(() => {
    setError(logFilter.error);
    if (logFilter.error !== null) {
      setPage_limit({
        ...page_limit,
        page: 1,
      });
    } else {
      setPage_limit({
        ...page_limit,
        limit: logFilter.totalCount,
      });
    }

    return () => {};
  }, [logFilter]);

  function changePagination(page, pageSize) {
    const payload = {
      page: page,
      limit: pageSize,
      timestamp_date_start: searchLog.timestamp_date_start,
      timestamp_date_end: searchLog.timestamp_date_end,
      employee_id: searchLog.employee_id,
      device_serial_number : searchLog.device_serial_number
    };

    dispatch(getLogFilterRequest(payload));
    setPage_limit({
      ...page_limit,
      page: page,
      page_Size: pageSize,
    });
  }

  function colorStyleTRTable(data) {
    if (data.status === "created") return "#ffeded";
    else return "#FFF";
  }

  const rowTableLog = [
    {
      title: "",
      render: (data) => (
        <div style={{ position: "relative", marginTop: "20px" }}>
          {data.status === "created" && (
            <ButtonTrainImage_When_No_ID
              textButton="Add feature"
              onClick={() => {
                setAddFeatureEmployee({
                  ...addFeatureEmployee,
                  _id: data._id.$oid,
                  img: data.img,
                });
                setVisible(true);
              }}
            />
          )}
        </div>
      ),
      functionStyleTR: (data) => {
        if (data.status === "created") return "#ffeded";
        else return "#FFF";
      },
    },
    {
      title: "Image",
      render: (text) => (
        <img
          className="image-components-display"
          src={`${Ip.getImagePath()}imagestatic/${text.img}`}
          alt="ImageLog"
          onError={(e) => {
            e.target.src = user;
          }}
        />
      ),
    },
    {
      title: "Name",
      render: (data) => (
        <p style={{ whiteSpace: "nowrap" }}>{checkNameEmployee(data)}</p>
      ),
    },
    {
      title: "Log type",
      render: (text) => <p>{text.log_type ? text.log_type : null}</p>,
    },
    {
      title: "Device",
      render: (text) => <p>{text.device_sn ? text.device_sn : null}</p>,
    },
    {
      title: "Temp",
      dataIndex: "temp",
      key: "temp",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Status",
      render: (data) => (
        <div>
          {data.status === "created" && (
            <Tooltip title="created">
              <img
                src={no_image_in_systems}
                alt="no_image_in_systems"
                style={{ width: "40px" }}
              />
            </Tooltip>
          )}
          {data.status === "updated" && (
            <Tooltip title="updated">
              <img
                src={face_detected}
                alt="face_detected"
                style={{ width: "40px" }}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      title: "Create",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <p>{ConvertTimezoneLocal(text.$date)}</p>,
    },
    {
      title: "result",
      render: (text) => (
        <div>
          <ThreeImageDisplaySelect data={text} />
        </div>
      ),
    },
  ];

  function checkNameEmployee(data) {
    if (data.result[0].confident_percent >= 80) {
      return (
        data.employee_predict_data.data.first_name +
        " " +
        data.employee_predict_data.data.last_name
      );
    } else {
      return "Unknow";
    }
  }

  function renderJson(json) {
    const myJSON = JSON.stringify(json);
    return myJSON;
  }

  function closeModal() {
    setAddFeatureEmployee({
      ...addFeatureEmployee,
      _id: null,
      employee_id: null,
      first_name: null,
      last_name: null,
      img: null,
    });
    setVisible(false);

    const payload = {
      page: 1,
      limit: 10,
    };
    dispatch(getEmployeeRequest(payload));
    setPage_limit_select_employee({
      ...page_limit_select_employee,
      page: 1,
      limit: 0,
      page_Size: 10,
    });
  }

  function addFeature() {
    setLoading(true);
    const add = api.ADD_EMPLOYEE_FEATURE(
      token,
      addFeatureEmployee.employee_id,
      addFeatureEmployee._id
    );
    axios(add)
      .then((res) => {
        const payload = {
          page: 1,
          limit: 10,
          timestamp_date_start: searchLog.timestamp_date_start,
          timestamp_date_end: searchLog.timestamp_date_end,
        };
        openNotificationWithIcon("success", "Add employee feature success");
        dispatch(getLogFilterRequest(payload));
        setLoading(false);
        closeModal();
      })
      .catch((error) => {
        setLoading(false);
        openNotificationWithIcon("error", "Add employee feature error");
      });
  }

  return (
    <div className="box-table-by-nile">
      <TableLogComponents
        columns={rowTableLog}
        dataSource={logFilter.log}
        functionStyleTableRow={colorStyleTRTable}
        loading={logFilter.loading}
      />

      <div>
        <div style={{ float: "right" }}>
          <Pagination
            current={page_limit.page}
            total={page_limit.limit}
            onChange={changePagination}
          />
        </div>
        <p className="text-header-date-picker" style={{ marginTop: "20px" }}>
          total data : {page_limit.limit}
        </p>
      </div>

      <Modal
        centered
        closable={false}
        visible={visible}
        onOk={() => closeModal()}
        onCancel={() => closeModal()}
        footer={null}
        width={800}
        className="modalStyle-ModalSelectATK"
      >
        {addFeatureEmployee.employee_id === null &&
          addFeatureEmployee.first_name === null && (
            <SelectEmployee
              employee={employee}
              setAddFeatureEmployee={setAddFeatureEmployee}
              addFeatureEmployee={addFeatureEmployee}
              page_limit_select_employee={page_limit_select_employee}
              setPage_limit_select_employee={setPage_limit_select_employee}
            />
          )}
        {addFeatureEmployee.employee_id !== null &&
          addFeatureEmployee.first_name !== null && (
            <div>
              <p className="txt-confirm-create-new-employee">
                <AiOutlineQuestionCircle
                  size="30"
                  color="#faad14"
                  style={{ marginRight: "10px" }}
                />
                Confirm add employee feature
              </p>
              <img
                style={{ display: "block", margin: "auto" }}
                className="image-components-display"
                src={`${Ip.getImagePath()}imagestatic/${
                  addFeatureEmployee.img
                }`}
                alt={`${Ip.getImagePath()}imagestatic/${
                  addFeatureEmployee.img
                }`}
              />
              <br />
              <p
                style={{ textAlign: "center" }}
                className="text-header-date-picker"
              >
                {addFeatureEmployee.first_name} {addFeatureEmployee.last_name}
              </p>

              <Row>
                <Col xs="12" sm="12" md="6" lg="6" xl="6">
                  <br />
                  <ButtonPrimary
                    className="button2-save-create-new-employee-by-nile"
                    text="Confirm"
                    loading={loading}
                    onClick={() => addFeature()}
                  />
                </Col>
                <Col xs="12" sm="12" md="6" lg="6" xl="6">
                  <br />
                  <ButtonPrimary
                    className="icon-txt-button2-by-nile"
                    text="Cancel"
                    onClick={() => {
                      setAddFeatureEmployee({
                        ...addFeatureEmployee,
                        employee_id: null,
                        first_name: null,
                        last_name: null,
                      });
                      setPage_limit_select_employee({
                        ...page_limit_select_employee,
                        page: 1,
                        limit: 0,
                        page_Size: 10,
                      });
                    }}
                  />
                </Col>
              </Row>
            </div>
          )}
        <br />
      </Modal>
    </div>
  );
}
