import React from "react";
import { Select } from "antd";
import "antd/dist/antd.css";
import { IoMdArrowDropdown } from "react-icons/io";

const { Option } = Select;

export default function SelectFilterClear({
  headerSelectFilterClear,
  handleChange,
  data,
  value,
  name,
  defaultValue,
  require ,
  styleSelect,
  styleDiv,
  error,
  textError,
  classNameDiv,
  styleHeader,
  classNameTextHeader
}) {
  const mgTop = {
    marginTop: "10px",
  };
  function checkRequire(require) {
    if (require) {
      return <span style={{ color: "red" }}>*</span>;
    }
  }
  return (
    <div className={classNameDiv} style={ styleDiv ? styleDiv : mgTop}>
      <p className={classNameTextHeader ?classNameTextHeader  : "txt-sub-explanation1"} style={styleHeader} > {headerSelectFilterClear} {checkRequire(require)} </p>
      <Select
        onChange={handleChange}
        defaultValue={defaultValue ? defaultValue : null}
        style={ styleSelect ? styleSelect : { width: "100%" , marginTop: "6px"  }}
        allowClear
        suffixIcon={
          <IoMdArrowDropdown
            style={{ fontSize: "1.7rem", marginLeft: "-20px" }}
          />
        }
      >
        {data.map((dataSelect) => (
          <Option key={dataSelect[value]} value={dataSelect[value]}>
            {" "}
            {dataSelect[name]}{" "}
          </Option>
        ))}
      </Select>
      {error && <p className="txt-show-error-required"> {textError} </p>}
    </div>
  );
}
