import { useState, useEffect, useContext } from "react";
import TableLogComponents from "../../components/TableLogComponents";
import DisplayImageOneTable from "../../components/DisplayImageOneTable";
import ButtonPrimary from "../../components/ButtonPrimary";
import IconButtonTooltip from "../../components/IconButtonTooltip";
import { AiOutlineDelete } from "react-icons/ai";
import { CgDanger } from "react-icons/cg";
import { Modal } from "antd";
import { Row, Col } from "reactstrap";
import axios from "axios";
import * as api from "../../Service/Api_Muthermfacesense";
import { useDispatch } from "react-redux";
import { getEmployeeOneAndFeatureRequest } from "../../redux/actions";
import openNotificationWithIcon from "../../components/NotificationAlertAntd";
import ConvertTimezoneLocal from "../../components/ConvertTimezoneLocal";
import { EditEmployeeOneContext } from "../../components/Context";

var Ip = require("../../Service/Ip");

export default function FaceDatabase({}) {
  const { feauture, employee_id } = useContext(EditEmployeeOneContext);
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [featureSelect, setFeatureSelect] = useState(null);

  const rowTableLog = [
    {
      title: "Image",
      dataIndex: "image_path",
      key: "image_path",
      render: (data) => (
        <DisplayImageOneTable
          Image={`${Ip.getImagePath()}imagestatic/${data}`}
        />
      ),
    },
    {
      title: "Create",
      dataIndex: "created_at",
      key: "created_at",
      render: (time) => <p>{ConvertTimezoneLocal(time)}</p>,
    },
    {
      title: "Update",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (time) => <p>{ConvertTimezoneLocal(time)}</p>,
    },
    {
      title: "",
      render: (data) => (
        <IconButtonTooltip
          title="Remove face database"
          className="border-cursor-pointer"
          style={{
            width: "40px",
            height: "40px",
            background: "#FE6B6B",
            borderRadius: "50%",
            boxShadow:
              "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
          }}
          icon={
            <AiOutlineDelete
              size="25"
              style={{ color: "#FFF", marginTop: "8px" }}
            />
          }
          onClick={() => {
            setVisible(true);
            setFeatureSelect(data);
          }}
        />
      ),
    },
  ];

  function closeModal() {
    setVisible(false);
    setFeatureSelect(null);
  }

  function removeFeatureEmployee() {
    setLoading(true);
    const removeFeature = api.REMOVE_FEATURE_EMPLOYEE_NEW_API(
      token,
      featureSelect.employee_feature_id
    );
    axios(removeFeature)
      .then((res) => {
        setLoading(false);
        dispatch(getEmployeeOneAndFeatureRequest(employee_id));
        openNotificationWithIcon("success", "Remove face database success");
        closeModal();
      })
      .catch((error) => {
        setLoading(false);
        if (error) {
          openNotificationWithIcon("error", "Remove face database error (4xx)");
        } else {
          openNotificationWithIcon("error", "Remove face database error (500)");
        }
      });
  }

  return (
    <div className="box-table-by-nile">
      <TableLogComponents columns={rowTableLog} dataSource={feauture} />

      <Modal
        centered
        closable={false}
        visible={visible}
        onOk={() => closeModal()}
        onCancel={() => closeModal()}
        footer={null}
        className="modalStyle-ModalSelectATK"
      >
        <br />
        <p className="txt-confirm-create-new-employee">
          <CgDanger size="30" color="red" style={{ marginRight: "10px" }} />
          Confirm Remove face database
        </p>
        {featureSelect !== null && (
          <img
            style={{ display: "block", margin: "auto" }}
            className="image-components-display"
            src={`${Ip.getImagePath()}imagestatic/${featureSelect.image_path}`}
            alt={`${Ip.getImagePath()}imagestatic/${featureSelect.image_path}`}
          />
        )}
        <br />
        <Row>
          <Col xs="12" sm="12" md="6" lg="6" xl="6">
            <br />
            <ButtonPrimary
              className="button2-save-create-new-employee-by-nile"
              text="Confirm"
              style={{ background: "red" }}
              loading={loading}
              onClick={() => removeFeatureEmployee()}
            />
          </Col>
          <Col xs="12" sm="12" md="6" lg="6" xl="6">
            <br />
            <ButtonPrimary
              className="icon-txt-button2-by-nile"
              text="Cancel"
              onClick={() => closeModal()}
            />
          </Col>
        </Row>
      </Modal>
    </div>
  );
}
