import Department from "../assets/data/Department";

const FindDepartmentFromID = (id) => {
  let nameDepartment;
  Department.map((data) => {
    if (data.id === id) {
      nameDepartment = data.name;
    }
  });
  return nameDepartment;
};

export default FindDepartmentFromID;
