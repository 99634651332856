import { useContext, useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { EditEmployeeOneContext } from "../../components/Context";
import ButtonPrimary from "../../components/ButtonPrimary";
import { Modal, notification } from "antd";
import {
  AllCheckerCheckbox,
  Checkbox,
  CheckboxGroup,
} from "@createnl/grouped-checkboxes";
import rpcData from "../../assets/data/dataRpc";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import axios from "axios";
import * as api from "../../Service/Api_Muthermfacesense";

// DELETE_EMPLOYEE_RPC_ZONE = (token , employee_zone_id )
// ADD_EMPLOYEE_IN_RPC_ZONE = (token, employee_id, rpc_id)
export default function EditEmployeeRpcZone() {
  const { rpcZone, employee_id, getUserRPCZone } = useContext(
    EditEmployeeOneContext
  );
  const [editRpcZone, setEditRpcZone] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorSelectRpc, setErrorSelectRpc] = useState(false);
  const token = localStorage.getItem("token");

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    });
  };

  useEffect(() => {
    mappingDefaultRpc();
    return () => {};
  }, [rpcZone]);

  function mappingDefaultRpc() {
    const rpcDefault = [];
    rpcZone.map((rpcZoneData) => {
      rpcDefault.push(rpcZoneData.rpc_id);
    });
    setEditRpcZone(rpcDefault);
  }

  function onCloseModal() {
    setErrorSelectRpc(false);
    setVisible(false);
    setVisibleEdit(false);
    mappingDefaultRpc();
  }

  function checkDefaultCheckbox(indexCheckbox) {
    let dataIsCheck = false;
    editRpcZone.map((data) => {
      if (indexCheckbox === data) {
        dataIsCheck = true;
      }
    });
    return dataIsCheck;
  }

  const onCheckboxChange = (checkboxes) => {
    const checkZone = [];
    checkboxes.map((data) => {
      if (data.checked === true) {
        checkZone.push(data.value);
      }
    });
    setEditRpcZone(checkZone);
  };

  function checkRpcIsEmpty() {
    if (editRpcZone.length === 0) {
      setErrorSelectRpc(true);
    } else {
      setErrorSelectRpc(false);
      setVisibleEdit(true);
    }
  }

  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function editRpcZoneFc() {
    setLoading(true);
    var interval = 1000;
    const promisesRemoveRpc = rpcZone.map(async (data, index) => {
      await timeout(interval * index);
      const loop = await removeRpcOne(data);
      return loop;
    });
    await Promise.all(promisesRemoveRpc).then((data) => {
      // console.log(data);
    });

    const promises = editRpcZone.map(async (rpc_id, index) => {
      await timeout(interval * index);
      const loopCreate = await addEmployeeOne(rpc_id);
      return loopCreate;
    });

    await Promise.all(promises).then((data) => {
      // console.log(data);
    });
    setTimeout(() => {
      onCloseModal();
      setLoading(false);
      getUserRPCZone();
    }, 2000);
  }

  async function addEmployeeOne(rpc_id) {
    let statusAddEmployeeRpc = null;
    const addEmployeeOne = api.ADD_EMPLOYEE_IN_RPC_ZONE(
      token,
      employee_id,
      rpc_id
    );
    await axios(addEmployeeOne)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Add zone ID " + rpc_id + " success"
        );
        let dataSuccess = {
          rpc_id: rpc_id,
          status: "success",
          message: res.data.message,
        };
        statusAddEmployeeRpc = dataSuccess;
      })
      .catch((error) => {
        openNotificationWithIcon("error", "Add zone ID " + rpc_id + " error");
        let dataError = {
          rpc_id: rpc_id,
          status: "error",
          message: error.response,
        };
        statusAddEmployeeRpc = dataError;
      });
    return statusAddEmployeeRpc;
  }

  async function removeRpcOne(data) {
    let statusRemoveRpcOne = null;
    const deleteRpc = api.DELETE_EMPLOYEE_RPC_ZONE(
      token,
      data.employee_zone_id
    );
    await axios(deleteRpc)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "remove employee zone ID " + data.employee_zone_id + " success"
        );
        let dataSuccess = {
          employee_zone_id: data.employee_zone_id,
          status: "success",
          message: res.data.message,
        };
        statusRemoveRpcOne = dataSuccess;
      })
      .catch((error) => {
        openNotificationWithIcon(
          "error",
          "remove employee zone ID " + data.employee_zone_id + " error"
        );
        console.log(error.response);
        let dataError = {
          employee_zone_id: data.employee_zone_id,
          status: "error",
          message: error.response,
        };
        statusRemoveRpcOne = dataError;
      });
    return statusRemoveRpcOne;
  }

  return (
    <div>
      <Row>
        <Col xs={12} sm={5} md={5} lg={5} xl={5}>
          <p className="text-personal-detail"> Zone </p>
          <ButtonPrimary
            text="Edit zone"
            className="text-button"
            style={{ fontSize: "15px", padding: "10px", marginBottom: "15px" }}
            onClick={() => setVisible(true)}
          />
        </Col>
        <Col xs={12} sm={7} md={7} lg={7} xl={7}>
          {rpcZone.map((data, index) => {
            return (
              <p className="text-personal-detail" key={index}>
                <span className="tick-personal-detail">-</span>
                {data.rpc_name}{" "}
              </p>
            );
          })}
        </Col>
      </Row>

      <Modal
        centered
        closable={false}
        visible={visible}
        onOk={() => onCloseModal()}
        onCancel={() => onCloseModal()}
        footer={null}
        className="modalStyle-ModalSelectATK"
      >
        <div style={{ padding: "10px" }}>
          <p className="txt-sub-explanation1">
            {" "}
            Database zone <span style={{ color: "red" }}>*</span>{" "}
          </p>
          <br />
          <CheckboxGroup onChange={onCheckboxChange}>
            <p
              className="txt-sub-explanation1"
              style={{ marginBottom: "10px" }}
            >
              <AllCheckerCheckbox style={{ marginRight: "10px" }} /> Select all
              database zone
            </p>
            {rpcData.map((data, index) => {
              return (
                <p
                  key={index}
                  className="txt-sub-explanation1"
                  style={{ marginBottom: "10px" }}
                >
                  <Checkbox
                    style={{ marginRight: "10px" }}
                    value={data.rpc_id}
                    checked={checkDefaultCheckbox(index + 1)}
                  />{" "}
                  {data.rpc_name}
                </p>
              );
            })}
          </CheckboxGroup>
        </div>
        {errorSelectRpc === true && (
          <p style={{ color: "red", fontSize: "15px", marginLeft: "5px" }}>
            Please select zone
          </p>
        )}
        <Row>
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <ButtonPrimary
              className="button-new-student-by-nile"
              onClick={() => checkRpcIsEmpty()}
              style={{
                width: "100%",
                background: "#6DD765",
                marginTop: "0px",
                height: "40px",
              }}
              text={
                <>
                  <p style={{ marginTop: "3px" }}>
                    <span style={{ color: "#FFF" }}>Save</span>
                  </p>
                </>
              }
            />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <ButtonPrimary
              className="button-new-student-by-nile"
              style={{ width: "100%", marginTop: "0px", height: "40px" }}
              text={
                <>
                  <p style={{ marginTop: "3px" }}>
                    <span style={{ color: "gray" }}>Cancel</span>
                  </p>
                </>
              }
              onClick={() => onCloseModal()}
            />
          </Col>
        </Row>
      </Modal>

      <Modal
        centered
        closable={false}
        visible={visibleEdit}
        onOk={() => setVisibleEdit(false)}
        onCancel={() => setVisibleEdit(false)}
        footer={null}
        className="modalStyle-ModalSelectATK"
      >
        <br />
        <p className="txt-confirm-create-new-employee">
          <AiOutlineQuestionCircle
            size="30"
            color="#faad14"
            style={{ marginRight: "10px" }}
          />
          ยืนยันการแก้ไข Zone
        </p>
        <br />
        <Row>
          <Col xs="12" sm="12" md="6" lg="6" xl="6">
            <br />
            <ButtonPrimary
              className="button2-save-create-new-employee-by-nile"
              text="ยืนยัน"
              onClick={() => editRpcZoneFc()}
              loading={loading}
            />
          </Col>
          <Col xs="12" sm="12" md="6" lg="6" xl="6">
            <br />
            <ButtonPrimary
              className="icon-txt-button2-by-nile"
              text="ยกเลิก"
              onClick={() => setVisibleEdit(false)}
            />
          </Col>
        </Row>
      </Modal>
    </div>
  );
}
