import React, { useState } from "react";
const color = { 0: "fddc9a", 1: "83F8AB", 2: "9bb2ff", 3: "eaa5ff" };
const txtMode = { 0: "Temp", 1: "Fan Speed", 2: "Freq", 3: "Power" };
export default function CircleIndicator({ percent, mode, value }) {
  const [show, setShow] = useState(false);
  var circum = 2 * 3.14 * 25;
  var dash = circum * percent;
  var gap = circum - dash;
  //   console.log(percent);
  //   console.log(circum);
  //   console.log(dash);
  //   console.log(gap);
  return (
    <div className="box-circle">
      <div
        className="box-cover-circle-by-nile"
        onMouseOver={() => {
          setShow(true);
        }}
        onMouseOut={() => {
          setShow(false);
        }}
      >
        <div className="full-background-circle" />
        <div
          style={{
            backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23${color[mode]}' stroke-width='25' stroke-dasharray='${dash}%2c${gap}' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e")`,
          }}
          className="semi-circle-by-nile"
        ></div>
        <div
          id={show ? "show-circle" : "hide-circle"}
          className="box-hover-top"
        >
          <p className="txt-sub-explanation1">{txtMode[mode]}</p>
          <p className="txt-higlight1">{value}</p>
        </div>
      </div>
      <p className="txt-sub-explanation1">{txtMode[mode]}</p>
    </div>
  );
}
