import { useState, useEffect, useContext } from "react";
import { user } from "../../assets/images";
import ButtonPrimary from "../../components/ButtonPrimary";
import { FiEdit } from "react-icons/fi";
import { Row, Col } from "reactstrap";
import CardProfileInfo from "./CardProfileInfo";
import EditCardProfileInfo from "./EditCardProfileInfo";
import RemoveEmployee from "./RemoveEmployee";
import { EditEmployeeOneContext } from "../../components/Context";

export default function Profile({}) {
  const { employee, employee_id, department, factory, rpcZone } = useContext(
    EditEmployeeOneContext
  );
  const [editProfile, setEditProfile] = useState(false);
  const [editRpcZoneEmployee, setEditRpcZoneEmployee] = useState([]);
  const [defaultRpcZoneEmployee, setDefaultRpcZoneEmployee] = useState([]);
  const [employeeProfile, setEmployeeProfile] = useState({
    code: null,
    department_id: null,
    factory_id: null,
    first_name: null,
    last_name: null,
  });

  useEffect(() => {
    mappingDefaultRpcZone()

    return () => {};
  }, [rpcZone]);

  function mappingDefaultRpcZone() {
    const rpcDefault = [];
    if (rpcZone.length !== 0) {
      rpcZone.map((data) => {
        rpcDefault.push(data.rpc_id);
      });
      setDefaultRpcZoneEmployee(rpcDefault);
    }
  }

  useEffect(() => {
    if (employee.employee_id) {
      setEmployeeProfile({
        ...employeeProfile,
        code: employee.code,
        department_id: employee.department_id,
        factory_id: employee.factory_id,
        first_name: employee.first_name,
        last_name: employee.last_name,
      });
    }

    return () => {};
  }, [employee]);

  useEffect(() => {
    if (editProfile === false) {
      setEmployeeProfile({
        ...employeeProfile,
        code: employee.code,
        department_id: employee.department_id,
        factory_id: employee.factory_id,
        first_name: employee.first_name,
        last_name: employee.last_name,
      });

      mappingDefaultRpcZone()
      setEditRpcZoneEmployee([])
    }

    return () => {

    };
  }, [editProfile]);

  return (
    <div className="box-profile-student-by-nile">
      <div>
        <p className="text-header-personal">Profile</p>
      </div>

      {editProfile === true && <RemoveEmployee employee={employee} />}

      <div className="box-inside-profile-student-by-nile">
        <Row>
          <Col>
            <img
              className="box-image-profile-sm-by-nile"
              src={user}
              alt="employee"
            />
            <div className="box-top-left-button">
              <ButtonPrimary
                style={{ padding: "0.7rem" }}
                text={<FiEdit size={18} color="gray" />}
                className={"text-button"}
                onClick={() => setEditProfile(!editProfile)}
              />
            </div>
          </Col>
        </Row>

        {editProfile === false && <CardProfileInfo />}

        {editProfile === true && (
          <EditCardProfileInfo
            editProfile={editProfile}
            employeeProfile={employeeProfile}
            setEmployeeProfile={setEmployeeProfile}
            setEditProfile={setEditProfile}
            editRpcZoneEmployee={editRpcZoneEmployee}
            setEditRpcZoneEmployee={setEditRpcZoneEmployee}
            defaultRpcZoneEmployee={defaultRpcZoneEmployee}
            setDefaultRpcZoneEmployee={setDefaultRpcZoneEmployee}
          />
        )}
      </div>
    </div>
  );
}
