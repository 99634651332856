import React from "react";
import { Row, Col } from "reactstrap";

export default function DisplayTextValueResponsive({ textHeader, textValue }) {
  return (
    <Row>
      <Col xs={12} sm={12} md={3} lg={3} xl={3}>
      {textHeader}
      </Col>
      <Col xs={12} sm={12} md={9} lg={9} xl={9}>
      {textValue}
      </Col>
    </Row>
  );
}
