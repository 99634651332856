import React from "react";
import DisplayTextValueResponsive from "../../components/DisplayTextValueResponsive";

export default function General({
  device_serial_number,
  meta_data,
  status_device,
}) {

  function calculateBetweenTwoDates(dateServer, dateLocal) {
    let diff, ms, ss, hh, days, mm;
    diff = Math.abs(dateServer - dateLocal);
    ms = diff % 1000;
    diff = (diff - ms) / 1000;
    ss = diff % 60;
    diff = (diff - ss) / 60;
    mm = diff % 60;
    diff = (diff - mm) / 60;
    hh = diff % 24;
    days = (diff - hh) / 24;
    return {ms, ss, hh, days, mm}
  }

  function checkStatus(status_device) {
    switch (status_device.command) {
      case "reboot":
        return <span className="text-color-reboot-device-by-nile">Reboot</span>;
        break;
      case "turn-off":
        return (
          <span className="text-color-offline-device-by-nile">Offline</span>
        );
        break;
      case "turn-on":
        let date = new Date(status_device.last_updated.$date);
        let dateLocal = new Date();
        let { ms, ss, hh, days, mm } = calculateBetweenTwoDates(date, dateLocal);
        if (days === 0 && hh === 0) {
          return <span className="text-color-success-by-nile">Available</span>;
        } else {
          return (
            <span className="text-color-error-by-nile">
              Device error, Please reboot device
            </span>
          );
        }
        break;
      default:
        break;
    }
  }

  return (
    <div className="box-details-by-nile">
      <p className="txt-sub-header1-by-nile">General</p>
      <DisplayTextValueResponsive
        textHeader={<p className="txt-main-content1">SN</p>}
        textValue={
          <p className="txt-main-content1 mb-10px">
            <span className="col-part-lg-by-nile">:</span>
            <span className="col-part-sm-by-nile">-</span>{" "}
            {device_serial_number}
          </p>
        }
      />

      {/* <DisplayTextValueResponsive
        textHeader={<p className="txt-sub-header1">Place</p>}
        textValue={
          <p className="txt-sub-header1-font-weight-400 mb-10px">
            <span className="col-part-lg-by-nile">:</span>
            <span className="col-part-sm-by-nile">-</span> LPF ลำพูน จุด 2
          </p>
        }
      /> */}

      {meta_data !== null && (
        <>
          <DisplayTextValueResponsive
            textHeader={<p className="txt-sub-header1">IP</p>}
            textValue={
              <p className="txt-sub-header1-font-weight-400 mb-10px">
                <span className="col-part-lg-by-nile">:</span>
                <span className="col-part-sm-by-nile">-</span>{" "}
                {meta_data.ip_address ? meta_data.ip_address : null}
              </p>
            }
          />

          <DisplayTextValueResponsive
            textHeader={<p className="txt-sub-header1">WIfI</p>}
            textValue={
              <p className="txt-sub-header1-font-weight-400 mb-10px">
                <span className="col-part-lg-by-nile">:</span>
                <span className="col-part-sm-by-nile">-</span> {null}
              </p>
            }
          />
        </>
      )}

      {status_device !== null && (
        <DisplayTextValueResponsive
          textHeader={<p className="txt-sub-header1">Status</p>}
          textValue={
            <p className="txt-sub-header1-font-weight-400 mb-10px">
              <span className="col-part-lg-by-nile">:</span>
              <span className="col-part-sm-by-nile">-</span>{" "}
              {checkStatus(status_device)}
            </p>
          }
        />
      )}
    </div>
  );
}
