import { put, takeEvery, call } from "redux-saga/effects";
import {
    GET_EMPLOYEE_DELETED_REQUEST,
    GET_EMPLOYEE_DELETED_SUCCESS,
    GET_EMPLOYEE_DELETED_FAILED,
    CLEAR_EMPLOYEE_DELETED
} from "../actions";
import * as api from "../../Service/Api_Muthermfacesense";
import axios from "axios";

const token = localStorage.getItem("token");

function getEmployeeDeletedFetch() {
    const employee = api.GET_EMPLOYEE_DELETED(token);
    return axios(employee)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  function* handleGetEmployeeDeleted({ payload }) {
    if (payload && payload.clear) {
        yield put({
          type: CLEAR_EMPLOYEE_DELETED,
        });
      }  else {
        try {
            const responseEmployee = yield call(getEmployeeDeletedFetch);
            yield put({
              type: GET_EMPLOYEE_DELETED_SUCCESS,
              employeeDeleted: responseEmployee.message.employees,
            });
          } catch (err) {
            yield put({
              type: GET_EMPLOYEE_DELETED_FAILED,
              error: err.response.data.detail,
              statusCode: err.response.status,
            });
          }
      }
  }
  
  function* getEmployeeDeleted() {
    yield takeEvery(GET_EMPLOYEE_DELETED_REQUEST, handleGetEmployeeDeleted);
  }
  
  export default getEmployeeDeleted;