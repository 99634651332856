import * as types from "../actions";

const initialState = {
  log: [],
  loading: false,
  error: null,
  statusCode: null,
  totalCount: 0,
  totalPage: 0,
};

const logFilter = (state = initialState, action) => {
    switch (action.type) {
      case types.GET_LOG_FILTER_REQUEST:
        return { ...state, loading: true };
      case types.GET_LOG_FILTER_SUCCESS:
        return {
          ...state,
          loading: false,
          log: action.log,
          totalCount: action.totalCount,
          totalPage: action.totalPage,
          error: null,
          statusCode: null,
        };
      case types.GET_LOG_FILTER_FAILED:
        return {
          ...state,
          loading: false,
          error: action.error,
          statusCode: action.statusCode,
          log: [],
        };
        case types.CLEAR_LOG_FILTER:
          return {
            ...state,
            log: [],
            loading: false,
            error: null,
            statusCode: null,
            totalCount: 0,
            totalPage: 0,
          };
      default:
        return state;
    }
  };
  
  export default logFilter;