import * as types from "../actions";

const initialState = {
    factory: [],
  loading: false,
  error: null,
  statusCode: null,
};

const Factory = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_FACTORY_REQUEST:
      return { ...state, loading: true };
    case types.GET_FACTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        factory: action.factory,
      };
    case types.GET_FACTORY_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        statusCode: action.statusCode,
      };
    default:
      return state;
  }
};

export default Factory;
